import { Paginator, QueryData } from "@/hooks/useFilter";
import { apis } from ".";
import { User } from "@/types/user.types";
import { useQueryClient } from "@tanstack/react-query";
import Cookies from "universal-cookie";
import { clearAllCookies } from "@/layout/ProtectedLayout";
const cookies = new Cookies();
export interface like {
  itemId: string;
  itemType: "artist" | "object" | "event" | "business";
  action: "add" | "remove";
}

export interface UserInteraction {
  artistsFollowed: string[];
  objectsLiked: string[];
  eventsBooked: string[];
  businessFollowed: string[];
  addedBy: string;
  createdAt: string;
  updatedAt: string;
  isDeleted: boolean;
  isActive: boolean;
  recentArtistsViewed: string[];
  recentObjectsViewed: string[];
  recentbusinessViewed: string[];
  recenteventsViewed: string[];
  surveyResponses: SurveyResponse[];
  id: string;
  objectLikesCount: number;
}

export interface SurveyResponse {
  sectionId?: string;
  response?: {
    ans?: string[];
  };
  itemType: "artist" | "object" | "business" | "event";
}

export interface UseUserAPI {
  searchUsers: (searchParams: QueryData) => Promise<{
    data: User[];
    paginator: Paginator;
  }>;
  userById: (params: { id: string }) => Promise<any>;
  myInfo: () => Promise<any>;
  updateMe: (dataToUpdate: any) => Promise<any>;
  addOrRemoveLike: (body: like) => Promise<void>;
  getUserIntreaction: (params: { id: string }) => Promise<UserInteraction>;
  login: (params: { phone: string; password: string }) => Promise<any>;
  refreshToken: () => Promise<any>;
  resetPassword: (params: {
    id: string;
    password: string;
    oldPassword?: string;
  }) => Promise<boolean>;
  sendOtp: (params: { phone: string }) => Promise<boolean>;
  verifyOtp: (params: { phone: string; otp: string }) => Promise<any>;
  sendVerificationMail: (params: { keycloakId: string }) => Promise<any>;
  addAndRemoveFromCatalogueRaisonne: (params: { id: string }) => Promise<any>;
  checkIfUserMigrated: (params: {
    phone: string;
    test?: boolean;
  }) => Promise<any>;
  checkCatalogueRaisonne: (params: { id: string }) => Promise<any>;
  fetchUserRole: () => Promise<any>;
}
const useUserAPI = (): UseUserAPI => {
  const queryClient = useQueryClient();
  const searchUsers = async (
    searchParams: QueryData,
  ): Promise<{
    data: User[];
    paginator: Paginator;
  }> => {
    try {
      const { data: response } = await apis.post("/client/api/v1/user/list", {
        data: searchParams,
      });

      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const userById = async ({ id }: { id: string }): Promise<User> => {
    try {
      const { data } = await apis.get(`/client/api/v1/user/${id}`, {});
      return data.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const myInfo = async (): Promise<any> => {
    try {
      const { data } = await apis.get(`/client/api/v1/user/me`, {});
      return data.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const updateMe = async (dataToUpdate: any) => {
    try {
      const { data } = await apis.put(`/client/api/v1/user/me/update`, {
        data: dataToUpdate,
      });
      queryClient.invalidateQueries({
        queryKey: ["me"],
      });
      return data.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const addOrRemoveLike = async (boady: like) => {
    try {
      const { data } = await apis.post(
        "/client/api/v1/userinteractions/create",
        {
          data: boady,
        },
      );
      return data.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getUserIntreaction = async ({
    id,
  }: {
    id?: string;
  }): Promise<UserInteraction> => {
    try {
      const url = id
        ? `/client/api/v1/userinteractions/interactionsbyuser/${id}`
        : `/client/api/v1/userinteractions/interactionsbyuser`;

      const { data } = await apis.get(url, {});

      return data?.data; // Ensure data exists before returning
    } catch (error) {
      console.error("Error fetching user interaction:", error);
      throw error;
    }
  };

  const login = async ({
    phone,
    password,
  }: {
    phone: string;
    password: string;
  }) => {
    try {
      const { data } = await apis.post("client/auth/login", {
        data: {
          phone,
          password,
        },
      });
      const tokens = data.data;
      clearAllCookies();
      cookies.set("token", tokens.accessToken, { path: "/" });
      cookies.set("refreshToken", tokens.refreshToken, { path: "/" });
      return tokens.user;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const refreshToken = async () => {
    try {
      const { data } = await apis.post("client/auth/refresh-token", {
        data: {
          refreshToken: cookies.get("refreshToken"),
        },
      });
      return data.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const resetPassword = async ({
    id,
    password,
    oldPassword = "",
  }: {
    id: string;
    password: string;
    oldPassword?: string;
  }) => {
    try {
      const { data } = await apis.put("client/auth/reset-password", {
        data: {
          id,
          password,
          oldPassword,
        },
      });
      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const sendOtp = async ({ phone }: { phone: string }) => {
    try {
      const { data } = await apis.post("client/auth/send-otp", {
        data: {
          phone,
        },
      });
      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const verifyOtp = async ({ phone, otp }: { phone: string; otp: string }) => {
    try {
      const { data } = await apis.post("client/auth/verify-otp", {
        data: {
          phone,
          otp,
        },
      });
      return data.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const sendVerificationMail = async ({
    keycloakId,
  }: {
    keycloakId: string;
  }) => {
    try {
      const { data } = await apis.post("client/auth/verify-email", {
        data: {
          keycloakId,
        },
      });
      return data.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const addAndRemoveFromCatalogueRaisonne = async ({ id }: { id: string }) => {
    try {
      const { data } = await apis.put(
        `/client/api/v1/user/add-remove-catalog-raisone/${id}`,
        {},
      );
      return data.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const checkCatalogueRaisonne = async ({ id }: { id: string }) => {
    const { data } = await apis.get(
      `/client/api/v1/user/catalog-raisone-eligibility/${id}`,
      {},
    );
    return data.data;
  };

  const checkIfUserMigrated = async ({
    phone,
    test,
  }: {
    phone: string;
    test?: boolean;
  }) => {
    try {
      const { data } = await apis.post("/client/auth/is-migrated-user", {
        data: {
          phone,
          test,
        },
      });
      return data.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const fetchUserRole = async () => {
    try {
      const { data } = await apis.get(
        "/client/api/v1/user/fetchUserRole/fetch",
        {},
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  };

  return {
    searchUsers,
    userById,
    addOrRemoveLike,
    getUserIntreaction,
    myInfo,
    updateMe,
    login,
    refreshToken,
    verifyOtp,
    sendOtp,
    resetPassword,
    sendVerificationMail,
    addAndRemoveFromCatalogueRaisonne,
    checkIfUserMigrated,
    checkCatalogueRaisonne,
    fetchUserRole,
  };
};

export default useUserAPI;
