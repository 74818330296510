import React, { ReactNode, useState } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from "@/components/ui/drawer";
import { useMediaQuery } from "@mui/material";
import toast from "react-hot-toast";
import { cn } from "@/lib/utils";
import { useBoardsContext } from "@/context/boardsContext";

type ShareModalProps = {
  children: ReactNode;
  url: string; // URL to share
  className?: string;
  closeMenu?: () => void;
};

const ShareModal: React.FC<ShareModalProps> = ({
  children,
  url,
  className,
  closeMenu,
}) => {
  const [open, setOpen] = useState(false); // State for open/close
  const isDesktop = useMediaQuery("(min-width: 768px)"); // Media query for responsive design
  const { setIsDraggingDisable } = useBoardsContext();
  const closeModal = () => setOpen(false); // Function to close modal
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
    setIsDraggingDisable(true);
  };
  return isDesktop ? (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        if (!open && closeMenu) closeMenu();
        setOpen(open);
        setIsDraggingDisable(open);
      }}
    >
      <DialogTrigger
        onClick={handleClick} // Open dialog on trigger click
        className={cn(
          "text-lg text-white transition-colors duration-200 ease-in-out bg-transparent cursor-pointer hover:text-gray-300",
          className,
        )}
      >
        {children}
      </DialogTrigger>
      <DialogContent
        onClick={(e) => e.stopPropagation()}
        onOpenAutoFocus={(e) => e.preventDefault()}
        className="min-w-[400px] min-h-[250px] shadow-lg "
      >
        <DialogHeader className="px-8 ">
          <DialogTitle className="border-[#E5E5E5] pb-5 border-b-[1px] border-solid text-gray-100 font-normal font-sh5 text-[28px]">
            <div className="flex flex-row items-center justify-between">
              <p>Share</p>
              <DialogClose
                asChild
                className="bg-transparent"
                onClick={() => closeMenu?.()}
              >
                <img
                  className="transition-opacity duration-200 ease-in-out cursor-pointer hover:opacity-80"
                  src="/close1.svg"
                  alt="Close"
                />
              </DialogClose>
            </div>
          </DialogTitle>
        </DialogHeader>
        <ProfileForm url={url} closeModal={closeModal} />
      </DialogContent>
    </Dialog>
  ) : (
    <Drawer open={open} onOpenChange={setOpen}>
      <DialogTrigger
        onClick={handleClick}
        className={cn(
          "text-lg text-white transition-colors duration-200 ease-in-out bg-transparent cursor-pointer hover:text-gray-300",
          className,
        )}
      >
        {children}
      </DialogTrigger>
      <DrawerContent
        onClick={(e) => e.stopPropagation()}
        onOpenAutoFocus={(e) => e.preventDefault()}
        className="rounded-none shadow-lg"
      >
        <DrawerHeader className="p-5 m-0 mb-5 text-left border-b border-solid border-gray-10">
          <DrawerTitle className="text-5xl font-normal text-gray-100 font-sh5">
            <div className="flex flex-row items-center justify-between">
              Share
              <DialogClose asChild className="bg-transparent">
                <img
                  className="transition-opacity duration-200 ease-in-out cursor-pointer hover:opacity-80"
                  src="/close1.svg"
                  alt="Close"
                />
              </DialogClose>
            </div>
          </DrawerTitle>
        </DrawerHeader>
        <ProfileForm url={url} closeModal={closeModal} />
      </DrawerContent>
    </Drawer>
  );
};

type ProfileFormProps = {
  url: string; // URL to share
  closeModal: () => void; // Function to close the modal
};

const ShareButton: React.FC<{
  onClick: () => void;
  icon: string;
  label: string;
}> = ({ onClick, icon, label }) => (
  <div
    onClick={onClick}
    className="flex flex-row items-center transition-all duration-200 ease-in-out rounded-md cursor-pointer hover:bg-bdr-10 gap-x-[13px] mb-5"
  >
    <img className="w-6 h-6" src={icon} alt={label} />
    <p className="text-base font-normal text-gray-100 font-sh5">{label}</p>
  </div>
);

const ProfileForm: React.FC<ProfileFormProps> = ({ url, closeModal }) => {
  const domainUrl = window.location.origin; // Get the current domain URL
  const fullUrl = `${domainUrl}${url}`;
  const handleEmailShare = () => {
    window.location.href = `mailto:?subject=Check this out&body=Here's the link: ${encodeURIComponent(
      fullUrl,
    )}`;
    closeModal(); // Close modal after sharing
  };

  const handleWhatsAppShare = () => {
    window.open(
      `https://api.whatsapp.com/send?text=Check this out: ${encodeURIComponent(
        fullUrl,
      )}`,
      "_blank",
    );
    closeModal(); // Close modal after sharing
  };

  const handleCopyLink = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(fullUrl).then(
        () => {
          toast.success("Link copied to clipboard!");
          closeModal(); // Close modal after copying link
        },
        (err) => {
          console.error("Failed to copy text: ", err);
          toast.error("Failed to copy link, please try again.");
        },
      );
    } else {
      toast.error(
        "Clipboard not supported in this browser. Please copy manually.",
      );
    }
  };

  return (
    <form className="grid items-start pt-[20px] px-8 pb-[12px] gap-y-5 mq750:px-5 mq750:pb-0">
      <div className="text-base font-normal text-center cursor-pointer font-sh5 text-gray-60">
        <ShareButton
          onClick={handleEmailShare}
          icon="/email.svg"
          label="Email"
        />
        <ShareButton
          onClick={handleWhatsAppShare}
          icon="/instagram.svg"
          label="WhatsApp"
        />
        <ShareButton
          onClick={handleCopyLink}
          icon="/copyAlt.svg"
          label="Copy link"
        />
      </div>
    </form>
  );
};

export default ShareModal;
