import React, { useEffect } from "react";

interface BannerProps {
  desktopImage: string;
  mobileImage: string;
  styles?: any;
  page?: string;
}
const Banner = ({
  desktopImage,
  mobileImage,
  styles = null,
  page = "landing_page",
}: BannerProps) => {
  const windowWidth = window.innerWidth;
  const getcustomClass = (page: string) => {
    switch (page) {
      case "artist":
        return "text-[48px] text-[#fff] max-sm:text-[36px]  max-sm:mb-[0.5rem]";
      case "business":
        return "text-[48px] leading-[100%] text-[#fff] max-sm:text-[36px] max-sm:mb-4";
      case "everyone":
        return "text-[48px] mb-[32px] leading-[100%] text-[#fff] max-sm:text-[36px] max-sm:mb-4 max-sm:w-[84%]";
      case "collectors":
        return "text-[48px] leading-[100%] text-[#fff] max-sm:text-[36px] max-sm:mb-4";
      case "landing_page":
        return "text-[36px] leading-[120%] text-white";
      default:
        break;
    }
  };
  const getcustomSubClass = (page: string) => {
    switch (page) {
      case "artist":
        return "text-[#fff]  max-sm:w-[77%] max-sm:mb-[24px]";
      case "landing_page":
        return "text-white max-sm:mb-[24px]";
      case "everyone":
        return "text-white max-sm:w-[72%] max-sm:mb-[24px]";
      case "business":
        return "max-sm:w-[77%] text-[#fff]";
      case "collectors":
        return "max-sm:w-[68%] text-white max-sm:mb-[24px]";
      default:
        break;
    }
  };

  const getcustomButtonClass = (page: string) => {
    switch (page) {
      case "artist":
        return "hover:bg-[#f6f6f5] font-medium text-[#1D1D1D] bg-[#fff] ";
      case "landing_page":
        return "hover:bg-[#f6f6f5] font-medium text-[#303030] bg-white";
      case "business":
        return "hover:bg-[#f6f6f5] font-medium text-[#1D1D1D] bg-[#fff]";
      case "collectors":
        return "hover:bg-[#f6f6f5] font-medium text-[#1D1D1D] bg-[#fff]";
      case "everyone":
        return "hover:bg-[#f6f6f5] font-medium text-[#303030] bg-white";
      default:
        break;
    }
  };
  const getBannerText = (page: string) => {
    switch (page) {
      case "artist":
        return "Get Asign";
      case "business":
        return "Use Asign";
      case "collectors":
        return "Manage your collection!";
      case "everyone":
        return (
          <>
            Begin your journey <br /> into the world of <br /> art and
            collectibles
          </>
        );
      case "landing_page":
        return "A first-of-its-kind community where artists, galleries, and collectors can discover, gather, and grow.";
      default:
        break;
    }
  };
  const getBannerSubText = (page: string) => {
    switch (page) {
      case "artist":
        return "Use Asign for artwork documentation, inventory management, marketing, controlling your rights, and selling your work.";
      case "business":
        return "To sell to and interact with a global audience and leverage marketing tools for your business.";
      case "landing_page":
        return "Unlock the art world.";
      case "collectors":
        return "Use Asign to organise your documentation, establish authenticity, and build a pathway to liquidity.";
      case "everyone":
        return (
          <>
            Providing you with resources, services, and{" "}
            <br className="max-lg:hidden" /> dedicated support as you take your
            first <br className="max-lg:hidden" /> steps into the world of
            collecting with us.{" "}
          </>
        );
      default:
        break;
    }
  };

  useEffect(() => {
    let bannerSection = document.querySelector(".banner_section");
    let bannerSize = "max-sm:h-[426px]";
    switch (page) {
      case "artist":
        bannerSize = "max-sm:h-[475px]";
        break;
      case "business":
        bannerSize = "max-sm:h-[475px]";
        break;
      case "collectors":
        bannerSize = "max-sm:h-[475px]";
        break;
      case "everyone":
        bannerSize = "max-sm:h-[475px]";
        break;
      default:
        break;
    }
    if (bannerSection) {
      bannerSection.classList.add(bannerSize);
    }
  }, []);

  return (
    <section
      className={`relative banner_section font-sh5 w-full z-0 h-[646px] max-md:bg-bottom max-sm:top-[143px] max-md:top-[79px] max-md:px-[32px] 2xl:max-w-[1600px] max-md:[490px] top-[79px] mx-auto ${styles ? styles.desktopImageStyles : "bg-cover"} flex items-center px-12`}
      style={{
        backgroundImage: "url(" + desktopImage + ")",
        backgroundSize:
          page === "landing_page" || page === "everyone" ? "cover" : "cover ",
        backgroundPosition:
          windowWidth <= 767 // If it's a mobile view (<= 767)
            ? page === "landing_page" // Set position for "landing_page"
              ? "left"
              : page === "artist" // Set position for "artist"
                ? "100% 47%"
                : page === "business" // Set position for "business"
                  ? "left center"
                  : page === "everyone" // Set position for "everyone"
                    ? "left center"
                    : "13% 57%" // Default position for other pages on mobile
            : page === "landing_page" // Set position for "landing_page" on desktop
              ? "left"
              : page === "artist" // Set position for "artist" on desktop
                ? "100% 47%"
                : page === "business" // Set position for "business" on desktop
                  ? "100% 42%"
                  : page === "everyone" // Set position for "everyone" on desktop
                    ? "100% 50%"
                    : "100% 42%", // Default case if no matching page

        backgroundRepeat: "no-repeat",
        backgroundColor: "#CFCFCF",
      }}
      role="banner"
      aria-label="Banner"
    >
      <source media="(max-width: 767px)" srcSet={mobileImage}></source>
      {/* Content */}
      <div
        className="absolute z-0 max-w-[440px] text-left mt-[-10px]"
        style={{
          top:
            page === "landing_page"
              ? "10%"
              : page === "everyone" || page === "business"
                ? "12%"
                : "10%",
        }}
      >
        <h2
          className={
            "max-md:text-[24px] font-normal font-sh5  mb-[20px] " +
            getcustomClass(page)
          }
        >
          {getBannerText(page)}
        </h2>
        <p
          className={
            "text-[18px] max-md:text-[16px] leading-[120%] tracking-[0] mb-[40px] " +
            getcustomSubClass(page)
          }
        >
          {getBannerSubText(page)}
        </p>
        <a
          href="/register"
          className={
            "inline-block px-[27px] py-3 text-sm max-md:text-[12px]  rounded-full shadow-lg hover:bg- transition " +
            getcustomButtonClass(page)
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          GET ASIGN
        </a>
      </div>

      {/* Caption */}
      {page === "landing_page" && (
        <figcaption className="absolute bottom-5 left-12 max-md:left-8 text-white text-[18px] max-md:text-[18px] max-md:tracking-[0.4px] leading-[120%] tracking-[0] font-['EB_Garamond']">
          G. D. Paul Raj, <em>Untitled, 1936</em>
        </figcaption>
      )}
      {page === "artist" && (
        <figcaption className="absolute bottom-5 left-12 max-md:left-8 text-white text-[18px] max-md:text-[18px] max-md:tracking-[0.4px] leading-[120%] tracking-[0] font-['EB_Garamond']">
          Achuthan Kudallur, <em>Untitled, 1988</em>
        </figcaption>
      )}
      {page === "business" && (
        <figcaption className="absolute bottom-5 left-12 max-md:left-8 text-white text-[18px] max-md:text-[18px] max-md:tracking-[0.4px] leading-[120%] tracking-[0] font-['EB_Garamond']">
          K. Sreenivasulu, <em> Mother & Child, 1956</em>
        </figcaption>
      )}
      {page === "collectors" && (
        <figcaption className="absolute bottom-5 left-12 max-md:left-8 text-[#fff] max-md:pr-2 text-[18px] max-md:text-[18px] max-md:tracking-[0.4px] leading-[120%] tracking-[0] font-['EB_Garamond']">
          D.P. Roy Chowdhury,<em> Leader's Perch, c.1960s</em>
        </figcaption>
      )}
      {page === "everyone" && (
        <figcaption className="absolute bottom-5 left-12 max-md:left-8 text-white text-[18px] max-md:text-[18px] max-md:tracking-[0.4px] leading-[120%] tracking-[0] font-['EB_Garamond']">
          S. H. Raza, <em>Suryanamaskar,1993</em>
        </figcaption>
      )}
      {/* Background Overlay */}
    </section>
  );
};

export default Banner;
