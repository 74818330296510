import useUserAPI, { like } from "@/apis/user";
import { Button } from "@/components/ui/button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import toast from "react-hot-toast";

interface props extends like {
  children: React.ReactNode;
  itemId: string;
  itemType: "artist" | "object" | "event" | "business";
  action: "add" | "remove";
  handleLike?: (itemID: string) => void;
}
const VALID_ITEM_TYPES = {
  artist: "artistsFollowed",
  object: "objectsLiked",
  event: "eventsBooked",
  business: "businessFollowed",
};
const Like = React.memo((props: props) => {
  const { addOrRemoveLike } = useUserAPI();
  const [pendingRequest, setPendingRequest] = React.useState<ReturnType<
    typeof setTimeout
  > | null>(null);
  const queryClient = useQueryClient();

  const updateInteractionsOptimistically = (variables: like) => {
    const key = `${VALID_ITEM_TYPES[variables.itemType]}`;
    queryClient.setQueryData(["user-interactions"], (old: any) => ({
      ...old,
      [key]:
        variables.action === "add"
          ? [...(old?.[key] || []), variables.itemId]
          : (old?.[key] || []).filter((id: string) => id !== variables.itemId),
    }));
  };

  const handler = useMutation({
    mutationFn: addOrRemoveLike,
    mutationKey: ["like"],
    onError: (error: any) => {
      toast.error(error.message);
      // Rollback the optimistic update
      queryClient.invalidateQueries({ queryKey: ["user-interactions"] });
    },
    onSuccess: () => {
      if (props.handleLike) {
        props.handleLike(props.itemId);
      }
    },
  });

  const buttonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    // Clear any pending request
    if (pendingRequest) {
      clearTimeout(pendingRequest);
    }

    //if try to follow an artist/business update the artist/business by id api follower count
    if (props.itemType === "artist" || props.itemType === "business") {
      queryClient.setQueryData([props.itemType, props.itemId], (old: any) => ({
        ...old,
        followers:
          props.action === "add"
            ? old?.followers + 1
            : old?.followers == 0
              ? 0
              : old?.followers - 1,
      }));
    }

    // Immediately update UI
    updateInteractionsOptimistically({
      itemId: props.itemId,
      itemType: props.itemType,
      action: props.action,
    });

    // Debounce the actual API call
    const timeoutId = setTimeout(() => {
      handler.mutate({
        itemId: props.itemId,
        itemType: props.itemType,
        action: props.action,
      });
    }, 1000); // 1 second delay

    setPendingRequest(timeoutId);
  };

  return (
    <button onClick={buttonClick} type="button">
      {props.children}
    </button>
  );
});

export default Like;
