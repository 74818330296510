import React, { useState, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useLocation } from "react-router-dom";
interface IconProps {
  size?: string;
}

const ErrorIcon = ({ size = "16px" }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clip-path="url(#clip0_2502_564)">
      <path
        d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z"
        stroke="#FF2727"
        stroke-miterlimit="10"
      />
      <path
        d="M10 6V10.9522"
        stroke="#FF2727"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M10 12.4609V13.9609"
        stroke="#FF2727"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_2502_564">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const SuccessIcon = ({ size = "16px" }: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 16.125C12.935 16.125 16.125 12.935 16.125 9C16.125 5.06497 12.935 1.875 9 1.875C5.06497 1.875 1.875 5.06497 1.875 9C1.875 12.935 5.06497 16.125 9 16.125Z"
      stroke="#7AC51C"
      strokeMiterlimit="10"
    />
    <path
      d="M5.625 8.8726L7.9575 11.2126L12.375 6.7876"
      stroke="#7AC51C"
      strokeMiterlimit="10"
    />
  </svg>
);

const ResponsiveToaster = () => {
  const isCatalogueDetail =
    window.location.pathname.includes("catalog/add/object");
  const location = useLocation(); // Get current route
  const [toastStyles, setToastStyles] = useState<{
    success: React.CSSProperties;
    error: React.CSSProperties;
  }>({
    success: {
      backgroundColor: "#E8FAF0",
      color: "#696969",
      padding: "13.4px 16px",
      width: "100%",
      minWidth: "449px",
      fontSize: "14px",
      wordBreak: "break-all",
      marginBottom: "1rem",
      borderRadius: "0",
      textAlign: "left",
      fontFamily: "Neue Montreal",
      fontWeight: 400,
    },
    error: {
      backgroundColor: "#FAE8E8",
      color: "#696969",
      padding: "13.4px 16px",
      width: "100%",
      minWidth: "449px",
      fontSize: "14px",
      wordBreak: "break-all",
      marginBottom: "1rem",
      borderRadius: "0",
      textAlign: "left",
      fontFamily: "Neue Montreal",
      fontWeight: 400,
    },
  });

  useEffect(() => {
    const updateStyles = () => {
      if (window.innerWidth < 600) {
        setToastStyles({
          success: {
            backgroundColor: "#E8FAF0",
            color: "#1d1d1d",
            padding: "10px",
            width: "90%",
            minWidth: "auto",
            fontSize: "12px",
            marginBottom: "0.5rem",
            wordWrap: "break-word", // Use this instead of wordBreak
            overflowWrap: "break-word", // Additional safety for word wrapping
            borderRadius: "0px",
            textAlign: "center",
          },
          error: {
            backgroundColor: "#FAE8E8",
            color: "#1d1d1d",
            padding: "10px",
            width: "90%",
            minWidth: "auto",
            fontSize: "12px",
            wordWrap: "break-word", // Use this instead of wordBreak
            overflowWrap: "break-word", // Additional safety for word wrapping
            marginBottom: "0.5rem",
            borderRadius: "0px",
            textAlign: "center",
          },
        });
      } else {
        setToastStyles({
          success: {
            backgroundColor: "#E8FAF0",
            color: "#1d1d1d",

            padding: "13.4px 16px",
            width: "100%",
            minWidth: "449px",
            fontSize: "14px",
            marginBottom: "1rem",
            borderRadius: "0",
            textAlign: "left",
            ...(isCatalogueDetail && {
              position: "relative",
              width:
                window.innerWidth >= 600 && window.innerWidth < 1279
                  ? "350px"
                  : "449px",
              minWidth:
                window.innerWidth >= 600 && window.innerWidth < 1279
                  ? "350px"
                  : "449px",
              left:
                window.innerWidth >= 600 && window.innerWidth < 1279
                  ? "16%"
                  : window.innerWidth >= 1280
                    ? "12%" // Adjust this value as needed
                    : "13%",
            }),
          },
          error: {
            backgroundColor: "#FAE8E8",
            color: "#1d1d1d",
            padding: "13.4px 16px",
            width: "100%",
            minWidth: "449px",
            fontSize: "14px",
            marginBottom: "1rem",
            borderRadius: "0",
            textAlign: "left",
            ...(isCatalogueDetail && {
              position: "relative",
              width:
                window.innerWidth >= 600 && window.innerWidth < 1279
                  ? "350px"
                  : "449px",
              minWidth:
                window.innerWidth >= 600 && window.innerWidth < 1279
                  ? "350px"
                  : "449px",
              left:
                window.innerWidth >= 600 && window.innerWidth > 1279
                  ? "16%"
                  : "13%",
            }),
          },
        });
      }
    };

    window.addEventListener("resize", updateStyles);
    updateStyles(); // Initial setup

    return () => window.removeEventListener("resize", updateStyles);
  }, []);

  return (
    <Toaster
      position="bottom-center"
      toastOptions={{
        success: {
          duration: 3000,
          icon: <SuccessIcon size={"14px"} />,
          style: {
            ...toastStyles.success,
            fontFamily: "Neue Montreal",
            fontWeight: 400,
          },
        },
        error: {
          duration: 3000,
          icon: <ErrorIcon size={"14px"} />,
          style: {
            ...toastStyles.error,
            fontFamily: "Neue Montreal",
            fontWeight: 400,
          },
        },
        loading: {
          style: {
            fontSize: "14px",
            borderRadius: "0px",
            fontFamily: "Neue Montreal",
            fontWeight: 400,
          },
        },
      }}
    />
  );
};

export default ResponsiveToaster;
