import { capitalizeWords } from "@/lib/helper";
import { GetAllBoardResponse } from "@/types/boards.type";
import React from "react";
import { cn } from "@/lib/utils";
import { useLocation } from "react-router-dom";
// Define a function to check if a date is within the last N days
const isRecent = (date: string, days: number): boolean => {
  const dateThreshold = new Date();
  dateThreshold.setDate(dateThreshold.getDate() - days);
  return new Date(date) > dateThreshold;
};

// Define the number of days to consider a board as "recent"
const RECENT_DAYS_CREATED = 1;
const RECENT_DAYS_UPDATED = 3;

const RecentBoardList: React.FC<{
  data: GetAllBoardResponse;
  imageSources: { id: number; src: string }[];
  handleBoards: (url: string) => void;
}> = ({ data, imageSources, handleBoards }) => {
  // Check if data and data.data are defined before filtering
  const boards = data?.data?.data || [];

  // Filter boards that are recent based on createdAt or updatedAt
  let recentBoards = boards.filter(
    (board) =>
      isRecent(board.createdAt, RECENT_DAYS_CREATED) ||
      isRecent(board.updatedAt, RECENT_DAYS_UPDATED),
  );
  const location = useLocation();
  const currentPath = location.pathname;
  recentBoards = recentBoards
    .sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))
    .slice(0, 3);
  return (
    <>
      {recentBoards.length > 0 ? (
        recentBoards.map((board) => {
          const imageSource = imageSources.find(
            (image) => image.id === board.icon,
          );

          return (
            <div
              onClick={() => handleBoards(`/boards/${board.id}`)}
              key={board.id}
              className="flex flex-row items-center justify-center gap-2 py-0"
            >
              <img
                className="w-6 h-6"
                src={imageSource ? imageSource.src : ""}
                alt={`Icon for ${board.name}`}
              />
              <div
                className={cn(
                  "relative text-gray-60 max-w-[150px] truncate leading-[140%] shrink-0 whitespace-nowrap font-normal text-lg font-sh5",
                  currentPath == `/boards/${board.id}` &&
                    "text-gray-900 font-medium",
                )}
              >
                {capitalizeWords(board.name)}
              </div>
            </div>
          );
        })
      ) : (
        <div className="text-gray-500">No recent boards available.</div>
      )}
    </>
  );
};

export default React.memo(RecentBoardList);
