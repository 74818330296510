import { cn } from "@/lib/utils";
import React from "react";
import { FunctionComponent } from "react";

const Footer2: FunctionComponent<{ className?: string }> = ({ className }) => {
  return (
    <footer
      className={
        "mq1000:pt-[25px] pl-5 mq1000:pb-[10px] py-10 mq450:pl-1 font-sh5 max-sm:mb-[40px]" +
        className
          ? className
          : ""
      }
    >
      <div className="container mx-auto">
        <div className="flex flex-wrap items-center justify-center max-sm:justify-start text-sm text-gray-60">
          <div className="flex items-center gap-2">
            <a
              href="/terms-and-conditions/"
              className="hover:text-gray-700 leading-tight whitespace-nowrap relative after:content-[''] after:absolute after:right-[-8px] after:top-1/2 after:-translate-y-1/2 after:w-[0.1px] after:h-[13px] after:bg-[#696969]"
            >
              Terms & Conditions
            </a>

            <a
              href="/privacy-policy"
              className="hover:text-gray-700 leading-tight pl-2 whitespace-nowrap relative after:content-[''] after:absolute after:right-[-8px] after:top-1/2 after:-translate-y-1/2 after:w-[0.1px] after:h-[13px] after:bg-[#696969]"
            >
              Privacy Policy
            </a>

            <a
              href="/contact"
              className="hover:text-gray-700 leading-tight pl-2 whitespace-nowrap relative after:content-[''] after:absolute after:right-[-8px] after:top-1/2 after:-translate-y-1/2 after:w-[0.1px] after:h-[13px] md:after:bg-[#696969]"
            >
              Contact Us
            </a>
          </div>

          <p className="mq750:mt-0 hover:text-gray-700 leading-normal text-center border-solid  max-sm:pl-0 px-4 text-gray-60 relative">
            © 2025 Democrart Technologies Private Limited
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer2;
