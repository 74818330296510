import React from "react";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Banner from "./Components/Banner";
import Accordion from "./Components/Faqs";
import Featured from "./Components/Featured";
import banner from "../../constant/banners.json";
import reportsData from "./markingReport.json";
export default function PreloginLandingPage() {
  return (
    <>
      <div className="font-sh5 mx-auto px-0">
        <Header />

        <Banner
          desktopImage={banner.landing_page.desktopImage}
          mobileImage={banner.landing_page.mobileImage}
          page="landing_page"
        />

        <section className="touser pt-[100px] max-sm:px-[20px] max-md:px-[24px] lg:px-[40px] max-2xl:px-[40px] 2xl:px-0 max-sm:mt-[90px] max-md:mt-[80px] mt-[82px] 2xl:container">
          <div
            className="flex flex-col md:flex-row md:justify-center gap-10"
            role="list"
          >
            {/* Card 1: Artists */}
            <div className="flex flex-col md:w-1/3" role="listitem">
              <div className="w-full  lg:max-h-[330px] max-sm:h-[380px] max-md:h-[266px] mb-[36px] overflow-hidden">
                <picture className="block w-full h-full ">
                  <source
                    media="(max-width: 768px)"
                    srcSet="./prelogin-page-images/481694660135m1.webp"
                  />
                  <img
                    src="./prelogin-page-images/281694660135d1.webp"
                    alt="Artists - Showcase your work, protect your rights, and build a following."
                    className="w-full h-full object-cover  object-center"
                    role="img"
                  />
                </picture>
              </div>
              <div className="">
                <h2 className="text-[36px] max-md:text-[24px] font-normal leading-[100%] text-[#1d1d1d] mb-2">
                  Asign for Artists
                </h2>
                <p className="text-[#696969] text-[18px]  max-md:text-[16px] line-height-[120%] tracking-[0] mb-8">
                  Display your works and connect with art lovers and businesses.
                </p>
                <a
                  href="/artist"
                  className="inline-block px-[27px] py-[12px] max-h-[46px] font-medium max-md:text-[12px] text-[14px] max-md:w-full max-md:text-center login-btn text-[#303030] rounded-full hover:border-[#303030]"
                  role="button"
                  aria-label="Read More about Asign for artists"
                >
                  READ MORE
                </a>
              </div>
            </div>

            {/* Card 2: Business */}
            <div className="flex flex-col  md:w-1/3" role="listitem">
              <div className="w-full  lg:max-h-[330px] max-sm:h-[380px] max-md:h-[266px] mb-[36px] overflow-hidden">
                <picture className="block w-full h-full">
                  <source
                    media="(max-width: 768px)"
                    srcSet="./prelogin-page-images/211694660135m2.webp"
                  />
                  <img
                    src="./prelogin-page-images/221694660135d2.webp"
                    alt="Business - Leverage our tools to grow your business and reach a global customer base."
                    className="w-full h-full object-cover"
                    role="img"
                  />
                </picture>
              </div>
              <div>
                <h2 className="text-[36px] max-md:text-[24px] font-normal leading-[100%] text-[#1d1d1d] mb-2">
                  Asign for Business
                </h2>
                <p className="text-[#696969] text-[18px] max-md:text-[16px] line-height-[120%] tracking-[0] mb-8">
                  Grow your sales and reach a global network of buyers.
                </p>
                <a
                  href="/business"
                  className="inline-block px-[27px] py-[12px] max-h-[46px] font-medium max-md:text-[12px] text-[14px] max-md:w-full max-md:text-center login-btn text-[#303030] rounded-full hover:border-[#303030]"
                  role="button"
                  aria-label="Read More about Asign for business"
                >
                  READ MORE
                </a>
              </div>
            </div>

            {/* Card 3: Collectors */}
            <div className="flex flex-col  md:w-1/3" role="listitem">
              <div className="w-full lg:max-h-[330px] max-sm:h-[380px] max-md:h-[266px]  mb-[36px] overflow-hidden">
                <picture className="block w-full h-full">
                  <source
                    media="(max-width: 768px)"
                    srcSet="./prelogin-page-images/401694660135m3.webp"
                  />
                  <img
                    src="./prelogin-page-images/481694660135d3.webp"
                    alt="Collectors - Verified and authentic artworks at your fingertips."
                    className="w-full h-full object-cover"
                    role="img"
                  />
                </picture>
              </div>
              <div>
                <h2 className="text-[36px] max-md:text-[24px] font-normal leading-[100%] text-[#1d1d1d] mb-2">
                  Asign for Collectors
                </h2>
                <p className="text-[#696969] text-[18px] max-md:text-[16px] line-height-[120%] tracking-[0] mb-8">
                  Manage your collection and discover art you'll love.
                </p>
                <a
                  href="/collectors"
                  className="inline-block px-[27px] py-[12px] max-h-[46px] font-medium max-md:text-[12px] text-[14px] max-md:w-full max-md:text-center login-btn text-[#303030] rounded-full hover:border-[#303030]"
                  role="button"
                  aria-label="Read More about Asign for collectors"
                >
                  READ MORE
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="flex flex-col max-sm:flex-col-reverse max-sm:pt-[80px] max-sm:pr-[20px] max-sm:pl-[20px] max-sm:pb-[20px] md:flex-row my-[100px] max-md:my-[60px] max-md:mb-0 2xl:container bg-[#eeeeea]">
          {/* Left Section */}
          <div className="md:w-1/2">
            <div className="relative">
              <picture className="md:flex">
                <source
                  media="(max-width: 768px)"
                  srcSet="./prelogin-page-images/391694660234nwm1.webp"
                />
                <img
                  src="./prelogin-page-images/121694660234nwd1.webp"
                  alt="Image description"
                  className="w-full h-full object-cover max-md:h-[415px]"
                />
              </picture>
              <figcaption className="absolute bottom-6 left-12 text-white font-eb text-[18px] leading-[120%] tracking-[0] italic">
                DAG, Mumbai
              </figcaption>
            </div>
          </div>
          {/* Right Section */}
          <div className="md:w-1/2 flex items-center pl-[80px] max-md:px-[24px] md:pt-[87px] lg:pt-0 max-sm:flex-col tracking-[0] max-sm:px-[0px] pr-[48px] ">
            <div className="w-full">
              <h2 className="text-[48px] font-normal max-md:text-[28px] leading-[100%] mb-4 text-[#1d1d1d]">
                New to art?
              </h2>
              <p className="text-[18px] max-md:text-[16px] leading-[120%] tracking-[0] text-[#696969] mt-[16px]">
                Asign is an art space that welcomes all.
              </p>
              <hr className="border-t-[1px] border-[#cfcfcf] w-full mb-[40px] max-md:mb-[32px] max-md:mt-[16px] mt-[24px] opacity-[0.3]" />
              <ul className="space-y-4 text-[#696969] text-[16px] max-md:text-[14px] ml-[18px] font-normal leading-[120%] tracking-[0] list-square">
                <li>Find resources on all your art-related curiosities</li>
                <li>Interact with seasoned collectors and artists</li>
                <li>Become a part of a community of art enthusiasts</li>
              </ul>
              <a
                href="/everyone"
                role="button"
                aria-label="Read More"
                className="inline-block mt-[40px] max-sm:w-full max-md:text-[12px] max-md:mb-[48px] max-md:text-center px-[28px] py-[14px] text-[14px] font-medium border login-btn hover:border-[#1d1d1d]  border-gray-400 rounded-full text-gray-800 transition duration-300"
              >
                READ MORE
              </a>
            </div>
          </div>
        </section>
        {/* How it Works Section */}
        <section
          className=" md:pb-[100px] 2xl:container max-md:pt-[60px] max-sm:px-[20px] max-md:px-[24px] lg:px-[40px] max-2xl:px-[40px] 2xl:px-0"
          aria-labelledby="howitworks-heading"
        >
          {/* Header Section */}
          <div className=" mb-[80px] max-md:mb-[40px] max-sm:flex-col flex justify-between">
            <h2
              id="howitworks-heading"
              className="text-[48px] max-md:text-[28px] tracking-[0] font-normal text-[#1d1d1d] leading-[100%]"
            >
              Asign of things to come...
            </h2>

            <p className="text-[18px] leading-[120%] max-md:text-[16px] max-sm:w-full max-sm:mt-[12px] max-md:pr-[32px] tracking-[0] text-[#696969] max-md:w-[360px] w-full max-w-[592px]">
              Transform your artworks and collectibles into the asset class of
              tomorrow. Manage, trade and track the value of your collection
              with Asign. Our platform guarantees easy and secure transactions
              between buyers and sellers.
            </p>
          </div>

          {/* Card Grid */}
          <div className="grid grid-cols-1 max-sm:flex max-sm:flex-col sm:grid-rows-2 md:grid-cols-3 gap-y-20 max-sm:gap-y-[28px] gap-x-10">
            {/* Card 1 */}
            <div
              className="border-t-2 border-gray-300 pt-[20px] flex max-sm:justify-between max-sm:flex-row flex-col"
              style={{ borderTop: "1px solid #e5e5e5" }}
            >
              <h3 className="text-[40px] font-normal max-md:text-[24px] max-md:leading-[120%] text-[#1d1d1d] leading-[100%] tracking-[0] mb-[24px]">
                Gather
              </h3>

              <p className="text-[16px] max-md:max-w-[80%] max-sm:w-[calc(50%+10px)] max-md:text-[14px] max-md:leading-[120%] font-normal text-[#696969] leading-[120%] tracking-[0] max-w-[75%]">
                Unites all art and collectibles enthusiasts on a common
                platform.
              </p>
            </div>
            {/* Card 2 */}
            <div
              className="border-t-2 border-gray-300 pt-[20px] flex max-sm:justify-between max-sm:flex-row flex-col"
              style={{ borderTop: "1px solid #e5e5e5" }}
            >
              <h3 className="text-[40px] font-normal  max-md:text-[24px] max-md:leading-[120%] text-[#1d1d1d] leading-[100%] tracking-[0] mb-[24px]">
                Secure
              </h3>
              <p className="text-[16px] max-md:max-w-[80%] max-sm:w-[calc(50%+10px)] max-md:text-[14px] font-normal text-[#696969] leading-[120%] tracking-[0] max-w-[75%]">
                Verifies users with physical KYC and authenticates artwork via
                our proprietary technology.
              </p>
            </div>
            {/* Card 3 */}
            <div
              className="border-t-2 border-gray-300 pt-[20px] flex max-sm:justify-between max-sm:flex-row flex-col"
              style={{ borderTop: "1px solid #e5e5e5" }}
            >
              <h3 className="text-[40px] font-normal  max-md:text-[24px] max-md:leading-[120%] text-[#1d1d1d] leading-[100%] tracking-[0] mb-[24px]">
                Catalogue
              </h3>
              <p className="text-[16px] max-md:max-w-[80%] max-sm:w-[calc(50%+10px)] max-md:text-[14px] font-normal text-[#696969] leading-[120%] tracking-[0] max-w-[75%]">
                Documents the past, present, and future of art objects on a
                registry.
              </p>
            </div>
            {/* Card 4 */}
            <div
              className="border-t-2 border-gray-300 pt-[20px] flex max-sm:justify-between max-sm:flex-row flex-col"
              style={{ borderTop: "1px solid #e5e5e5" }}
            >
              <h3 className="text-[40px] font-normal  max-md:text-[24px] max-md:leading-[120%] text-[#1d1d1d] leading-[100%] tracking-[0] mb-[24px]">
                Guide
              </h3>
              <p className="text-[16px] max-md:max-w-[80%] max-sm:w-[calc(50%+10px)] max-md:text-[14px] font-normal text-[#696969] leading-[120%] tracking-[0] max-w-[75%]">
                Assists in the discovery, selection, and sale of art and
                collectibles.
              </p>
            </div>
            {/* Card 5 */}
            <div
              className="border-t-2 border-gray-300 pt-[20px] flex max-sm:justify-between max-sm:flex-row flex-col"
              style={{ borderTop: "1px solid #e5e5e5" }}
            >
              <h3 className="text-[40px] font-normal  max-md:text-[24px] max-md:leading-[120%] text-[#1d1d1d] leading-[100%] tracking-[0] max-sm:mb-0 mb-[24px]">
                Sell
              </h3>
              <p className="text-[16px] max-md:max-w-[80%] max-sm:w-[calc(50%+10px)] max-md:text-[14px] font-normal text-[#696969] leading-[120%] tracking-[0] max-w-[75%]">
                Enables authentic sale of art and collectibles.
              </p>
            </div>
            {/* Card 6 */}
            <div
              className="border-t-2 border-gray-300 pt-[20px] flex max-sm:justify-between max-sm:flex-row flex-col"
              style={{ borderTop: "1px solid #e5e5e5" }}
            >
              <h3 className="text-[40px] font-normal  max-md:text-[24px] max-md:leading-[120%] text-[#1d1d1d] leading-[100%] tracking-[0] mb-[24px] max-md:mb-[60px]">
                Track
              </h3>
              <p className="text-[16px] max-md:max-w-[80%] max-sm:w-[calc(50%+10px)] max-md:text-[14px] font-normal text-[#696969] leading-[120%] tracking-[0] max-w-[75%]">
                Keeps a record of an object throughout its lifetime.
              </p>
            </div>
          </div>
        </section>

        <section className="flex flex-col md:flex-row items-start max-sm:flex-col-reverse bg-[#eeeeea]  px-[48px] mb-[40px] max-md:mb-[0px] max-md:px-[24px] 2xl:container">
          {/* Left Section */}
          <div className="md:w-1/2 space-y-6 mb-8 max-sm:mb-[60px] pt-[120px] max-md:pt-[90px] md:mb-0">
            <h2 className="text-[48px] leading-[100%] tracking-0 max-md:text-[28px] font-normal text-[#1D1D1D]">
              Always a good deal
            </h2>

            <p className="text-[18px] max-md:text-[16px] tracking-0 max-md:max-w-[80%] text-[#4f4f4f] !mt-[16px] tracking-0 leading-[120%]">
              A versatile marketplace that supports a variety of transactions.
            </p>

            {/* Cards */}
            <div className="grid grid-cols-1 md:grid-cols-2 max-md:grid-cols-2 !mt-[80px]  max-md:!mt-[40px] max-md:gap-x-[20px] max-md:gap-y-[32px] gap-x-[40px] gap-y-[80px]">
              {/* Fixed Prices */}

              <div className="flex items-start max-md:items-baseline max-md:flex-col space-x-4 max-md:space-x-0">
                <img
                  src="./prelogin-page-images/icon__wallet.svg"
                  alt="icon wallet"
                  className="w-[32px] h-[32px] max-md:w-[40px] max-md:h-[40px]"
                />
                <div>
                  <p className="text-[36px] max-md:text-[20px] max-md:leading-[120%] max-md:mt-[20px] font-normal leading-[100%] tracking-0 text-[#1d1d1d]">
                    Fixed prices
                  </p>
                  <p className="text-[16px] font-normal max-md:mt-[14px] max-md:text-[14px] max-md:max-w-[90%] leading-[120%] tracking-0 text-[#4f4f4f] mt-[16px] max-w-full">
                    Artworks available at a fixed price on the marketplace.
                  </p>
                </div>
              </div>

              {/* Offers */}
              <div className="flex items-start  max-md:space-x-0 space-x-4 max-md:!mx-0 max-md:items-baseline max-md:flex-col">
                <img
                  src="./prelogin-page-images/icon__offer.svg"
                  alt="icon offer"
                  className="w-[32px] h-[32px] max-md:w-[40px] max-md:h-[40px]"
                />
                <div>
                  <p className="text-[36px] font-normal max-md:text-[20px] max-md:mt-[20px] max-md:leading-[120%] leading-[100%] tracking-0 text-[#1d1d1d]">
                    Offers
                  </p>
                  <p className="text-[16px] font-normal max-md:mt-[14px] max-md:text-[14px] max-md:max-w-[90%] leading-[120%] tracking-0 text-[#4f4f4f] mt-[16px] max-w-full">
                    Buyers and sellers can negotiate on a price for select
                    artworks.
                  </p>
                </div>
              </div>

              {/* Auction */}
              <div className="flex items-start space-x-4 max-md:space-x-0 max-md:items-baseline max-md:flex-col">
                <img
                  src="./prelogin-page-images/icon__auction.svg"
                  alt="icon auction"
                  className="w-[32px] h-[32px] max-md:w-[40px] max-md:h-[40px]"
                />
                <div>
                  <p className="text-[36px] font-normal  max-md:text-[20px] max-md:mt-[20px] max-md:leading-[120%] leading-[100%] tracking-0 text-[#1d1d1d]">
                    Auction
                  </p>

                  <p className="text-[16px] max-md:mt-[14px] max-md:text-[14px] max-md:max-w-[90%] font-normal leading-[120%] tracking-0 text-[#4f4f4f] mt-[16px] max-w-full">
                    Participate and bid in regularly curated auctions.
                  </p>
                </div>
              </div>

              {/* Reserve */}
              <div className="flex items-start max-md:space-x-0 space-x-4 max-md:items-baseline max-md:flex-col">
                <img
                  src="./prelogin-page-images/icon__reserve.svg"
                  alt="icon reserve"
                  className="w-[32px] h-[32px] max-md:w-[40px] max-md:h-[40px]"
                />
                <div>
                  <p className="text-[36px] font-normal max-md:text-[20px] max-md:mt-[20px] max-md:leading-[120%] leading-[100%] tracking-0 text-[#1d1d1d]">
                    Reserve
                  </p>

                  <p className="text-[16px] max-md:mt-[14px] max-md:text-[14px] max-md:max-w-[90%] font-normal leading-[120%] tracking-0 text-[#4f4f4f] mt-[16px] max-w-full">
                    Buyers can pay a deposit to ‘hold’ an artwork for a certain
                    time period.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Right Section - Image */}
          <div className="md:w-1/2 flex justify-center max-sm:pt-[60px]">
            <img
              src="./prelogin-page-images/31694660346desk1.webp"
              alt="Discover the artists you might like"
              className="w-full max-w-md md:max-w-[375px] object-cover mt-[-6px]"
            />
          </div>
        </section>

        <Featured />

        {/* Similar sections */}

        <section className="flex flex-col max-sm:flex-col-reverse max-sm:pt-[80px] max-sm:pr-[20px] max-sm:pl-[20px] max-sm:pb-[20px] md:flex-row-reverse my-[100px] 2xl:container bg-[#373737] max-md:my-[60px] max-md:gap-[40px]">
          {/* Left Section */}
          <div className="lg:w-1/2">
            <div className="relative">
              <picture className="md:flex">
                <source
                  media="(max-width: 768px)"
                  srcSet="./prelogin-page-images/461694660368awaitm1.webp"
                />
                <img
                  src="./prelogin-page-images/71694660368awd1.webp"
                  alt="Image description"
                  className="w-full h-full object-cover max-md:h-[415px]"
                />
              </picture>
            </div>
          </div>
          {/* Right Section */}
          <div className="lg:w-1/2 flex items-center pl-[80px]  tracking-[0]  max-sm:px-[0px] max-md:pl-[24px] pr-[48px] ">
            <div className="w-full">
              <h2 className="text-[48px] font-normal max-md:text-[28px] text-[#fff] leading-[100%] mb-4  ">
                What awaits you
              </h2>
              <p className="text-[18px] max-md:text-[16px] text-[#fff] leading-[120%] tracking-[0] opacity-[0.8] mt-[16px]">
                Signing up with Asign unlocks a treasure trove of features.
              </p>
              <hr className="border-t-[1px] border-[#cfcfcf] w-full mb-[40px] max-md:mb-[32px] max-md:mt-[16px] mt-[24px] opacity-[0.4]" />
              <ul className="space-y-4 text-[#fff] text-[16px]  opacity-[0.8] max-md:text-[14px] ml-[18px] font-normal leading-[120%] tracking-[0] list-square">
                <li>Insightful videos</li>
                <li>Comprehensive art resources</li>
                <li>Latest news and trends</li>
                <li>Real-time market data… and so much more</li>
              </ul>
            </div>
          </div>
        </section>

        <section
          className="featured art-market-reports uptopro font-sh5 2xl:container max-md:pl-5 pt-0"
          id="art-mkt"
        >
          <h2 className="featured__title font-normal">Art Market Reports</h2>
          <div id="" role="" className="featured__tabPanel">
            <div className="featured__arts">
              {reportsData.map((report, index) => (
                <div className="featured__art" key={index}>
                  <a
                    className="artworks-click"
                    href={`./art-market-reports/${report.pdfName}.pdf`}
                    title={report.title}
                    download
                  >
                    <div className="featured__artImgWrapper">
                      <img
                        src={`./market_report_images/${report.desktop_image}.png`}
                        alt={report.title}
                        className="featured__artImg"
                      />
                    </div>
                    <p className="featured__artName auction-report md:leading-4">
                      {report.category}
                    </p>
                    <p className="featured__artName auction-name md:leading-4">
                      {report.title}
                    </p>
                    <p className="featured__artName auction-year md:leading-4">
                      {report.year}
                    </p>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="securely flex flex-col 2xl:container md:flex-row items-start md:items-start justify-between py-12 max-md:pb-[60px] max-sm:px-[20px] md:pt-[120px] max-md:px-[24px] lg:px-[40px] max-2xl:px-[40px] 2xl:px-0   bg-white">
          {/* Left Section */}
          <div className="securely__left lg:w-1/2">
            <h2 className="securely__title text-[48px] max-lg:text-[28px] tracking-0 leading-[100%] font-normal text-[#1d1d1d] mb-4">
              Get Asign securely
            </h2>

            <p className="securely__para text-[#4f4f4f] max-lg:text-[16px] max-md:leading-[120%] max-md:pr-[32px] max-md:mt-[12px] max-md:max-w-full leading-[140%] text-[18px] mt-[16px] tracking-0 max-w-[95%]">
              Every user on Asign is KYC-verified, all data is encrypted and
              securely stored, and all artworks on the platform are guaranteed
              authentic.
            </p>
          </div>

          {/* Right Section */}
          <div className="securely__right lg:w-1/2 mt-[40px] md:mt-0 max-md:w-full ">
            <ul className="securely__list list-none space-y-6 max-md:space-y-5">
              <li className="securely__listItem text-[#4f4f4f] text-[20px] max-lg:text-[14px] bdr-top tracking-0 leading-[120%] border-t pt-6">
                1. Add personal information
              </li>
              <li className="securely__listItem text-[#4f4f4f] text-[20px] max-lg:text-[14px] bdr-top tracking-0 leading-[120%] border-t max-md:pt-[20px] pt-6">
                2. Add your Aadhaar and PAN
                {/* Tooltip */}
                <div className="securely__tooltip ml-2 relative inline-block group">
                  <button
                    className="m-tooltip__trigger focus:outline-none"
                    aria-describedby="aadhaarPanTooltip"
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M10.0007 1.66537C5.41732 1.66537 1.66732 5.41537 1.66732 9.9987C1.66732 14.582 5.41732 18.332 10.0007 18.332C14.584 18.332 18.334 14.582 18.334 9.9987C18.334 5.41537 14.584 1.66537 10.0007 1.66537Z"
                        stroke="#696969"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10 13.334L10 9.16732"
                        stroke="#696969"
                        strokeLinecap="square"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.0059 6.66797L9.99837 6.66797"
                        stroke="#696969"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  {/* Tooltip Content */}
                  <div
                    id="aadhaarPanTooltip"
                    className="m-tooltip__content absolute w-56 bg-[#eeeeea] text-[#696969] text-[12px] leading-[120%] p-3 left-1/2 transform -translate-x-1/2  opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-opacity duration-300"
                    role="tooltip"
                  >
                    We don’t store this
                    <br /> information. We partner with <br /> a government
                    authorised <br /> body to verify this data.
                  </div>
                </div>
              </li>
              <li className="securely__listItem text-[#4f4f4f] text-[20px] max-lg:text-[14px] bdr-top tracking-0 leading-[120%] border-t pt-6">
                3. Get Asign
              </li>
            </ul>
          </div>
        </section>
        {/* Similar sections */}
        <section className="flex flex-col max-sm:flex-col-reverse max-sm:pt-[80px] max-sm:pr-[20px] max-sm:pl-[20px] max-sm:pb-[20px] md:flex-row mt-[100px] max-md:mt-0 2xl:container bg-[#eeeeea]">
          {/* Left Section */}
          <div className="md:w-1/2">
            <div className="relative">
              <picture className="md:flex">
                <source
                  media="(max-width: 768px)"
                  srcSet="./prelogin-page-images/151694660403asm1.webp"
                />
                <img
                  src="./prelogin-page-images/14170679346151706765469151694660403asm1.webp"
                  alt="Image description"
                  className="w-full h-full object-cover max-md:h-[415px]"
                />
              </picture>
              <figcaption className="absolute bottom-6 left-12 text-white font-eb text-[18px] leading-[120%] tracking-[0] italic">
                Sayed Haider Raza, Bindu, 1980
              </figcaption>
            </div>
          </div>
          {/* Right Section */}
          <div className="md:w-1/2 flex items-center pl-[80px] max-md:pl-[24px]  tracking-[0] max-sm:px-[0px] pr-[48px] ">
            <div className="w-full">
              <h2 className="text-[48px] font-normal max-md:text-[28px] leading-[100%] mb-4 text-[#1d1d1d]">
                Asset class of tomorrow
              </h2>
              <p className="text-[18px] max-md:text-[16px] max-md:mb-[48px] leading-[120%] tracking-[0] text-[#696969] mt-[16px]">
                Developing a framework for safe transactions between buyers and{" "}
                <br className="hidden max-sm:block" /> sellers within the
                ecosystem and transforming artworks and{" "}
                <br className="hidden max-sm:block" /> collectibles into
                reliable instruments for building wealth.
              </p>
            </div>
          </div>
        </section>
        <Accordion page="landing_page" />
        {/* Footer */}
        <Footer />
      </div>
    </>
  );
}
