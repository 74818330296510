import useCountryState from "@/apis/countryState";

// Create our number formatter.
export const formatInRuppes = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "Inr",
});

// this helps to get the if the user if already or follow the particular object

export const findById = (targetArray?: string[], target?: string): boolean => {
  if (!targetArray || !target) {
    return false;
  }
  return targetArray.includes(target);
};
export const getId = (item: any): string => item?.id || item?._id;

export const capitalizeWords = (str: string) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

// Constants for conversion
// Constants for conversion
const CM_TO_INCH = 0.393701;
const INCH_TO_CM = 2.54;

export const formatDimensions = (object: any): string => {
  const { metaData } = object || {};
  const {
    height,
    width,
    depth,
    diameter,
    dimensionsType = "cm",
  } = metaData || {};

  if (!height || !width) {
    return "Dimensions not available";
  }

  const convertValue = (value?: number, toInch?: boolean) =>
    value
      ? Number((toInch ? value * CM_TO_INCH : value * INCH_TO_CM).toFixed(1))
      : undefined;

  // Always show inches first, then centimeters
  const inchValues =
    dimensionsType === "cm"
      ? {
          height: convertValue(height, true),
          width: convertValue(width, true),
          depth: convertValue(depth, true),
          diameter: convertValue(diameter, true),
        }
      : { height, width, depth, diameter };

  const cmValues =
    dimensionsType === "cm"
      ? { height, width, depth, diameter }
      : {
          height: convertValue(height, false),
          width: convertValue(width, false),
          depth: convertValue(depth, false),
          diameter: convertValue(diameter, false),
        };

  // Format the dimensions string
  let dimensionsString = `${formatNumber(
    inchValues.height,
  )} in × ${formatNumber(inchValues.width)} in`;

  if (depth) {
    dimensionsString += ` × ${formatNumber(inchValues.depth!)} in`;
  }

  // Add centimeter values
  dimensionsString += ` (${formatNumber(cmValues.height)} cm × ${formatNumber(
    cmValues.width,
  )} cm`;

  if (depth) {
    dimensionsString += ` × ${formatNumber(cmValues.depth!)} cm`;
  }

  // Add diameter if present
  if (diameter) {
    dimensionsString += ` (Diameter: ${formatNumber(
      inchValues.diameter!,
    )} in | ${formatNumber(cmValues.diameter!)} cm)`;
  }
  dimensionsString += ")";
  return dimensionsString;
};

// Helper function to format numbers to 2 decimal places and remove trailing zeros
const formatNumber = (num: number): string => {
  return Number(num?.toFixed(2))?.toString();
};

export function formatArtMaterials(medium: string[], surface: string[]) {
  // Join mediums with commas and 'and' before the last item if multiple items exist
  const mediumString =
    medium?.length > 1
      ? medium?.slice(0, -1).join(", ") + " and " + medium.slice(-1)
      : medium?.join(", ");

  // Join surfaces with commas and 'and' before the last item if multiple items exist
  const surfaceString =
    surface?.length > 1
      ? surface?.slice(0, -1).join(", ") + " and " + surface?.slice(-1)
      : surface?.join(", ");

  // Combine medium and surface with 'on'
  return `${mediumString} on ${surfaceString}`;
}

export const fetchCountries = async () => {
  try {
    let { getCountries, getStates } = useCountryState();
    let countries = await getCountries();
    // sort countries
    countries = countries.sort((a: any, b: any) => {
      return a.name === "India" ? -1 : b.name === "India" ? 1 : 0;
    });
    let states = await getStates("IN");
    return { countries, states };
  } catch (error) {
    console.log(error);
  }
};

export const getCookieValue = (name: string): string | null => {
  const match = document.cookie
    .split("; ")
    .find((cookie) => cookie.startsWith(name + "="));
  return match ? decodeURIComponent(match.split("=")[1]) : null;
};
