import React from "react";
import { Link } from "react-router-dom";
const Footer = ({ className = "" }: { className?: string }) => {
  return (
    <footer
      className={
        "bg-[#303030]  p-[80px_var(--viewportSidePadding)] grid grid-cols-6 gap-10 bottom-auto w-full max-md:py-12 max-md:px-5  max-md:grid-cols-[26px_1fr_1fr] max-md:gap-[0px] " +
        className
      }
    >
      {/* Logo */}
      <Link to="/#" className="w-max inline-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="100"
          viewBox="0 0 26 100"
          fill="none"
        >
          <g id="Group">
            <path
              id="Vector"
              d="M7.73599 25.5049V29.1567C4.70998 29.7632 2.87115 31.673 2.87115 35.5055C2.87115 38.6606 4.08414 40.964 6.63269 40.964H6.66495C9.39416 40.964 10.1813 38.8283 10.8588 35.983L11.6266 32.7247C12.5815 28.6793 14.5235 26.0985 18.5302 26.0985H18.5754C22.5047 26.0985 25.0855 29.731 25.0855 34.7442C25.0855 39.8413 22.5369 42.9963 18.1818 43.6351V40.0026C21.0594 39.2929 22.2273 37.467 22.2273 34.5635C22.2273 31.8021 20.9756 29.6793 18.6915 29.6793H18.6593C16.5882 29.6793 15.4333 31.0278 14.8268 33.5248L14.0396 36.7959C12.8912 41.5446 11.5427 44.5384 7.10369 44.5384H7.05207C2.41951 44.5384 0 40.6413 0 35.3636C0.0129041 29.318 3.32925 26.1501 7.73599 25.5049Z"
              fill="white"
            />
            <path
              id="Vector_2"
              d="M24.6359 47.2935V50.7776H0.466553V47.2935H24.6359Z"
              fill="white"
            />
            <path
              id="Vector_3"
              d="M12.5385 53.6804H12.5708C19.8551 53.6804 25.1006 58.4485 25.1006 65.7651C25.1006 71.4364 22.223 75.5141 17.4679 76.7593V73.1075C20.5261 72.1074 22.0553 69.1459 22.0553 65.7909C22.0553 60.4938 18.126 57.371 12.5708 57.371H12.5385C6.95107 57.371 3.02178 60.4938 3.02178 65.7909C3.02178 70.0815 5.6026 73.6172 10.0545 73.6172H10.2352V65.2167H13.1644V76.94H0.473231V74.0817L4.13798 74.0366C1.91849 72.6558 0.0151367 69.7137 0.0151367 65.6876C0.0151367 58.4614 5.22838 53.6804 12.5385 53.6804Z"
              fill="white"
            />
            <path
              id="Vector_4"
              d="M24.6359 80.7212V85.2892L4.79587 96.6319V96.6642L9.95749 96.6319H24.6359V99.9999H0.466553V95.3996L20.3066 84.0892V84.0569L15.1449 84.0892H0.466553V80.7212H24.6359Z"
              fill="white"
            />
            <g id="Group_2">
              <path
                id="Vector_5"
                d="M10.5176 0H14.4598L24.6475 24.1629H20.8344L17.5503 16.2591C17.5503 16.2591 15.4082 17.3043 12.2919 17.3043C9.17557 17.3043 7.0593 16.2527 7.0593 16.2527L3.80103 24.1629H0.329834L10.5176 0ZM16.376 13.4525L12.2854 3.65185H12.2532L8.20777 13.4654C8.20777 13.4654 9.89175 14.2848 12.2919 14.2848C14.6921 14.2848 16.376 13.4525 16.376 13.4525Z"
                fill="white"
              />
            </g>
          </g>
        </svg>
      </Link>

      {/* Links */}

      <ul className="list-none flex gap-[24px] max-md:pl-[40px] max-md:text-[14px] flex-col col-start-3">
        <li>
          <Link
            to="/artist"
            className="text-white hover:text-white max-md:leading-[16.8px]"
          >
            Artists
          </Link>
        </li>
        <li>
          <Link
            to="/business"
            className="text-white hover:text-white max-md:leading-[16.8px]"
          >
            Business
          </Link>
        </li>
        <li>
          <Link
            to="/collectors"
            className="text-white hover:text-white max-md:leading-[16.8px]"
          >
            Collectors
          </Link>
        </li>
        <li>
          <Link
            to="/everyone"
            className="text-white hover:text-white max-md:leading-[16.8px]"
          >
            Everyone
          </Link>
        </li>
      </ul>
      <ul className="list-none flex gap-[24px] max-md:text-[14px] flex-col max-md:pl-[89px]">
        <li>
          <Link
            to="/contact"
            className="text-[#cccccc] hover:text-white max-md:leading-[16.8px]"
          >
            Contact Us
          </Link>
        </li>
        <li>
          <Link
            to="/privacy-policy"
            className="text-[#cccccc] hover:text-white max-md:leading-[16.8px]"
          >
            Privacy Policy
          </Link>
        </li>
        <li>
          <Link
            to="/terms-and-conditions"
            className="text-[#cccccc] hover:text-white "
          >
            Terms &amp; Conditions
          </Link>
        </li>
      </ul>

      {/* Copyright */}
      <p className="col-start-5 max-md:pt-[0px] col-span-2 ml-auto text-[#cccccc] text-left text-base max-md:text-[14px] font-normal leading-[120%] max-md:col-start-4 max-md:pl-[89px]">
        <span className="all-inherit all-unset inline">
          ©2025 <br className="max-md:block md:hidden" />
        </span>
        Democrart Technologies <br /> Private Limited
      </p>
    </footer>
  );
};

export default Footer;
