import { QueryData } from "@/hooks/useFilter";
import { apis, baseUrl } from ".";

const useSearch = () => {
  const autoSuggestion = async (query: string, category: string = "all") => {
    const { data } = await apis.get(
      `${baseUrl}/client/api/v1/search/auto-suggest`,
      {
        params: {
          query,
          category,
        },
      },
    );
    return data?.data;
  };

  const searchData = async (params: QueryData) => {
    if (!params?.query?.searchText) return;
    const { data } = await apis.post(`${baseUrl}/client/api/v1/search`, {
      data: params,
    });
    return data?.data;
  };

  const filterData = async (params: QueryData, platform: string) => {
    // console.log("params?.query?.selectedFilters", params?.query);
    if (!platform || !params?.query?.selectedFilters) return;
    const { data } = await apis.get(
      `${baseUrl}/client/api/v1/search/platform/${platform}`,
      {
        data: params,
      },
    );
    return data?.data;
  };

  return { autoSuggestion, searchData, filterData };
};

export default useSearch;
