import React from "react";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Banner from "./Components/Banner";
import bannerConfig from "../../constant/banners.json";
import Accordion from "./Components/Faqs";
import Featured from "./Components/Featured";
export default function Everyone() {
  return (
    <>
      <div className="font-sh5 mx-auto px-0">
        <Header />

        <Banner
          desktopImage={bannerConfig.everyone.desktopImage}
          mobileImage={bannerConfig.everyone.mobileImage}
          page="everyone"
        />

        <section className="securely flex flex-col 2xl:container md:flex-row lg:px-[48px] max-2xl:px-[48px] max-md:py-[60px] max-md:pt-[40px] items-start md:items-start justify-between max-md:mt-[160px] py-[100px] max-md:px-[24px] mt-[100px] bg-white">
          {/* Left Section */}
          <div className="securely__left lg:w-1/2">
            <h2 className="securely__title text-[48px] max-md:text-[28px] tracking-0 leading-[100%] font-normal text-[#1d1d1d] mb-4">
              Get started
            </h2>

            <p className="securely__para text-[#4f4f4f] max-md:text-[16px] max-md:leading-[120%] max-md:pr-[32px] max-md:mt-[12px] max-md:max-w-full leading-[140%] text-[18px] mt-[16px] tracking-0 max-w-[95%]">
              The art world is vast, yet it doesn’t have to be confusing. Asign
              provides extensive resources to help you navigate through the art
              world.
            </p>
          </div>

          {/* Right Section */}
          <div className="securely__right lg:w-1/2 mt-6 md:mt-0 max-md:w-full ">
            <ul className="securely__list list-none space-y-6 max-md:space-y-5">
              <li className="securely__listItem text-[#4f4f4f] text-[20px] max-md:text-[14px] bdr-top tracking-0 leading-[120%]  pt-6">
                Learning programs
              </li>
              <li className="securely__listItem text-[#4f4f4f] text-[20px] max-md:text-[14px] bdr-top tracking-0 leading-[120%] border-t max-md:pt-[20px] pt-6">
                Custom search rooms
                {/* Tooltip */}
              </li>
              <li className="securely__listItem text-[#4f4f4f] text-[20px] max-md:text-[14px] bdr-top tracking-0 leading-[120%] border-t pt-6">
                Artist and gallery stories
              </li>
            </ul>
          </div>
        </section>

        <section className="2xl:container  py-[100px] lg:px-[48px] max-2xl:px-[48px] max-md:py-[60px] max-sm:px-[20px] bg-[#eeeeea] max-md:px-[24px]  ">
          <h2 className="text-[#1d1d1d] text-[48px] max-md:text-[28px] mr-[2px] pb-[2px] text-center max-md:text-start font-normal leading-[100%]">
            Get informed
          </h2>
          <p className="text-[18px] leading-[120%] max-sm:text-[16px] mt-[15px]  tracking-normal max-md:text-start text-center text-[#696969] max-md:mb-[32px] mb-[80px]">
            Asign provides helpful tools and materials from the art world to
            equip you
            <br />
            with the best practices before you deep-dive into the world of art.
          </p>
          <div className="max-w-full mx-auto grid grid-cols-1 md:grid-cols-3 gap-12 text-start">
            {/* Artist Profile Pages */}
            <div className="flex flex-col items-start">
              <img
                src="./prelogin-page-images/icon_videos.svg"
                className="w-[48px] h-[48px] md:ml-[8px] max-sm:w-[40px] max-sm:h-[40px]"
                alt=""
              />
              <h3 className="text-[28px] leading-[100%] ml-[8px] max-md:text-[20px] max-md:mt-[24px] tracking-normal font-normal mb-4 mt-[40px]">
                Insightful videos
              </h3>
            </div>

            {/* Resale Rights */}
            <div className="flex flex-col items-start">
              <img
                src="./prelogin-page-images/icon_blogs.svg"
                className="w-[48px] h-[48px] max-sm:w-[40px] max-sm:h-[40px]"
                alt=""
              />
              <h3 className="text-[28px] max-md:text-[20px] max-md:mt-[24px] leading-[100%] tracking-normal font-normal mb-4 mt-[40px]">
                Informative blog articles
              </h3>
            </div>

            <div className="flex flex-col items-start md:right-2 relative">
              <img
                src="./prelogin-page-images/icon_marketplace.svg"
                className="w-[48px] h-[48px]  max-sm:w-[40px] max-sm:h-[40px]"
                alt=""
              />
              <h3 className="text-[28px] max-md:text-[20px] max-md:mt-[24px] leading-[100%] tracking-normal font-normal mb-4 mt-[40px] mr-[8px]">
                Curated global marketplace
              </h3>
            </div>
          </div>
        </section>

        <section className="securely flex flex-col 2xl:container pt-[120px] lg:px-[48px] max-2xl:px-[48px] md:flex-row items-start md:items-start justify-between py-[100px] max-md:py-[60px] max-md:px-[24px]  bg-white">
          {/* Left Section */}
          <div className="securely__left lg:w-1/2">
            <h2 className="securely__title text-[48px] max-md:text-[28px] tracking-0 leading-[100%] font-normal text-[#1d1d1d] mb-4">
              Get involved
            </h2>

            <p className="securely__para text-[#4f4f4f] max-md:text-[16px] max-md:leading-[120%] max-md:pr-[32px] max-md:mt-[12px] max-md:max-w-full leading-[140%] text-[18px] mt-[16px] tracking-0 max-w-[95%]">
              As you build your art collecting expertise, keep track of events
              in the art world.
            </p>
          </div>

          {/* Right Section */}
          <div className="securely__right lg:w-1/2 mt-6 md:mt-0 max-md:w-full ">
            <ul className="securely__list list-none space-y-6 max-md:space-y-5">
              <li className="securely__listItem text-[#4f4f4f] text-[20px] max-md:text-[14px] bdr-top tracking-0 leading-[120%]  pt-6">
                Exhibition and auction updates
              </li>
              <li className="securely__listItem text-[#4f4f4f] text-[20px] max-md:text-[14px] bdr-top tracking-0 leading-[120%] border-t max-md:pt-[20px] pt-6">
                Latest art trends
                {/* Tooltip */}
              </li>
              <li className="securely__listItem text-[#4f4f4f] text-[20px] max-md:text-[14px] bdr-top tracking-0 leading-[120%] border-t pt-6">
                Custom art experiences
              </li>
            </ul>
          </div>
        </section>

        <section className="2xl:container  py-[100px] lg:px-[48px] max-2xl:px-[48px] max-md:py-[60px] max-sm:px-[20px] bg-[#eeeeea] max-md:px-[24px]  ">
          <h2 className="text-[#1d1d1d] text-[48px] max-md:text-[28px] pb-[16px] text-center max-md:text-start font-normal leading-[100%]">
            Ready to buy your first work of
            <br />
            art? We’ll help you out.
          </h2>
          <p className="text-[18px] leading-[120%] max-sm:text-[16px] tracking-normal max-md:text-start text-center text-[#696969] max-md:mb-[32px] mb-[80px]">
            Be worry-free and keep collecting with the safest art transactions
            in the country.
          </p>
          <div className="max-w-full mx-auto grid grid-cols-1 md:grid-cols-3 gap-12 text-start">
            {/* Artist Profile Pages */}
            <div className="flex flex-col items-start">
              <img
                src="./prelogin-page-images/icon_verifiedMembers.svg"
                className="w-[48px] h-[48px]  max-sm:w-[40px] max-sm:h-[40px]"
                alt=""
              />
              <h3 className="text-[28px] leading-[100%] max-md:text-[20px] max-md:mt-[24px] tracking-normal font-normal mb-4 mt-[40px]">
                Verified members only
              </h3>
              <p className="text-[#696969] text-[18px] max-md:text-[14px] leading-[120%] tracking-normal ">
                Every user is KYC-verified.
              </p>
            </div>

            {/* Resale Rights */}
            <div className="flex flex-col items-start">
              <svg
                className="touser__cardIcon w-[48px] h-[48px] max-sm:w-[40px] max-sm:h-[40px]"
                width="48"
                height="48"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_8410_2)">
                  <mask
                    id="mask0_8410_2"
                    style={{ maskType: "luminance" }}
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="24"
                  >
                    <path d="M24 0H0V24H24V0Z" fill="white" />
                  </mask>
                  <g mask="url(#mask0_8410_2)">
                    <path
                      d="M20 10.1096V20H4V4H12.9036"
                      stroke="#1D1D1D"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M4.5 16.3545L8.78975 12.161C9.0094 11.9463 9.3656 11.9463 9.58525 12.161L12.9375 15.438M12.9375 15.438L15.821 12.6192C16.0407 12.4045 16.3969 12.4045 16.6166 12.6192L19.5 15.438M12.9375 15.438L15.0469 17.5"
                      stroke="#1D1D1D"
                      strokeLinecap="square"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.5 6L16.5 8L20 4.5"
                      stroke="#1D1D1D"
                      strokeLinecap="square"
                      strokeLinejoin="round"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_8410_2">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <h3 className="text-[28px] max-md:text-[20px] max-md:mt-[24px] leading-[100%] tracking-normal font-normal mb-4 mt-[40px]">
                Guaranteed authentic artwork
              </h3>
              <p className="text-[#696969] text-[18px] max-md:text-[14px] leading-[120%] tracking-normal ">
                All artworks on Asign are verified and authenticated.
              </p>
            </div>

            <div className="flex flex-col items-start">
              <img
                src="./prelogin-page-images/icon_blockchain.svg"
                className="w-[48px] h-[48px]  max-sm:w-[40px] max-sm:h-[40px]"
                alt=""
              />
              <h3 className="text-[28px] max-md:text-[20px] max-md:mt-[24px] leading-[100%] tracking-normal font-normal mb-4 mt-[40px]">
                Blockchain technology
              </h3>
              <p className="text-[#696969] text-[18px] max-md:text-[14px] leading-[120%] tracking-normal ">
                Digitally records and future-proofs an artwork's provenance.
              </p>
            </div>
          </div>
        </section>

        <Accordion page="everyone" />
        {/* Footer */}
        <Footer />
      </div>
    </>
  );
}
