import React, { createContext, useContext } from "react";
import {
  useQuery,
  UseQueryResult,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import useGlobalApis from "@/apis/global";
import useFilter from "@/hooks/useFilter";
import { getId } from "@/lib/helper";

// Create a new QueryClient instance
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
    },
  },
});

interface SchemaData {
  value: string;
  label: string;
}

interface MasterContextType {
  getSchema: (schemaName: string) => UseQueryResult<SchemaData[], Error>;
}

const MasterContext = createContext<MasterContextType>({} as MasterContextType);

export const MasterProvider = ({ children }: { children: React.ReactNode }) => {
  const { getOptions } = useGlobalApis();
  const { filterOption } = useFilter({
    query: {
      status: "active", //TODO: remove this after testing
    },
    options: {
      populate: "",
      select: ["name"],
    },
  });

  const fetchSchema = async (schemaName: string): Promise<SchemaData[]> => {
    const response = await getOptions({
      type: schemaName,
      query: filterOption,
    });

    if (!response || !response.data) {
      return [];
    }

    return response.data.map((item: any) => ({
      value: item.name,
      label: item.name,
    }));
  };

  const getSchema = (schemaName: string) => {
    return useQuery({
      queryKey: ["schema", schemaName],
      queryFn: () => fetchSchema(schemaName),
    });
  };

  const contextValue = {
    getSchema,
  };

  return (
    <QueryClientProvider client={queryClient}>
      <MasterContext.Provider value={contextValue}>
        {children}
      </MasterContext.Provider>
    </QueryClientProvider>
  );
};

export const useMaster = () => {
  const context = useContext(MasterContext);

  const useSchema = (schemaName: string) => {
    const query = context.getSchema(schemaName);
    // if (query.isLoading) {
    //   return [{
    //     value: "loading",
    //     label: "loading..."
    //   }];
    // }
    return query.data || [];
  };

  return {
    useSchema,
  };
};

// Example of prefetching data
export const prefetchSchema = async (schemaName: string) => {
  await queryClient.prefetchQuery({
    queryKey: ["schema", schemaName],
    queryFn: async () => {
      const { getOptions } = useGlobalApis();
      const { filterOption } = useFilter({
        options: {
          populate: "",
          select: ["name"],
        },
      });

      const response = await getOptions({
        type: schemaName,
        query: filterOption,
      });

      if (!response || !response.data) {
        return [];
      }

      return response.data.map((item: any) => ({
        value: item.name,
        label: item.name,
      }));
    },
  });
};

// function getModel(schemaName) {
//   const models = {
//     auctionHouse: AuctionHouse,
//     measurementType: MeasurementType,
//     medium: Medium,
//     movement: Movement,
//     objectType: ObjectType,
//     style: Style,
//     surface: Surface,
//     technique: Technique,
//     valuationStatus: ValuationStatus,
//     MAArtsit: MAArtsit,
//     ActionHouse: ActionHouse,
//     Fairs: Fairs,
//     Gallery: Gallery,
//     Transporter: Transporter,
//     VoidReason: VoidReason,
//     AuthenticatorReason: AuthenticatorReason,
//     CoverageType: CoverageType,
//     Inscription: Inscription,
//     ObjectCondition: ObjectCondition,
//     MaGst: MaGst,
//     MaProduct: MaProduct,
//     RejectedReasons: RejectedReasons,
//     RepresentReasons: RepresentReasons,
//     SiteCondition: SiteCondition,
//     SurfaceType: SurfaceType,
//     TimeZones: TimeZones,
//     Period: Period,
//     ExhibitionTypes: ExhibitionTypes,
//     AsignLocation: AsignLocation,
//     City: City,
//     Country: Country,
//     State: State,
//     Price: Price,
//     Shape: Shape,
//     Size: Size,
//     Subject: Subject,
//     AcquisitionType: AcquisitionType,
//     InsuranceType: InsuranceType,
//     ValuationApproach: ValuationApproach,
//     ValuationBasis: ValuationBasis,
//     ValuationType: ValuationType,
//     LabelDamage: LabelDamage,
//     ManufacturerName: ManufacturerName,
//     StockCheckType: StockCheckType,
//     StockTransferReasons: StockTransferReasons,
//     Years: Years,
//     Currency: Currency,
//     AdvisoryServiceList: AdvisoryServiceList,
//     MACategory: MACategory,
//     ConditionObservation: ConditionObservation,
//     DocumentType: DocumentType,
//     Era: Era,
//   };
//   return models[schemaName];
// }
