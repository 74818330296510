import { cn } from "@/lib/utils";
import Sidebar from "@/pages/profile/Sidebar";
import React, { FunctionComponent, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { MobileHeader } from "./MobileHeader";
import useMediaQuery from "@/hooks/use-media-query";
import FingureSearch from "@/components/Search/modal/fingureSearch";
import FigureSearch from "@/components/Search/modal/fingureSearch";

interface HeaderProps {
  scrollToSection?: (label: string) => void;
  currentSection?: string;
  className?: string;
  me?: any;
  totalItemsCount?: number;
}

const Header: React.FC<HeaderProps> = ({
  scrollToSection,
  currentSection,
  className,
  me,
  totalItemsCount = 1,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  let currentLocation = location.pathname;

  // Determine if we are on a parent route or a deeper route
  let isCatalogActive = currentLocation.startsWith("/catalog");
  let isBoardsActive = currentLocation.startsWith("/boards");
  const [searchParams] = useSearchParams();
  const catalogueRaisonne = Boolean(searchParams.get("catalogue_raisonne"));
  let isDiscoverActive = [
    "/discover/feed",
    "/business/feed",
    "/objects/feed",
    "/artist/feed",
    "/events/feed",
    "/catalogueraisonne/detail",
    "/artist/detail",
    "/business/detail",
    "/writing/detail",
    "/objects/view",
    "/events/detail",
    "/objects/detail",
    "/events/view",
    "/business/view",
    "/artist/view",
    "/location/view",
  ].some((path) => currentLocation.startsWith(path));

  let isProfileActive = ["/user/profile", "/account/details"].some((path) =>
    currentLocation.startsWith(path),
  );

  let userProfileActive = ["/user/profile"].some((path) =>
    currentLocation.startsWith(path),
  );

  const parentRoutes = [
    "/discover/feed",
    "/business/feed",
    "/objects/feed",
    "/objects/detail",
    "/artist/feed",
    "/events/feed",
    "/catalog/detail",
  ];
  const isParentRoute = parentRoutes.includes(currentLocation);
  const isDesktop = useMediaQuery("(min-width: 1000px)");
  const isProduction = import.meta.env.VITE_ENV === "production";
  let sideBar = [
    { label: "Biography" },
    // { label: "Catalogue rasionné" },
    { label: "Representation" },
    { label: "Education" },
    { label: "Events" },
    { label: "Recognition" },
    { label: "Press" },
    { label: "Notable collections" },
    { label: "Publications" },
  ];
  const pathname = location.pathname;
  if (me) {
    sideBar = [
      { label: `${me?.userRole === "artist" ? "Biography" : "Overview"}` },
      // { label: "Catalogue rasionné" },
      {
        label: `${
          me?.userRole === "artist" ? "Representation" : "Represented artists"
        }`,
      },
      { label: `${me?.userRole === "artist" ? "Education" : ""}` },
      { label: "Events" },
      { label: "Recognition" },
      { label: "Press" },
      {
        label: `${
          me?.userRole === "artist" ? "Notable collections" : "Notable sales"
        }`,
      },
      { label: "Publications" },
    ];
  }

  if (catalogueRaisonne) {
    isDiscoverActive = true;
    isCatalogActive = false;
  }
  if (pathname === "/catalog/add/object") {
    isDiscoverActive = false;
    isCatalogActive = true;
  }
  if (isDiscoverActive) {
    isCatalogActive = false;
  }

  return (
    <>
      <header
        className={cn(
          "self-stretch z-[20] mq750:items-center bg-white box-border flex flex-row items-start justify-between py-0 w-full max-w-full gap-[20px] text-left text-sm text-grey100 font-paragraph-p3 border-b-[1px] border-solid border-[#e5e5e5] mq725:box-border font-sh5 font-normal mq450:flex mq450:items-center mq450:px-5 px-[48px] sticky top-0",
          className,
        )}
      >
        <div className="box-border flex flex-row items-center justify-start max-w-full gap-6 py-0 pl-0 overflow-hidden shrink-0 mq450:justify-between mq450:pr-5 mq450:box-border mq725:box-border">
          <img
            loading="lazy"
            alt="Aisgn Logo"
            src="/logo.svg"
            className="cursor-pointer"
            onClick={() => {
              const navigateTo = isProduction
                ? "/catalog/detail"
                : "/discover/feed";
              navigate(navigateTo);
            }}
          />
          <div className="flex flex-row items-center justify-start mq750:hidden">
            <div
              className={cn(
                "flex flex-row items-center justify-center p-4 gap-[8px] cursor-pointer text-gray-60",
                isDiscoverActive && "text-gray-100",
              )}
              onClick={() => {
                if (isProduction) return;
                navigate("/discover/feed");
              }}
            >
              {isDiscoverActive ? (
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  loading="lazy"
                  alt=""
                  src="/menu-icons.svg"
                />
              ) : (
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  loading="lazy"
                  alt=""
                  src="/discover-inactive.svg"
                />
              )}
              <div className="relative text-sm leading-[17px] inline-block min-w-[53px]">
                Discover
              </div>
            </div>

            <a
              className="flex flex-row items-center justify-center p-4 gap-[8px] text-gray-60 cursor-pointer"
              href="/catalog/detail"
              // navigate like href
            >
              {isCatalogActive ? (
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/catalog-active.svg"
                />
              ) : (
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src="/menu-icons-1.svg"
                />
              )}
              <div
                className={cn(
                  "relative leading-[17px] inline-block min-w-[53px] text-sm",
                  isCatalogActive && "text-gray-100",
                )}
              >
                Catalogue
              </div>
            </a>
            {!isProduction && (
              <a
                className="flex flex-row items-center justify-center p-4 gap-[8px] text-gray-60 cursor-pointer"
                href="/boards"
                // navigate like href
              >
                {isBoardsActive ? (
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0"
                    alt=""
                    src="/boardsSelecteddesktop.svg"
                  />
                ) : (
                  <img
                    className="relative w-6 h-6 overflow-hidden shrink-0"
                    alt=""
                    src="/boardUnselecteddesktop.svg"
                  />
                )}
                <div
                  className={cn(
                    "relative leading-[17px] inline-block min-w-[53px] text-sm",
                    isBoardsActive && "text-gray-100",
                  )}
                >
                  Boards
                </div>
              </a>
            )}
          </div>
        </div>
        <div className="box-border flex flex-col items-start justify-start max-w-full px-0 pt-2 pb-0 mq750:py-4">
          <div className="self-stretch flex flex-row items-center justify-start mq450:justify-end gap-[24px]">
            {!currentLocation.startsWith("/search") && totalItemsCount ? (
              <FingureSearch>
                <div
                  className={cn(
                    "flex items-center border border-solid border-[#CFCFCF] mq750:hidden mq450:hidden mq1000:hidden min-w-[232px] justify-center h-10 rounded-full transition-all duration-300 ease-in-out focus-within:justify-start focus-within:gap-2 focus-within:px-3",
                    isProduction && "hidden",
                  )}
                >
                  <img
                    src="/iconsearch.svg"
                    alt="Search Icon"
                    className="transition-all duration-300 ease-in-out"
                  />
                  <input
                    type="text"
                    className="w-full h-full placeholder:text-sm placeholder:font-sh5 max-w-[70px] tracking-[0.7px] font-sh5 font-normal outline-none transition-all duration-300 ease-in-out placeholder:text-center focus:placeholder:text-left focus:max-w-full border-transparent bg-transparent"
                    placeholder="Search"
                  />
                </div>
              </FingureSearch>
            ) : (
              <span
                className={cn(
                  "flex items-center border mq750:hidden mq450:hidden mq1000:hidden min-w-[232px] justify-center h-10 rounded-full transition-all duration-300 ease-in-out focus-within:justify-start focus-within:gap-2 focus-within:px-3",
                )}
              ></span>
            )}

            <div
              className={`flex-row items-center hidden gap-6 mq450:flex mq750:flex mq1000:flex ${
                isProduction ? "max-md:py-[12px]" : ""
              }`}
            >
              {totalItemsCount ? (
                <FigureSearch>
                  <img
                    className={`hidden w-6 h-6 mq450:block mq750:block mq1000:block ${
                      isProduction ? "max-md:!hidden" : ""
                    }`}
                    src="/iconsearch.svg"
                  />
                </FigureSearch>
              ) : (
                <span className="w-6 h-6"></span>
              )}
              <Sidebar>
                <div className="flex flex-col items-start justify-start px-0 pb-0 cursor-pointer [@media(min-width:751px)]:hidden mq750:hidden">
                  {!userProfileActive ? (
                    <img
                      className="relative w-6 h-6 overflow-hidden shrink-0"
                      alt=""
                      src="/button-1.svg"
                    />
                  ) : (
                    <img
                      className="relative w-6 h-6 overflow-hidden shrink-0"
                      alt=""
                      src="/active_user_icon.svg"
                    />
                  )}
                </div>
              </Sidebar>
            </div>

            <Sidebar>
              <div
                className={`flex flex-col z-[999999] items-start justify-start px-0 pb-0 cursor-pointer mq450:hidden mq750:hidden ${
                  isProduction ? "pt-[6px]" : ""
                }`}
              >
                <img
                  className="relative w-6 h-6 overflow-hidden shrink-0"
                  alt=""
                  src={
                    isProfileActive ? "/icon_you-active.svg" : "/button-1.svg"
                  }
                />
              </div>
            </Sidebar>
          </div>
        </div>
      </header>
      {location.pathname === "/user/profile" && (
        <MobileHeader
          data={sideBar}
          active={currentSection}
          onClick={scrollToSection}
        />
      )}
    </>
  );
};

export default React.memo(Header);
