import React from "react";
import { useMediaQuery } from "@mui/material";

type ContactUsModalProps = {
  isContactUsModalModalOpen: boolean;
  handleContactUsClose: () => void;
};

const ContactUsModal: React.FC<ContactUsModalProps> = ({
  isContactUsModalModalOpen,
  handleContactUsClose,
}) => {
  const isDesktop = useMediaQuery("(min-width: 768px)");

  return (
    isContactUsModalModalOpen && (
      <>
        {/* Desktop Modal */}
        {isDesktop ? (
          <div className="fixed inset-0 flex items-center justify-center bg-black/50 z-50">
            <div className="bg-white w-full max-w-md rounded-none shadow-lg  relative md:w-[520px] p-[60px] md:max-w-[520px]">
              {/* Close Button */}
              <button
                type="button"
                onClick={handleContactUsClose}
                className="absolute top-3 right-3 w-[16px] h-[16px] md:right-[30px] md:top-[30px] text-gray-60 hover:text-gray-700"
              >
                ✕
              </button>

              {/* Icon */}
              <div className="flex justify-center mb-4">
                <svg
                  width="37"
                  height="26"
                  viewBox="0 0 37 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 12.5L11.9859 24.5L36 0.5"
                    stroke="#4F4F4F"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              {/* Title */}
              <h2 className="text-center text-[32px] max-md:text-[24px] max-md:pt-[32px] leading-[100%] pt-[48px] font-normal text-[#1d1d1d] ">
                Thank you for <br className="" /> reaching out to us
              </h2>

              {/* Subtitle */}
              <p className="text-center text-base pt-[12px] leading-[120%] text-gray-60">
                We are processing your request for a callback. <br />
                Our team will get in touch with you shortly.
              </p>
            </div>
          </div>
        ) : (
          // Mobile Drawer
          <div className="fixed inset-0 flex flex-col justify-end bg-black/50 z-50">
            <div className="bg-white w-full shadow-lg px-[20px] pb-[40px] pt-[60px] relative">
              {/* Close Button */}
              <button
                type="button"
                onClick={handleContactUsClose}
                className="absolute top-5 right-5 w-[16px] h-[16px] md:right-[30px] md:top-[30px] text-gray-60 hover:text-gray-700"
              >
                ✕
              </button>

              {/* Icon */}
              <div className="flex justify-center mb-4">
                <svg
                  width="37"
                  height="26"
                  viewBox="0 0 37 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 12.5L11.9859 24.5L36 0.5"
                    stroke="#4F4F4F"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              {/* Title */}
              <h2 className="text-center text-[24px] pt-[32px] leading-[100%] font-normal text-gray-800 ">
                Thank you for <br className="" /> reaching out to us
              </h2>

              {/* Subtitle */}
              <p className="text-center text-base pt-[12px] leading-[120%] text-gray-60 ">
                We are processing your request for a callback.{" "}
                <br className="max-md:hidden block" />
                Our team will get in touch with you shortly.
              </p>
            </div>
          </div>
        )}
      </>
    )
  );
};

export default ContactUsModal;
