import PreloginHeader from "./Components/Header";
import Header from "@/components/Global/header/Header";
import React, { useState } from "react";
import Footer from "./Components/Footer";
import Footer2 from "../../components/Global/Footer2";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Button } from "@/components/ui/button";
import Cookies from "universal-cookie";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import usePrelogin from "@apis/prelogin";
import ContactUsModal from "../add-object/modal/ContactUsModal";
import BottomNavBar from "@/components/Global/BottomBarNav";
import { cn } from "@/lib/utils";
const formSchema = z.object({
  fullName: z.string().min(2, "Full name must be at least 2 characters"),
  email: z.string().email("Invalid email address"),
  phone: z
    .string()
    .min(10, "Invalid phone number")
    .max(10, "Invalid phone number"),
  comments: z.string().min(2, "Comments must be at least 2 characters"),
});

type FormValues = z.infer<typeof formSchema>;

export default function Contact() {
  const defaultValues = {
    fullName: "",
    email: "",
    phone: "",
    comments: "",
  };
  const { updateContactUs } = usePrelogin();
  const form = useForm<FormValues>({
    mode: "onChange",
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues,
  });

  const cookies = new Cookies();
  const [isContactUsModalModalOpen, setIsContactUsModalModalOpen] =
    useState<boolean>(false);
  const onSubmit = (data: FormValues) => {
    try {
      updateContactUs(data).then((res) => {
        console.log("contact use saved successfully");
      });
      setIsContactUsModalModalOpen(true);
      form.reset();
    } catch (error) {
      console.log(error);
    }
  };

  const handleContactUsClose = () => {
    setIsContactUsModalModalOpen(false);
  };

  const isLogin = cookies.get("token");

  return (
    <div className="font-sh5">
      {isLogin ? (
        <Header className="" />
      ) : (
        <PreloginHeader className="md:!mt-[-65px] max-md:!mt-[-40px] max-2xl:px-[48px] md:top-0" />
      )}
      <div
        className={cn(
          "flex container justify-center md-mt-5 pt-[45px] max-md:pt-[1px] ",
          "max-md:flex-col max-md:px-[24px] max-md:mb-[40px] max-md:mt-[40px] ",
          !isLogin &&
            " md:pt-[120px] max-md:pt-[120px] md:pb-[120px] max-md:pb-[120px] ",
        )}
      >
        <div className="lg:w-1/4 pr-[40px]">
          <h1 className="text-[24px] max-md:text-[20px] font-normal leading-[120%] tracking-normal">
            Request a callback
          </h1>
          <p className="text-[18px] max-md:text-[16px] leading-[120%] tracking-normal mt-[12px] text-[#696969]">
            Stuck somewhere or need clarity?
            <br />
            Help us get in touch with you.
          </p>
        </div>
        <div className="lg:w-[384px] ">
          <Form {...form}>
            <form className="space-y-9" onSubmit={form.handleSubmit(onSubmit)}>
              {/* Full Name */}
              <FormField
                control={form.control}
                name="fullName"
                render={({ field }) => (
                  <FormItem className="space-y-2 max-md:mt-[40px]">
                    <FormLabel className="text-[16px] leading-[120%] tracking-normal text-[#1d1d1d] mb-[12px] font-normal">
                      Full Name
                    </FormLabel>
                    <FormControl>
                      <Input
                        type="text"
                        className="h-12 px-4 border border-[#e5e5e5] p-[25.5px] focus:border-[#1d1d1d]"
                        placeholder=""
                        aria-label="Full Name"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Mobile Number */}
              <FormField
                control={form.control}
                name="phone"
                render={({ field }) => (
                  <FormItem className="space-y-2 max-md:-mt-[14px] max-md:mb-[34px]">
                    <FormLabel className="text-sm font-normal text-gray-100  sm:text-base">
                      Mobile number
                    </FormLabel>
                    <FormControl>
                      <div className="relative w-full font-sh5">
                        <div className="absolute inset-y-0 top-[-1px] left-0 flex items-center pl-5 pointer-events-none">
                          <span className="text-other-cta">+91</span>
                        </div>
                        <Input
                          type="number"
                          className="pl-12 pr-4 h-[53px] border-[#e5e5e5] focus:border-[#696969]"
                          placeholder=""
                          {...field}
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Email */}
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem className="space-y-2">
                    <FormLabel className="text-[14px] leading-[120%] tracking-normal text-[#1d1d1d] mb-[12px] font-normal">
                      Email
                    </FormLabel>
                    <FormControl>
                      <Input
                        type="email"
                        className="h-12 px-4 p-[25.5px] border border-[#e5e5e5] focus:border-[#1d1d1d]"
                        placeholder=""
                        aria-label="Email"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Comments */}
              <FormField
                control={form.control}
                name="comments"
                render={({ field }) => (
                  <FormItem className="space-y-2">
                    <FormLabel className="text-[14px] leading-[120%] tracking-normal text-[#1d1d1d] mb-[12px] font-normal">
                      Add your comments
                    </FormLabel>
                    <FormControl>
                      <textarea
                        className="h-28 px-4 py-2 p-[25.5px] border border-[#e5e5e5] focus:border-[#1d1d1d] w-full"
                        placeholder=""
                        aria-label="Comments"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Submit Button */}
              <Button
                type="submit"
                disabled={!form.formState.isValid}
                className="w-[384px] max-md:w-full font-medium tracking-normal leading-[120%] rounded-full py-[12px] text-[14px] bg-[#303030] hover:bg-[#262626] disabled:text-[#b5b5b5] disabled:bg-[#e8e8e8] disabled:opacity-100"
              >
                SUBMIT
              </Button>
            </form>
          </Form>
        </div>
      </div>
      <ContactUsModal
        isContactUsModalModalOpen={isContactUsModalModalOpen}
        handleContactUsClose={handleContactUsClose}
      />
      {!isLogin ? (
        <Footer />
      ) : (
        <>
          <Footer2 className=" pt-[40px] pb-[20px] md:pt-[70px] max-sm:pb-[40px] max-sm:h-[181px] max-sm:ml-[24px]" />
          <BottomNavBar />
        </>
      )}
    </div>
  );
}
