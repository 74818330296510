import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { DialogTrigger } from "@radix-ui/react-dialog";
import { Drawer, DrawerContent, DrawerHeader } from "@/components/ui/drawer";
import "react-toastify/dist/ReactToastify.css";
import { cn } from "@/lib/utils";
import { useMediaQuery, debounce } from "@mui/material";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import useBoard from "@/apis/board";
import { AddItemResponse, GetAllBoardResponse } from "@/types/boards.type";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import useFilter, { AddItemParams, DeleteItemParam } from "@/hooks/useFilter";
import { Button } from "../ui/button";
import toast from "react-hot-toast";
import NewBoard from "@/modal/NewBoard";
import RemoveItem from "@/modal/RemoveItem";
import { Spinner } from "../ui/spinner";
import { useDebouncedCallback } from "use-debounce";
import { ScrollArea } from "../ui/scroll-area";
import { useBoardsContext } from "@/context/boardsContext";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

type AddItemDropDownProps = {
  children: ReactNode;
  itemID: string;
  boardID: string;
  itemType: string;
};

const imageSources = [
  { id: 0, src: "/starLogoAlt.svg", alt: "Star Logo" },
  { id: 1, src: "/caseAlt.svg", alt: "Case Icon" },
  { id: 2, src: "/eyeAlt.svg", alt: "Eye Icon" },
  { id: 3, src: "/framingAlt.svg", alt: "Framing Icon" },
  { id: 4, src: "/paintAlt.svg", alt: "Paint Icon" },
  { id: 5, src: "/playAlt.svg", alt: "Play Icon" },
  { id: 6, src: "/scholarAlt.svg", alt: "Scholar Icon" },
  { id: 7, src: "/tvAlt.svg", alt: "TV Icon" },
  { id: 8, src: "/starAlt.svg", alt: "Star Alt Icon" },
  { id: 9, src: "/personAlt.svg", alt: "Person Icon" },
  { id: 10, src: "/writingAlt.svg", alt: "Writing Icon" },
  { id: 11, src: "/heart.svg", alt: "heart Icon" },
];

const selectedImageSources = [
  { id: 0, src: "/starLogoAltFilled.svg", alt: "Star Logo" },
  { id: 1, src: "/caseAltFilled.svg", alt: "Case Icon" },
  { id: 2, src: "/eyeAltFilled.svg", alt: "Eye Icon" },
  { id: 3, src: "/framingAltFilled.svg", alt: "Framing Icon" },
  { id: 4, src: "/paintAltFilled.svg", alt: "Paint Icon" },
  { id: 5, src: "/playAltFilled.svg", alt: "Play Icon" },
  { id: 6, src: "/scholarAltFilled.svg", alt: "Scholar Icon" },
  { id: 7, src: "/tvAltFilled.svg", alt: "TV Icon" },
  { id: 8, src: "/starAltFilled.svg", alt: "Star Alt Icon" },
  { id: 9, src: "/personAltFilled.svg", alt: "Person Icon" },
  { id: 10, src: "/writingAltFilled.svg", alt: "Writing Icon" },
  { id: 11, src: "/heartFilled.svg", alt: "heart Icon" },
];

const AddItemDropDown: React.FC<AddItemDropDownProps> = ({
  children,
  itemID,
  itemType,
}) => {
  const [selectedBoardId, setSelectedBoardId] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [isMutateLoading, setIsMutateLoading] = useState<boolean>(false);
  const { addItem } = useBoard();
  const queryClient = useQueryClient();

  const { filterOption, setQuery } = useFilter({
    options: {
      pagination: false,
    },
  });
  const updateSearchQuery = useCallback(
    debounce((query) => {
      setQuery({ searchQuery: query });
    }, 300),
    [],
  );

  const { getAllBoard, deleteItem } = useBoard();
  const { setAllObjectsInBoards } = useBoardsContext();

  const { data, error, isLoading, refetch } = useQuery<GetAllBoardResponse>({
    queryKey: ["boards", filterOption],
    queryFn: () =>
      getAllBoard({
        params: filterOption,
      }),
    refetchOnWindowFocus: false,
    retry: 3,
    staleTime: 1000 * 60 * 5,
  });

  useEffect(() => {
    if (data?.data?.data) {
      const objectsInBoards = data?.data?.data.flatMap((board) =>
        board.boardItems.map((item) => item._id),
      );
      setAllObjectsInBoards(objectsInBoards);
      // console.log(objectsInBoards)
    }
  }, [data?.data?.data]);
  // console.log(data?.data?.data)

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    updateSearchQuery(event.target.value);
  };

  const navigate = useNavigate();
  const location = useLocation();
  let currentLocation = location.pathname;

  const BoardCreationTost = ({
    boardName,
    boardId,
  }: {
    boardName: string;
    boardId: string;
  }) => {
    toast.success(`Board ${boardName} created successfully!`, {
      id: "board-create",
    });
    setSelectedBoardId(boardId);
    setIsDrawerOpen(true);
  };

  const mutation: UseMutationResult<
    AddItemResponse,
    Error,
    AddItemParams,
    unknown
  > = useMutation({
    mutationFn: addItem,
    onSuccess: (data) => {
      let successData = data.data;
      queryClient.invalidateQueries({
        queryKey: ["single-board"],
      });
      toast.success(
        <div>
          Item added successfully!{" "}
          <button
            onClick={() => navigate(`/boards/${successData._id}`)}
            className="view-link"
          >
            View
          </button>
        </div>,
        {
          id: "item-add",
        },
      );
      setIsMutateLoading(false);
      refetch();
    },
    onError: (error: Error) => {
      toast.error("Failed to add item. Please try again.", {
        id: "item-add",
      });
    },
    onMutate: () => {
      toast.loading("Adding the item...", {
        id: "item-add",
      });
      setIsMutateLoading(true);
    },
  });

  const deleteItemFromBoard: UseMutationResult<
    AddItemResponse,
    Error,
    DeleteItemParam
  > = useMutation({
    mutationFn: (params: DeleteItemParam) => deleteItem(params), // Call deleteItem with params
    onSuccess: (data) => {
      toast.success(`${data.message}`, {
        id: "delete",
      }); // Notify on success
      queryClient.invalidateQueries({
        queryKey: ["single-board"],
      });
      setIsMutateLoading(false);
      refetch();
    },
    onError: (error: Error) => {
      toast.error(`Failed to delete item: ${error.message}`, {
        id: "delete",
      }); // Notify on error
    },
    onMutate: () => {
      toast.loading("Deleting the item...", {
        id: "delete",
      });
    },
  });

  const handleDelete = (boardId: string, itemId: string) => {
    setIsMutateLoading(true);
    deleteItemFromBoard.mutate({ boardId, itemId }); // Trigger mutation for deletion
  };

  const handleAddItem = (BoardId: string) => {
    setIsMutateLoading(true);
    if (!BoardId) {
      toast.error("Please select a board first!");
      return;
    }
    const newItem: AddItemParams = {
      itemType,
      boardId: BoardId,
      itemId: itemID,
    };
    mutation.mutate(newItem);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  const sortedBoards = data?.data?.data ?? [];

  const filteredBoards = sortedBoards.filter(
    (board) => !board.isDeleted && board.isActive,
  );

  const oneDayAgo = new Date();
  oneDayAgo.setDate(oneDayAgo.getDate() - 1); // Get timestamp for 24 hours ago

  const recentAddedBoards = [...filteredBoards] // Prevents mutation
    .filter((board) => new Date(board.createdAt) >= oneDayAgo) // Only boards added in last 24 hours
    .sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    ) // Sort by newest first
    .slice(0, 2);

  const recentUpdatedBoards = filteredBoards
    .filter(
      (board) =>
        !recentAddedBoards.some((addedBoard) => addedBoard.id === board.id),
    )
    .sort(
      (a, b) =>
        new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
    )
    .slice(0, Math.min(3, filteredBoards.length - recentAddedBoards.length)); // Ensures valid slice

  let recentBoards = [...recentAddedBoards, ...recentUpdatedBoards];
  const allBoards = sortedBoards
    .filter((board) => !board.isDeleted && board.isActive)
    .filter((board) => !recentBoards.some((recent) => recent.id === board.id)); // Exclude recent boards
  recentBoards = recentBoards.slice(0, 3);
  return isDesktop ? (
    <DropdownMenu
      open={isDrawerOpen}
      onOpenChange={setIsDrawerOpen}
      modal={false}
    >
      <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
      <DropdownMenuContent
        className="max-w-[300px] w-full min-h-[250px] border-[1px] border-bdr-10 border-solid rounded-none p-0 z-[100]"
        align="start"
      >
        <DropdownMenuGroup className="z-20 flex flex-col gap-2 max-h-[412px] w-[300px] text-base text-gray-60 font-sh5">
          <ProfileForm
            searchQuery={searchQuery}
            handleSearchChange={handleSearchChange}
            setSelectedBoardId={setSelectedBoardId}
            boards={allBoards}
            recentBoards={recentBoards}
            handleAddItem={handleAddItem}
            selectedBoardId={selectedBoardId}
            itemID={itemID}
            handleDelete={handleDelete}
            isMutateLoading={isMutateLoading}
            isLoading={mutation.isPending || deleteItemFromBoard.isPending}
          />
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  ) : (
    <Drawer open={isDrawerOpen} onOpenChange={setIsDrawerOpen}>
      <DialogTrigger
        onClick={toggleDrawer}
        className="text-lg bg-transparent text-other-cta"
        asChild
      >
        {children}
      </DialogTrigger>
      <DrawerContent className="rounded-none ">
        <ProfileForm
          searchQuery={searchQuery}
          handleSearchChange={handleSearchChange}
          setSelectedBoardId={setSelectedBoardId}
          boards={allBoards}
          recentBoards={recentBoards}
          handleAddItem={handleAddItem}
          selectedBoardId={selectedBoardId}
          itemID={itemID}
          handleDelete={handleDelete}
          isLoading={mutation.isPending || deleteItemFromBoard.isPending}
        />
      </DrawerContent>
    </Drawer>
  );
};

type Board = {
  boardItems: any;
  icon: number;
  id: string;
  name: string;
};

type ProfileFormProps = {
  className?: string;
  boards?: Board[];
  setSelectedBoardId: (id: string) => void;
  handleAddItem: (id: string) => void;
  searchQuery: string;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedBoardId: string;
  itemID: string;
  recentBoards?: Board[];
  updatedBoards?: Board[];
  handleDelete: (boardId: string, itemId: string) => void;
  isLoading: boolean;
  isMutateLoading?: boolean;
};

function ProfileForm({
  className,
  boards,
  recentBoards,
  setSelectedBoardId,
  selectedBoardId,
  handleAddItem,
  searchQuery,
  handleSearchChange,
  itemID,
  handleDelete,
  isLoading,
  isMutateLoading,
}: ProfileFormProps) {
  const handleItemAction = useDebouncedCallback(
    (boardId: string, isInBoard: boolean) => {
      isInBoard ? handleDelete(boardId, itemID) : handleAddItem(boardId);
    },
    300,
  );

  return (
    <form
      className={cn(
        "grid items-center grid-cols-1 h-full justify-between pt-5 z-50 ",
        className,
      )}
    >
      <div className="relative px-5 pb-5 border-[#EAEAEA] border-b-[1px] border-solid">
        <input
          onChange={handleSearchChange}
          className="w-full border-[1px] min-w-[232px] pl-12 border-solid border-gray-20 focus:border-black focus:ring-0 h-10 rounded-[50px] transition-colors duration-300 ease-in-out focus:outline-none focus:ring-5 focus:ring-focus-ring focus:ring-opacity-50"
          placeholder="Search"
          value={searchQuery}
        />
        <img
          className="absolute pl-2 transform -translate-y-1/2 left-7 top-5"
          src="/iconsearch.svg"
          alt="Search Icon"
        />
      </div>
      <ScrollArea className="px-5 pt-6 mq450:p-5 max-h-[255px] overflow-y-scroll">
        <p className="text-[#BCBCBC] text-base font-sh5 font-normal">Recent</p>
        <div className="relative flex flex-col">
          {recentBoards?.map((board) => {
            const imageSource = imageSources.find(
              (image) => image.id === board.icon,
            );
            const selectedImageSource = selectedImageSources.find(
              (image) => image.id === board.icon,
            );
            const existingItemIds = board.boardItems.map(
              (item: { _id: any }) => item._id,
            );

            const isItemAlreadyInBoard = existingItemIds.includes(itemID);
            const isSelected = board.id === selectedBoardId;
            return (
              <div
                key={`board-${board.id}`}
                onClick={() =>
                  !isMutateLoading &&
                  handleItemAction(board.id, isItemAlreadyInBoard)
                }
                className={`group border-b-[1px] border-solid border-[#E5E5E5] py-[22.5px] md:py-4 flex flex-row items-center justify-start gap-2 cursor-pointer 
                 bg-transparent
                }`}
              >
                <img
                  className="w-6 h-6 md:w-4 md:h-4"
                  src={
                    isItemAlreadyInBoard
                      ? selectedImageSource?.src
                      : imageSource?.src
                  }
                  alt={
                    isItemAlreadyInBoard
                      ? selectedImageSource?.alt
                      : imageSource?.alt
                  }
                />
                <div className="relative leading-[140%] inline-block min-w-[88px] shrink-0 whitespace-nowrap font-normal text-base font-sh5 text-[#212121]">
                  {board.name}
                </div>
                {/* Display side-panel-icons.svg only if the item is not selected */}
                {/* {!isSelected && !isItemAlreadyInBoard && (
                  <img
                    className="absolute right-0 invisible w-6 h-6 group-hover:visible"
                    src="/side-panel-icons.svg"
                    alt="Item not selected"
                  />
                )} */}

                {/* Always display tickmark.svg if the item is selected or already in board */}
                {(isSelected || isItemAlreadyInBoard) && (
                  <img
                    className="absolute right-0 w-6 h-6"
                    src="/tickmark.svg"
                    alt="Item selected or already added"
                  />
                )}
              </div>
            );
          })}
        </div>

        <p className="text-[#BCBCBC] text-base font-sh5 font-normal mt-5 md:mt-4">
          All Boards
        </p>
        <div className="relative flex flex-col">
          {boards?.map((board) => {
            const imageSource = imageSources.find(
              (image) => image.id === board.icon,
            );
            const selectedImageSource = selectedImageSources.find(
              (image) => image.id === board.icon,
            );
            const existingItemIds = board.boardItems.map(
              (item: { _id: any }) => item._id,
            );

            const isItemAlreadyInBoard = existingItemIds.includes(itemID);
            const isSelected = board.id === selectedBoardId;
            return (
              <div
                key={`board-${board.id}`}
                onClick={() => handleItemAction(board.id, isItemAlreadyInBoard)}
                className={`group border-b-[1px] border-solid border-[#E5E5E5] py-[22.5px] md:py-4 flex flex-row items-center justify-start gap-2 cursor-pointer 
                 bg-transparent
                }`}
              >
                <img
                  className="w-6 h-6 md:w-4 md:h-4"
                  src={isSelected ? selectedImageSource?.src : imageSource?.src}
                  alt={isSelected ? selectedImageSource?.alt : imageSource?.alt}
                />
                <div className="relative leading-[140%] inline-block min-w-[88px] shrink-0 whitespace-nowrap font-normal text-lg font-sh5">
                  {board.name}
                </div>
                {/* Display side-panel-icons.svg only if the item is not selected */}
                {!isSelected && !isItemAlreadyInBoard && (
                  <img
                    className="absolute right-0 invisible w-6 h-6 group-hover:visible"
                    src="/side-panel-icons.svg"
                    alt="Item not selected"
                  />
                )}

                {/* Always display tickmark.svg if the item is selected or already in board */}
                {(isSelected || isItemAlreadyInBoard) && (
                  <img
                    className="absolute right-0 w-6 h-6"
                    src="/tickmark.svg"
                    alt="Item selected or already added"
                  />
                )}
              </div>
            );
          })}
        </div>
      </ScrollArea>
      <div className="bg-[#F2F2F2] flex-1 px-3 py-5">
        <NewBoard>
          <Button
            size="default"
            className="w-[156px] rounded-[50px] text-sm font-medium font-sh5"
          >
            <img src="/plus-icon.svg" alt="" className="mr-2" /> New Board
          </Button>
        </NewBoard>
      </div>
    </form>
  );
}

export default React.memo(AddItemDropDown);
