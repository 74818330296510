import React, { useEffect, useState } from "react";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { useLongPress } from "use-long-press";
import Image from "@/components/Global/Image";
import { LikeObject } from "@/components/Templates/Objects/OBig";
import { Button } from "@/components/ui/button";
import { ArtObject } from "@/apis/objects";
import { formatDimensions, formatInRuppes, getId } from "@/lib/helper";
import { Link, useNavigate } from "react-router-dom";
import AddItemDropDown from "@/components/Boards/AddItemDropDown";
import ShareModal from "@/components/ShareModal";
import {
  ObjectLike,
  OverLaly,
  PlayVideo,
} from "@/components/Preview/DiscoverPreview";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { format } from "date-fns";
import { FollowArtistButton } from "@/components/Templates/Artists/BigBanner";
import { FollowBussiness } from "@/components/Templates/Business/BBig";
import ReactPlayer from "react-player";
import { Badge } from "@/components/ui/badge";

const LongPressPreivew = ({
  children,
  data,
  type,
}: {
  children: React.ReactNode;
  data: ArtObject;
  type: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  // console.log(data)
  useEffect(() => {
    // Check if the screen is mobile size
    const updateScreenSize = () => {
      setIsMobile(window.innerWidth <= 1000);
    };

    // Set initial value
    updateScreenSize();

    // Listen to window resize events
    window.addEventListener("resize", updateScreenSize);

    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);

  const bind = useLongPress(
    () => {
      if (!isMobile) return; // Disable long press for desktop/laptop
      setIsOpen(true);
    },
    {
      threshold: 500, // Adjust the duration as needed (in milliseconds)
    },
  );

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <div {...bind()} className="w-full h-full">
        {children}
      </div>
      <DialogContent className="text-white border-none bg-[#303030E5] backdrop-blur-[16px] w-[calc(100vw+0.4px)] max-w-[1200px] h-[100dvh] overflow-y-scroll -translate-x-[calc(50%+0.2px)] -translate-y-[calc(50%+0.5px)]">
        <div className="flex flex-col items-center mt-[24px] w-full h-full px-12 font-normal font-sh5">
          <img
            src="/cross.svg"
            className="absolute cursor-pointer top-4 right-5"
            onClick={() => setIsOpen(false)}
          />
          <div className="flex items-center justify-between gap-12 mq825:flex-col mq825:gap-0">
            {type == "video" && <VideoPreview data={data} />}
            {type == "objects" && <ObjectPreview data={data} />}
            {type == "artist" && <ArtistPreview data={data} />}
            {type == "business" && <BusinessPreview data={data} />}
            {type == "events" && <EventPreview data={data} />}
            {type == "writing" && <WritingPreview data={data} />}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default LongPressPreivew;

const PressFooter = ({
  id,
  type,
  addedBy,
}: {
  id: string;
  type: string;
  addedBy?: { profilePic: string; id: string; displayName: string };
}) => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-between mt-12 gap-x-[18px]">
      <div className="flex gap-3">
        <div className="w-[44px] h-[44px] rounded-full cursor-pointer bg-white flex items-center justify-center">
          <AddItemDropDown itemID={id} boardID={""} itemType={type}>
            <img src="/save.svg" alt="" className="w-[13px] h-[18px]" />
          </AddItemDropDown>
        </div>
        <div className="w-[44px] h-[44px] rounded-full cursor-pointer bg-white flex items-center justify-center">
          <ShareModal url={`/objects/detail/${id}`}>
            <img src="/share.svg" alt="" className="w-[21px] h-[17px]" />
          </ShareModal>
        </div>
        {getId(addedBy) && (
          <div className="w-[44px] h-[44px] rounded-full cursor-pointer bg-white flex items-center justify-center border border-solid border-gray-300 hover:border-blue-500">
            <Link
              to={`/artist/detail/${getId(addedBy)}`}
              className="w-[43px] h-[43px] rounded-full flex items-center justify-center"
            >
              {addedBy?.profilePic ? (
                <img
                  src={addedBy?.profilePic || "/noimage.png"}
                  alt={`Profile picture of ${addedBy?.displayName}`}
                  className="w-[40px] h-[40px] rounded-full"
                />
              ) : (
                <div className="text-[#1d1d1d]">
                  {addedBy?.displayName
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase())
                    .join("")
                    .substring(0, 2)}
                </div>
              )}
            </Link>
          </div>
        )}
      </div>
      <Button
        className="text-gray-900 bg-white border border-[#cfcfcf] border-solid rounded-full hover:text-white hover:border-white"
        onClick={() => navigate(`/${type}s/detail/${id}`)}
      >
        Know more
      </Button>
    </div>
  );
};

const ObjectPreview = ({ data }: { data: any }) => {
  return (
    <div>
      {/* {data.assignProtect && (
        <p className="flex items-center justify-center h-10 mb-5 text-sm truncate md:hidden">
          <img src="/icon-protect-1.svg" alt="asign protect" /> Asign Protect+
        </p>
      )} */}

      <Image
        src={data.image}
        alt={data.objectName}
        className="max-h-[400px] w-full h-full object-cover md:min-w-[360px] "
      />
      <div className="w-full pb-5 mt-5 font-normal text-left font-sh5 ">
        <div className="flex items-center justify-between w-full gap-20 ">
          <div>
            <p className="text-5xl capitalize mq750:text-base leading-[120%]">
              {data?.displayName ? data?.displayName : data?.artistName}
            </p>
            <p className="text-[#CCCCCC]  mt-1 text-lg mq750:text-sm leading-[120%]">
              {data.objectName}
            </p>
            <p className="text-[#CCCCCC] text-lg mq750:text-sm mt-1 leading-[120%]">
              {data?.metaData?.creationDate?.year}{" "}
              {data?.metaData?.creationDate?.era === "CE"
                ? ""
                : data?.metaData?.creationDate?.era}
            </p>
            <p className="text-xs text-[#CCCCCC] mt-4 leading-[120%]">
              {data?.metaData?.medium}
            </p>
            <p className="text-xs text-[#CCCCCC] mt-1 leading-[120%]">
              {formatDimensions(data)}
            </p>
            {/* <p className="mt-4 text-lg text-white mq750:text-base">
              {" "}
              {formatInRuppes.format(data?.price ?? (2000 as number))}
            </p> */}
          </div>

          <div className="">
            <ObjectLike
              id={getId(data)}
              className="bg-transparent border border-white border-solid"
              iconClass="h-[44px] w-[44px]"
              likeClass="h-[22px] w-[22px]"
            />
          </div>
        </div>
        <PressFooter id={getId(data)} type={"object"} addedBy={data?.addedBy} />
      </div>
    </div>
  );
};

const ArtistPreview = ({ data }: { data: any }) => {
  return (
    <>
      <Image
        src={data.profilePic}
        alt={data.name}
        className="max-h-[400px] w-full h-full object-cover md:min-w-[360px]"
      />
      <div>
        <div className="flex justify-between w-full mt-5 font-normal text-left font-sh5 ">
          <div className="flex items-center gap-[10px]">
            <div>
              <h1 className="mq750:text-[15px] font-thin font-eb tracking-wide truncate capitalize text-5xl">
                {data?.name}
              </h1>
              <p className="text-base mq750:text-xs font-sh5 text-[#CCCCCC]">
                b. {format(data?.dob ?? new Date(), "yyyy")}
              </p>
            </div>
          </div>
          {/* <FollowArtistButton id={getId(data)} /> */}
        </div>
        <PressFooter id={getId(data)} addedBy={data?.addedBy} type={"artist"} />
      </div>
    </>
  );
};

const BusinessPreview = ({ data }: { data: any }) => {
  return (
    <>
      <Image
        src={data.image}
        alt={data.businessName}
        className="max-h-[400px] w-full h-full object-cover"
      />
      <div className="flex justify-between w-full mt-5 font-normal text-left font-sh5">
        <div className="flex items-center gap-[10px]">
          <Avatar className="cursor-pointer">
            <AvatarImage src={data?.image} alt="" />
            <AvatarFallback>{data?.businessName}</AvatarFallback>
          </Avatar>
          <div>
            <h1 className="mq750:text-[15px] font-thin font-eb tracking-wide truncate capitalize text-5xl">
              {data?.businessName}
            </h1>
            <p className="mq750:text-xs font-sh5 text-[#CCCCCC] capitalize text-base">
              {data?.businessLocation}
            </p>
          </div>
        </div>
        {/* <FollowBussiness id={data?._id} /> */}
        <PressFooter id={data?._id} addedBy={data?.addedBy} type={"business"} />
      </div>
    </>
  );
};

const EventPreview = ({ data }: { data: any }) => {
  return (
    <>
      <Image
        src={data.image}
        alt={data.eventName}
        className="max-h-[400px] w-full h-full object-cover max-w-[500px]"
      />
      <div>
        <div className="flex justify-between w-full mt-5 font-normal text-left font-sh5">
          <div className="">
            <p className="text-5xl capitalize mq750:text-sm">
              {data?.eventName}
            </p>
            <p className="mq750:text-xs text-[#CCCCCC] capitalize mt-1 text-lg">
              {(data?.country, data?.state)}
            </p>
            <p className="mq750:text-xs text-[#CCCCCC] mt-1 text-base">
              Sep 14, 2023 - Oct 21, 2023{" "}
            </p>
            <p className="mq750:text-xs text-[#CCCCCC] mt-1 text-base">
              10:00 AM - 05:00 PM{" "}
            </p>
          </div>
          <Button
            variant="outline"
            className="text-white border-white rounded-[33px]"
          >
            ATTEND
          </Button>
        </div>
        <PressFooter id={getId(data)} type={"events"} />
      </div>
    </>
  );
};

const VideoPreview = ({ data }: { data: any }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handleThumbnailClick = () => {
    setIsPlaying(true);
  };

  return (
    <div className="relative w-full h-full">
      {!isPlaying ? (
        <div
          className="relative w-full h-full cursor-pointer"
          onClick={handleThumbnailClick}
        >
          <Image
            src={data.content?.coverImage}
            alt={data.content?.title}
            className="object-cover object-center w-full h-full"
          />
          <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] bg-white rounded-full w-[50px] h-[50px] inline-flex items-center justify-center">
            <img src="/play.svg" alt="" />
          </div>
        </div>
      ) : (
        <div className="relative w-full h-full">
          <ReactPlayer
            url={data.content?.url}
            width="100%"
            height="100%"
            playing={true}
            muted={false}
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload",
                  disablePictureInPicture: true,
                },
              },
            }}
            controls={true}
          />
        </div>
      )}
      <PressFooter id={getId(data)} type={"video"} />
    </div>
  );
};

const WritingPreview = ({ data }: { data: any }) => {
  console.log(data);
  return (
    <>
      <div className="relative">
        <div className="relative overflow-hidden aspect-square">
          <Image
            src={data.content.coverImage}
            className="object-cover w-full h-full cursor-pointer"
            alt={data.content.description}
            loading="lazy"
          />
          <div
            className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-gray-100 to-70% cursor-pointer"
            // onClick={() => {
            //   navigate(`/writing/detail/${getId(data)}`);
            // }}
          />

          <div className="absolute left-0 w-full px-4 font-normal text-white md:px-3 lg:px-5 bottom-5 font-sh5">
            <Badge className="px-2 py-1">
              <img src="/writing.svg" alt="writing icon" className="w-[20px]" />
              <span className="max-w-[50px] truncate capitalize text-xs text-[#1D1D1D] ml-[6px]">
                Read
              </span>
            </Badge>
            <p className="mt-4 text-[20px] leading-6 capitalize normal tracking-tight line-clamp-2">
              {`“${data?.content?.title}”`}
            </p>
            <p className="mt-[16px] text-xs capitalize">
              {data?.content?.authorName}
            </p>
          </div>
        </div>
      </div>
      <div>
        <PressFooter id={getId(data)} type={"events"} />
      </div>
    </>
  );
};
