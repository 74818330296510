import { cn } from "@/lib/utils";
import { Artist, Section } from "@/types/atrists.type";
import { sectionType } from "@/types/global.type";
import React, { useEffect, useRef, useState } from "react";
import DBigBanner from "../Templates/Discover/DBigBanner";
import Image from "../Global/Image";
import { getContentImageAndTitle } from "@/helper";
import Survey from "../Templates/Common/Survey";
import Announcement from "../Templates/Common/Announcement";
import { format } from "date-fns";
import { Button } from "../ui/button";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { FollowArtistButton } from "../Templates/Artists/BigBanner";
import { FollowBussiness } from "../Templates/Business/BBig";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import LongPressPreivew from "@/modal/LongPressPreivew";
import { useUserContext } from "@/context/user";
import { findById, getId } from "@/lib/helper";
import Like from "../Global/intreactions/Like";
import AddBoardDropDown from "@/components/Boards/AddItemDropDown";
import LazyLoad from "../LazyLoad";
import Header from "../Global/header/Header";
import { useBoardsContext } from "@/context/boardsContext";
import { Badge } from "../ui/badge";
interface DiscoverSection extends Section {
  layoutType: number;
  sectionType: sectionType;
}

interface Props {
  data: DiscoverSection;
  order: "right" | "left";
  isFirstIndex: boolean;
}

export const getFieldNameWithContentType = {
  artist: "objects",
  business: "objects",
  objects: "objects",
  events: "objects",
  video: "video",
  contentLibrary: "video", //consider this as video for now change it later
  writing: "writing",
};
type Type =
  | "artist"
  | "objects"
  | "events"
  | "business"
  | "video"
  | "discover"
  | "writing";

export const renderImage = (item: any) => {
  const navigate = useNavigate();
  const type = getFieldNameWithContentType[
    item?.sectionType as keyof typeof getFieldNameWithContentType
  ] as Type;
  const redirect = () => {
    if (type === "video") return;
    let artistId = item?.addedBy._id ? item?.addedBy._id : item?.addedBy;
    navigate(`/${type}/detail/${getId(item)}/${artistId}`);
  };
  return (
    <LongPressPreivew type={type} data={item}>
      <div className="relative w-full h-full p-0 m-0 group" id="">
        <Image
          src={getContentImageAndTitle(item).image}
          alt={getContentImageAndTitle(item).title}
          className="block object-cover w-full h-full rounded-none"
        />
        <div
          className="absolute top-0 left-0 w-full h-full bg-gradient-to-t mq1000:group-hover:opacity-0 from-gray-100 to-70% opacity-0 group-hover:opacity-100 transition-opacity ease-in-out duration-300 cursor-pointer"
          onClick={redirect}
        />
        <div className="mq1000:group-hover:hidden">
          <OverLaly
            type={type}
            data={item}
            className={{
              price: "hidden",
              h1: "text-[10px]",
            }}
          />
        </div>
      </div>
    </LongPressPreivew>
  );
};

export const PlayVideo = ({
  url,
  children,
  title,
}: {
  url: string;
  children: React.ReactNode;
  title?: string;
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // Preload the video to ensure it's ready when needed
    if (url) {
      const video = document.createElement("video");
      video.src = url;
      video.oncanplaythrough = () => setIsLoaded(true);
      // Add error handling
      video.onerror = () => setIsLoaded(true); // Still set loaded to allow fallback player to attempt playback
    }
  }, [url]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="w-full h-full p-3 z-[999] overflow-y-scroll max-w-full grid justify-center place-items-center">
        <Header className="absolute w-full" />
        <div className="absolute cursor-pointer pr-6 pb-6  top-[69px] mq450:right-5 right-[45px] h-5 w-5">
          <DialogClose>
            <img src="/close1.svg" alt="close" />
          </DialogClose>
        </div>
        <div className="px-2">
          {isLoaded && url ? (
            <div className="relative m-auto aspect-video max-h-[436.02px]">
              <ReactPlayer
                url={url}
                width="100%"
                height="100%"
                playing={open}
                muted={true}
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                      disablePictureInPicture: true,
                    },
                    forceVideo: true,
                    forceFLV: false,
                  },
                  youtube: {
                    playerVars: { showinfo: 1 },
                  },
                }}
                controls={true}
                style={{
                  marginTop: "32px",
                }}
                onError={(e) => {
                  console.error("Video playback error:", e);
                }}
              />
            </div>
          ) : (
            <p>Loading video...</p>
          )}
          <DialogTitle className="mt-3 text-sm font-normal font-sh5 text-[#1d1d1d]">
            {title ?? "Untitled"}
          </DialogTitle>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export const disCoverMainTemplateRender = (
  sectionDetails: any,
  sectionType: sectionType,
  title: string,
  sectionId: string,
  order: string,
) => {
  if (!sectionDetails) return null;
  if (sectionDetails.templateId === 8 && sectionDetails.size === "l") {
    return (
      <Survey
        data={[sectionDetails]}
        title={title}
        type={sectionType}
        sectionId={sectionId}
        isDiscover
      />
    );
  }
  if (sectionDetails.templateId === 8 && sectionDetails.size === "s") {
    return (
      <Survey
        data={[sectionDetails]}
        title={title}
        isImage
        type={sectionType}
        sectionId={sectionId}
        isDiscover
      />
    );
  }
  if (sectionDetails.templateId === 9) {
    return <Announcement data={sectionDetails} />;
  }
  if (sectionDetails.templateId === 1) {
    return sectionDetails.items.length > 1 ? (
      <DBigBanner data={sectionDetails} type={sectionType} order={order} />
    ) : (
      renderImage(sectionDetails.items[0])
    );
  }

  return null;
};

const DiscoverPreview = ({ data, order, isFirstIndex }: Props) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const updateWidth = () => setContainerWidth(container.offsetWidth);
    const resizeObserver = new ResizeObserver(updateWidth);
    resizeObserver.observe(container);
    updateWidth();

    return () => resizeObserver.disconnect();
  }, []);

  const isSmallWidth = containerWidth < 411;

  const renderSection = (item: DiscoverSection) => {
    switch (item.layoutType) {
      case 1:
        return (
          <>
            <div
              className={cn(
                "grid grid-cols-4 grid-rows-6  mq450:pb-1  gap-2  [@media(max-width:508px)]:gap-1  [@media(max-width:508px)]:pb-1 w-full",
                isSmallWidth && "max-h-full flex flex-col",
              )}
              dir={order === "right" ? "rtl" : "ltr"}
            >
              <LazyLoad
                className="col-span-2 row-span-6 aspect-square"
                forcedLoad={isFirstIndex}
              >
                {disCoverMainTemplateRender(
                  item.sectionDetails[0],
                  item.sectionType,
                  item.title,
                  getId(item),
                  order,
                )}
              </LazyLoad>

              <LazyLoad
                className="col-start-3 row-span-3 aspect-square "
                forcedLoad={isFirstIndex}
              >
                {renderImage(item.sectionDetails[1].items[0])}
              </LazyLoad>
              <LazyLoad
                className="col-start-4 row-span-3 aspect-square mq750:mb-0 mq450:mb-0"
                forcedLoad={isFirstIndex}
              >
                {renderImage(item.sectionDetails[2].items[0])}
              </LazyLoad>

              <div className="relative w-full h-full col-span-2 col-start-3 row-span-3 mq750:mb-1 [@media(max-width:508px)]:mb-0">
                <div className=" [@media(min-width:507px)]:absolute [@media(min-width:507px)]:inset-0">
                  {renderImage(item.sectionDetails[3].items[0])}
                </div>
              </div>
            </div>
            <div
              className={cn(
                "grid grid-cols-4 my-2 mq450:my-0  [@media(max-width:508px)]:mb-1  mq750:mb-2  [@media(max-width:508px)]:mt-0 gap-2  [@media(max-width:508px)]:gap-1",
                isSmallWidth && "grid-cols-1 gap-y-1 mq450:gap-y-1",
              )}
            >
              {item.sectionDetails.slice(4).map((data, index) => (
                <LazyLoad
                  key={index}
                  className="aspect-square mq750:mb-0"
                  forcedLoad={isFirstIndex}
                >
                  {renderImage(data.items[0])}
                </LazyLoad>
              ))}
            </div>
          </>
        );

      case 2:
        return (
          <>
            <div
              className={cn(
                "grid grid-cols-4 grid-rows-6 gap-2 w-full max-h-[450px]",
                isSmallWidth && "max-h-full flex flex-col",
              )}
              dir={order === "right" ? "rtl" : "ltr"}
            >
              <LazyLoad className="col-span-2 row-span-6">
                {disCoverMainTemplateRender(
                  item.sectionDetails[0],
                  item.sectionType,
                  item.title,
                  getId(item),
                  order,
                )}
              </LazyLoad>
              <LazyLoad className="col-start-3 row-span-3 row-start-4">
                {renderImage(item.sectionDetails[1].items[0])}
              </LazyLoad>
              <LazyLoad className="col-start-3 row-span-3 row-start-1">
                {renderImage(item.sectionDetails[2].items[0])}
              </LazyLoad>
              <LazyLoad className="col-start-4 row-span-3 row-start-1">
                {renderImage(item.sectionDetails[3].items[0])}
              </LazyLoad>
              <LazyLoad className="col-start-4 row-span-3 row-start-4">
                {renderImage(item.sectionDetails[4].items[0])}
              </LazyLoad>
            </div>
            <div
              className={cn(
                "grid grid-cols-4 my-2 gap-2",
                isSmallWidth && "grid-cols-1 gap-y-2",
              )}
            >
              {item.sectionDetails.slice(5).map((data, index) => (
                <LazyLoad key={index} className="aspect-square">
                  {renderImage(data.items[0])}
                </LazyLoad>
              ))}
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return <div ref={containerRef}>{renderSection(data)}</div>;
};

export default React.memo(DiscoverPreview);

export const OverLaly = ({
  type,
  data,
  order,
  className,
}: {
  type: Type;
  data: any;
  order?: "right" | "left";
  className?: {
    parent?: string;
    avatar?: string;
    h1?: string;
    date?: string;
    likeIcon?: string;
    price?: string;
    board?: string;
    likesvg?: string;
  };
}) => {
  const { allObjectsInBoards } = useBoardsContext();
  if (type === "artist") {
    return (
      <>
        <div
          className={cn(
            "absolute left-0 flex invisible group-hover:visible items-center justify-between w-full px-2 text-white transition-all ease-in-out bottom-5 ",
            className?.parent,
          )}
          dir={order === "right" ? "rtl" : "ltr"}
        >
          <div className="flex items-center gap-[10px]">
            <Avatar className={cn("cursor-pointer", className?.avatar)}>
              <AvatarImage src={data?.profilePic} alt="" />
              <AvatarFallback>{data?.name}</AvatarFallback>
            </Avatar>
            <div>
              <h1
                className={cn(
                  "text-[15px] font-thin font-eb tracking-wide truncate capitalize",
                  className?.h1,
                )}
              >
                {data?.name}
              </h1>
              <p className={cn("text-xs font-sh5", className?.date)}>
                b. {format(data?.dob ?? new Date(), "yyyy")}
              </p>
            </div>
          </div>
          {/*  <FollowArtistButton id={getId(data)} /> */}
        </div>
        <AddBoardDropDown itemID={getId(data)} boardID={""} itemType={"artist"}>
          <span
            dir="ltr"
            className="absolute top-4 font-sh5 bg-white text-other-cta rounded-full right-0 p-[5.5px_12px] mr-[5px] text-base flex gap-x-1 opacity-0  group-hover:opacity-100  invisible group-hover:visible cursor-pointer transition-all duration-300 ease-in-out items-center"
          >
            {allObjectsInBoards.includes(getId(data)) ? (
              <img
                src="/boardsSelecteddesktop.svg"
                alt="label"
                className="w-4 mq450:h-4"
              />
            ) : (
              <img src="/label.svg" alt="label" className="w-4 mq450:h-4" />
            )}{" "}
            Board
          </span>
        </AddBoardDropDown>
      </>
    );
  } else if (type === "objects") {
    // You can add a specific overlay for "objects" here

    return (
      <>
        <div
          className={cn(
            `absolute left-0 flex items-end justify-between invisible w-full mq750:px-2 px-3 text-white transition-all ease-in-out  group-hover:visible ${
              className?.likeIcon ? "bottom-[30px]" : "bottom-[14px]"
            }`,
            className?.parent,
          )}
          style={{ overflow: "hidden" }}
          dir={order === "right" ? "rtl" : "ltr"}
        >
          <div className={className?.likeIcon ? "mb-2" : ""}>
            {/* {data?.assignProtect && (
              <p className="flex items-center -mx-1 text-[10px] truncate font-sh5 mq750:text-xs text-[#ffffff]">
                <img
                  src="/icon-protect-1.svg"
                  alt="asign protect"
                  className="object-cover w-3 h-3 -mt-px"
                />
                Asign Protect+
              </p>
            )} */}
            <p className="mt-1 text-base mq750:text-xs font-sh5 leading-[16.8px] text-[#ffffff]">
              {data?.artistName}
            </p>
            <p
              className={cn(
                "text-base  capitalize mt-1 font-sh5 line-clamp-1 text-[#CCCCCC] leading-[12px]",
                className?.h1,
              )}
            >
              {data?.objectName}
            </p>
            <p
              className={cn(
                " text-[12px] mq750:text-base mt-1 text-[#CCCCCC] font-sh5 tracking-tighter leading-[12px]",
                className?.date,
              )}
            >
              {!data?.metaData?.isCreationDateUnknown && (
                <>
                  {data?.metaData?.circa &&
                    "c. " +
                      format(data.metaData?.creationDate?.year, "yyyy") +
                      " " +
                      data.metaData?.creationDate?.era}
                </>
              )}
            </p>
            {/* <p className={cn("text-[16px] mq750:text-xs", className?.price)}>
              ₹7,500{" "}
            </p> */}
          </div>
          <div className="">
            <ObjectLike
              id={getId(data)}
              className="bg-transparent border border-white border-solid"
              iconClass={className?.likeIcon}
              likeClass={className?.likesvg}
            />
          </div>
        </div>
        <AddBoardDropDown itemID={getId(data)} boardID={""} itemType={"object"}>
          <span
            dir="ltr"
            className="absolute top-4 font-sh5 bg-white text-other-cta rounded-full text-[16px] right-3 p-[9px_20px] flex gap-x-1 opacity-0 scale-95 group-hover:opacity-100  invisible group-hover:visible cursor-pointer transition-all duration-300 ease-in-out items-center"
          >
            {allObjectsInBoards.includes(getId(data)) ? (
              <img
                src="/boardsSelecteddesktop.svg"
                alt="label"
                className="w-4 mq450:h-4"
              />
            ) : (
              <img src="/label.svg" alt="label" className="w-4 mq450:h-4" />
            )}
            Board
          </span>
        </AddBoardDropDown>
      </>
    );
  } else if (type === "events") {
    return (
      <>
        <div
          className={cn(
            "absolute left-0 flex items-center justify-between invisible w-full px-[10px] text-white transition-all ease-in-out bottom-[30px] group-hover:visible ",
            className?.parent,
          )}
          dir={order === "right" ? "rtl" : "ltr"}
        >
          <div className="font-sh5">
            <p className="[@media(max-width:508px)]:block hidden text-sm capitalize group-hover:block">
              {data?.eventName}
            </p>
            <p className="text-sm text-[#CCCCCC] capitalize mt-1">
              {data?.country ?? ""}
            </p>
            <p className="text-sm text-[#CCCCCC] mt-1">
              {format(new Date(data.startDate ?? new Date()), "MMM d, yyyy")} -{" "}
              {format(new Date(data.endDate ?? new Date()), "MMM d, yyyy")}{" "}
            </p>
            <p className="text-sm text-[#CCCCCC] mt-1 [@media(max-width:508px)]:block hidden group-hover:block ">
              {format(new Date(data.startDate ?? new Date()), "h:mm a")} -{" "}
              {format(new Date(data.endDate ?? new Date()), "h:mm a")}{" "}
            </p>
          </div>
          <Button
            variant="outline"
            className="text-white border-white text-[12px] rounded-[33px] hidden"
          >
            ATTEND
          </Button>
        </div>
        <AddBoardDropDown itemID={getId(data)} boardID={""} itemType={"events"}>
          <span
            dir="ltr"
            className={`absolute font-sh5 top-4 bg-white text-other-cta rounded-full  p-[5.5px_12px] text-sm flex gap-x-1 [@media(max-width:508px)]:opacity-100 opacity-0  group-hover:opacity-100 [@media(max-width:508px)]:visible invisible group-hover:visible cursor-pointer transition-all duration-300 ease-in-out items-center ${
              className?.board ? className.board : "right-[10px]"
            }`}
          >
            {allObjectsInBoards.includes(getId(data)) ? (
              <img
                src="/boardsSelecteddesktop.svg"
                alt="label"
                className="w-4 mq450:h-4"
              />
            ) : (
              <img src="/label.svg" alt="label" className="w-4 mq450:h-4" />
            )}{" "}
            Board
          </span>
        </AddBoardDropDown>
      </>
    );
  } else if (type === "business") {
    return (
      <>
        <div
          className={cn(
            "absolute left-0 flex items-center justify-between invisible w-full px-2 text-white transition-all ease-in-out bottom-5 group-hover:visible",
            className?.parent,
          )}
          dir={order === "right" ? "rtl" : "ltr"}
        >
          <div className="flex items-center gap-[10px]">
            <Avatar className={cn("cursor-pointer", className?.avatar)}>
              <AvatarImage src={data?.profilePic} alt="" />
              <AvatarFallback>{data?.displayName}</AvatarFallback>
            </Avatar>
            <div>
              <h1 className="text-[16px] font-thin font-eb tracking-wide truncate capitalize ">
                {data?.displayName}
              </h1>
              <p className="text-base font-sh5 text-[#CCCCCC] capitalize">
                {data?.registerPlace}
              </p>
            </div>
          </div>
          {/*  <FollowBussiness id={data?._id} /> */}
        </div>
        <AddBoardDropDown
          itemID={getId(data)}
          boardID={""}
          itemType={"business"}
        >
          <span
            dir="ltr"
            className="absolute top-4 font-sh5 bg-white text-other-cta rounded-full right-4 p-[5.5px_12px] text-base flex gap-x-1 opacity-0 scale-95 group-hover:opacity-100  invisible group-hover:visible cursor-pointer transition-all duration-300 ease-in-out items-center px-[20px] py-[9px]"
          >
            {allObjectsInBoards.includes(getId(data)) ? (
              <img
                src="/boardsSelecteddesktop.svg"
                alt="label"
                className="w-4 mq450:h-4"
              />
            ) : (
              <img src="/label.svg" alt="label" className="w-4 mq450:h-4" />
            )}{" "}
            Board
          </span>
        </AddBoardDropDown>
      </>
    );
  } else if (type == "video") {
    return (
      <PlayVideo url={data?.content?.url} title={data?.content?.title}>
        <div className="absolute top-0 left-0 w-full h-full cursor-pointer">
          <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] bg-white rounded-full w-[51px] h-[51px] cursor-pointer transition-all ease-in-out flex items-center justify-center">
            <img
              src="/play.svg"
              alt="Play Button"
              className="absolute left-[18px]"
            />
          </div>

          <div
            className="absolute z-10 flex  justify-between invisible w-full px-2 text-white transition-all ease-in-out left-3 bottom-[32px] group-hover:visible"
            dir={order === "right" ? "rtl" : "ltr"}
          >
            <h1 className="flex items-center tracking-wide capitalize dot-compress truncate text-[20px] font-sh5 font-normal">
              {data?.content?.title}
            </h1>
            <div className="mr-5">
              <ObjectLike
                id={getId(data)}
                className="bg-transparent border border-white border-solid"
                iconClass="h-[48px] w-[48px] mb-2"
                likeClass="h-[24px] w-[24px]"
              />
            </div>
          </div>
          <div className="" onClick={(e) => e.stopPropagation()}>
            <AddBoardDropDown itemID={getId(data)} boardID={""} itemType={type}>
              <span
                dir="ltr"
                className="absolute top-4 font-sh5 bg-white text-other-cta rounded-full right-4 p-[5.5px_12px] text-base flex gap-x-1 opacity-0 scale-95 group-hover:opacity-100  invisible group-hover:visible cursor-pointer transition-all duration-300 ease-in-out items-center"
              >
                {allObjectsInBoards.includes(getId(data)) ? (
                  <img
                    src="/boardsSelecteddesktop.svg"
                    alt="label"
                    className="w-4 mq450:h-4"
                  />
                ) : (
                  <img src="/label.svg" alt="label" className="w-4 mq450:h-4" />
                )}{" "}
                Board
              </span>
            </AddBoardDropDown>
          </div>
        </div>
      </PlayVideo>
    );
  } else if (type == "writing") {
    return (
      <div className="absolute top-0 left-0 w-full h-full cursor-pointer">
        <div className="absolute left-0 w-full px-4 font-normal text-white md:px-3 lg:px-5 bottom-5 font-sh5">
          <Badge className="px-2 py-1">
            <img src="/writing.svg" alt="writing icon" className="w-[20px]" />
            <span className="max-w-[50px] truncate capitalize text-xs text-[#1D1D1D] ml-[6px]">
              Read
            </span>
          </Badge>
          <p className="mt-4 text-[20px] leading-6 capitalize normal tracking-tight line-clamp-2">
            {data?.content?.title}
          </p>
          <p className="mt-[15px] text-xs capitalize">
            {data?.content?.authorName}
          </p>
        </div>

        <div className="" onClick={(e) => e.stopPropagation()}>
          <AddBoardDropDown itemID={getId(data)} boardID={""} itemType={type}>
            <span
              dir="ltr"
              className="absolute top-4 font-sh5 bg-white text-other-cta rounded-full right-4 p-[5.5px_12px] text-sm flex gap-x-1 opacity-0 scale-95 group-hover:opacity-100  invisible group-hover:visible cursor-pointer transition-all duration-300 ease-in-out items-center"
            >
              {allObjectsInBoards.includes(getId(data)) ? (
                <img
                  src="/boardsSelecteddesktop.svg"
                  alt="label"
                  className="w-4 mq450:h-4"
                />
              ) : (
                <img src="/label.svg" alt="label" className="w-4 mq450:h-4" />
              )}{" "}
              Board
            </span>
          </AddBoardDropDown>
        </div>
      </div>
    );
  }

  return null; // Ensure to return null if none of the types match
};

export const ObjectLike = ({
  id,
  className,
  iconClass,
  likeClass,
}: {
  id?: string;
  className?: string;
  iconClass?: string;
  likeClass?: string;
}) => {
  const { interactions } = useUserContext();
  const isLiked = findById(interactions?.objectsLiked, id ?? "");
  return (
    <Like
      itemId={id ?? " "}
      action={isLiked ? "remove" : "add"}
      itemType="object"
    >
      <div
        className={cn(
          `rounded-full cursor-pointer  flex items-center justify-center w-[48px] h-[48px] ${
            iconClass ? iconClass : "w-[32px] h-[32px] "
          }`,
          className,
          `${isLiked ? " bg-white" : ""}`,
        )}
      >
        {isLiked ? (
          <img
            src="/dis-fill-heart.svg"
            alt=""
            className={likeClass ? likeClass : "h-[16px] w-[16px]"}
          />
        ) : (
          <img
            src="/dis-heart.svg"
            alt=""
            className={likeClass ? likeClass : "h-[16px] w-[16px]"}
          />
        )}
      </div>
    </Like>
  );
};
