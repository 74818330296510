import { apis } from ".";
interface ContactUs {
  fullName: String;
  email: String;
  phone: String;
  comments: String;
}
const usePrelogin = () => {
  const updateContactUs = async (contactUsData: ContactUs): Promise<any> => {
    try {
      const { data } = await apis.postPrelogin(
        "client/api/v1/prelogin/contactus",
        {
          data: contactUsData,
        },
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  };
  return { updateContactUs };
};

export default usePrelogin;
