import React from "react";
import { useState } from "react";

const Featured = () => {
  const [activeTab, setActiveTab] = useState("artists");

  const tabs = ["artists", "artworks", "galleries"];
  const content: { [key: string]: { name: string; image: string }[] } = {
    artists: [
      {
        name: "Zarina Hashmi",
        image: "/prelogin-features-img/artists/Zarina_Hashmi.jpg",
      },
      {
        name: "Sayed Haider Raza",
        image: "/prelogin-features-img/artists/Sayed_Haider_Raza.jpg",
      },
      {
        name: "Madhvi Parekh",
        image: "/prelogin-features-img/artists/Madhvi_Parekh.jpg",
      },
      {
        name: "Ram Kumar",
        image: "/prelogin-features-img/artists/Ram_Kumar.jpg",
      },
      {
        name: "Natvar Bhavsar",
        image: "/prelogin-features-img/artists/Natvar_bhasar.jpg",
      },
      {
        name: "Manohar Mhatre",
        image: "/prelogin-features-img/artists/Manahor_Mhatre.jpg",
      },
      {
        name: "Anjolie Ela Menon",
        image: "/prelogin-features-img/artists/Anjolie_Ela_Menon.jpg",
      },
      {
        name: "Krishen Khanna",
        image: "/prelogin-features-img/artists/krishen_khanna.jpg",
      },
      {
        name: "Asma Menon",
        image: "/prelogin-features-img/artists/Asma_Menon.jpg",
      },
      {
        name: "Jogen Chowdhury",
        image: "/prelogin-features-img/artists/Jogen_Chowdhury.jpg",
      },
      {
        name: "Shanthi Kasi",
        image: "/prelogin-features-img/artists/Shanthi_Kasi.jpg",
      },
      {
        name: "C Krishnaswamy",
        image: "/prelogin-features-img/artists/C_Krishnaswamy.jpg",
      },
    ],
    artworks: [
      {
        name: "Untitled",
        image: "/prelogin-features-img/artworks/1.webp",
      },
      {
        name: "Untitled (Farmer Series)",
        image: "/prelogin-features-img/artworks/2.webp",
      },
      {
        name: "Untitled",
        image: "/prelogin-features-img/artworks/3.webp",
      },
      {
        name: "Untitled",
        image: "/prelogin-features-img/artworks/4.webp",
      },
      {
        name: "Untitled",
        image: "/prelogin-features-img/artworks/5.webp",
      },
      {
        name: "Untitled",
        image: "/prelogin-features-img/artworks/6.webp",
      },
      {
        name: "Untitled",
        image: "/prelogin-features-img/artworks/7.webp",
      },
      {
        name: "Untitled",
        image: "/prelogin-features-img/artworks/8.webp",
      },
      {
        name: "Untitled",
        image: "/prelogin-features-img/artworks/9.webp",
      },
      {
        name: "Leader's Perch",
        image: "/prelogin-features-img/artworks/10.webp",
      },
      {
        name: "Untitled",
        image: "/prelogin-features-img/artworks/11.webp",
      },
      {
        name: "Untitled (Two Sisters)",
        image: "/prelogin-features-img/artworks/12.webp",
      },
    ],
    galleries: [
      {
        name: "AICON",
        image: "/prelogin-features-img/gallaries/1.png",
      },
      {
        name: "Artworld",
        image: "/prelogin-features-img/gallaries/2.png",
      },
      {
        name: "Ashvita’s",
        image: "/prelogin-features-img/gallaries/3.png",
      },
      {
        name: "Crayon Art Gallery",
        image: "/prelogin-features-img/gallaries/4.png",
      },
      {
        name: "Chatterjee & Lal",
        image: "/prelogin-features-img/gallaries/5.png",
      },
      {
        name: "DAG",
        image: "/prelogin-features-img/gallaries/6.png",
      },
    ],
  };

  return (
    <section className="2xl:container pt-[100px] max-md:pt-[60px] max-2xl:px-[48px] max-md:px-[24px] max-md:pr-0">
      <h2 className="text-center text-[24px] max-md:text-[14px] max-md:leading-[120%] max-md:mb-[12px] leading-[100%] tracking-0 text-[#1D1D1D] font-normal mb-[28px]">
        Featured
      </h2>

      <ul className="flex justify-center gap-[40px] list-none space-x-4 mb-[80px] max-md:mb-[40px] max-md:px-[24px]">
        {tabs.map((tab) => (
          <li
            key={tab}
            className={`flex flex-col items-center w-[calc(100%/3-20px)] max-md:w-[calc(100%+20px)]  ${
              activeTab === tab ? "bdr-bottom" : ""
            }`}
          >
            <button
              onClick={() => setActiveTab(tab)}
              className={`py-[32px] max-md:py-[16px] text-[48px] max-md:text-[20px] max-md:leading-[120%] tracking-0 leading-[100%] text-[#1D1D1D]  ${
                activeTab === tab ? "" : ""
              }`}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          </li>
        ))}
      </ul>

      <div className="artist-sec featured__arts">
        {content[activeTab].map((item, index) => (
          <div key={index} className="text-start featured__art">
            <div className="featured__artImgWrapper">
              <img
                src={item.image}
                alt={item.name}
                className={` ${activeTab === "artists" ? "grayscale" : ""}`}
              />
            </div>
            <p className=" text-[16px] text-start  font-normal leading-[120%] tracking-0 text-[#696969]">
              {item.name}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Featured;
