import React, { createContext, useContext } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import useUserAPI, { UserInteraction } from "@/apis/user";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { useAuth } from "@/hooks/useAuth";

// Define the shape of the context value
interface UserContextValue {
  interactions: UserInteraction | undefined;
  isLoading: boolean;
  isError: boolean;
  me: any;
  isLoadingMe: boolean;
  isErrorMe: boolean;
  updateUser: any;
  updateUserLogin: any;
  [key: string]: any;
  // Add more properties as needed
}

// Create the UserContext with a default value
const UserContext = createContext<UserContextValue>({
  interactions: undefined,
  isLoading: true,
  isError: false,
  me: undefined,
  isLoadingMe: true,
  isErrorMe: false,
  updateUser: undefined,
  updateUserLogin: undefined,
});

const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { getUserIntreaction, myInfo, updateMe } = useUserAPI();
  const location = useLocation();
  const { isLogin } = useAuth();
  const currentLocation = location.pathname;

  // Define stable callback functions to prevent unnecessary re-renders
  const handleSuccess = React.useCallback(() => {
    toast.dismiss();
    toast.success("Profile updated successfully");
  }, []);

  const handleError = React.useCallback(() => {
    toast.dismiss();
    toast.error("Something went wrong");
  }, []);

  const handleMutate = React.useCallback(() => {
    toast.loading("Updating profile...");
  }, []);

  // Use useQuery to fetch user interactions
  const {
    data: interactions,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["user-interactions"],
    queryFn: () => getUserIntreaction({ id: "" }),
    enabled: isLogin,
  });

  const {
    data: me,
    isLoading: isLoadingMe,
    isError: isErrorMe,
  } = useQuery({
    queryKey: ["me"],
    queryFn: () => myInfo(),
    enabled: isLogin,
  });

  // Memoize the mutation with stable callbacks
  const updateUser = useMutation({
    mutationFn: updateMe,
    onSuccess: handleSuccess,
    onError: handleError,
    onMutate: handleMutate,
  });

  const updateUserLogin = useMutation({
    mutationFn: updateMe,
  });

  const value = React.useMemo(
    () => ({
      interactions,
      isLoading,
      isError,
      isLoadingMe,
      isErrorMe,
      me,
      updateUser,
      updateUserLogin,
      // Add more values as needed
    }),
    [
      interactions,
      isLoading,
      isLoadingMe,
      isError,
      isErrorMe,
      me,
      updateUser,
      updateUserLogin,
    ],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

// Export the context provider and the custom hook for consuming the context
export default UserContextProvider;
export const useUserContext = () => useContext(UserContext);
