import useSearch from "@/apis/search";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import { useUserContext } from "@/context/user";
import useMediaQuery from "@/hooks/use-media-query";
import useFilter from "@/hooks/useFilter";
import { cn } from "@/lib/utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Clock, Search } from "lucide-react";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { set } from "react-hook-form";

interface Category {
  id: string;
  name: string;
}

interface Tag {
  id: string;
  name: string;
}

interface HistoryItem {
  id: string;
  name: string;
}

export const categories: Category[] = [
  { id: "all", name: "All" },
  { id: "objects", name: "Objects" },
  { id: "artists", name: "Artists" },
  { id: "businesses", name: "Businesses" },
  { id: "events", name: "Events" },
  { id: "videos", name: "Videos" },
  { id: "writing", name: "Writing" },
];

const FigureSearch = ({
  children,
  classname,
}: {
  children: React.ReactNode;
  classname?: string;
}) => {
  const { me } = useUserContext();
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const { autoSuggestion } = useSearch();
  const [query, setQuery] = useState("");
  const [searchParams] = useSearchParams();
  const search = searchParams?.get("q");
  const searchCategroy = searchParams?.get("category");
  const [category, setCategory] = useState(searchCategroy ?? "all");
  const navigate = useNavigate();

  // auto suggestion
  const { isLoading, data } = useQuery({
    queryKey: ["autoSuggestion", query, category],
    queryFn: () => autoSuggestion(query, category),
    enabled: !!query,
  });

  // debounce search
  const debounceSearch = useDebouncedCallback((query) => {
    setQuery(query);
  }, 500);

  return (
    <>
      {isDesktop ? (
        <Dialog>
          <DialogTrigger asChild>{children}</DialogTrigger>
          <DialogContent
            className={cn(
              "max-w-[655px] shadow-none top-0 bg-transparent fixed translate-y-0",
              classname,
            )}
          >
            {/* search component */}
            <DialogTitle>
              <div className=" rounded-[20px] bg-bg-5 mt-7 mb-3 sticky top-0 h-[95px]">
                <div className="bg-white rounded-full shadow-sm -translate-y-1 border border-solid border-[#cfcfcf]">
                  <div className="flex items-center h-12 px-4">
                    <Search className="w-5 h-5 stroke-[1px]" />
                    <input
                      type="text"
                      placeholder="Search"
                      className="flex-1 ml-3 text-base text-center outline-none"
                      onChange={(e) => debounceSearch(e.target.value)}
                      defaultValue={search || ""}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          navigate(`/search?q=${query}&category=${category}`);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="flex gap-1 overflow-x-auto pb-[6px] px-10 justify-center mx-2 mt-[2px]">
                  {categories.map((item) => (
                    <button
                      key={item.id}
                      className={`px-5 py-[9.5px] rounded-full border border-solid text-sm whitespace-nowrap ${
                        item.id === category
                          ? "bg-black text-white border-gray-200"
                          : "bg-white text-[#696969] border border-gray-200"
                      }`}
                      onClick={() => setCategory(item.id)}
                    >
                      {item.name}
                    </button>
                  ))}
                </div>
              </div>
            </DialogTitle>
            {/* Add your search content here */}
            {data && data.length > 0 ? (
              <div className="p-2 bg-white rounded-[20px] overflow-y-auto">
                <div className="flex flex-col space-y-1">
                  {data?.map((result: string, index: number) => {
                    const lowerResult = result.toLowerCase();
                    const lowerQuery = query.toLowerCase();
                    const startIndex = lowerResult.indexOf(lowerQuery);

                    if (startIndex === -1) {
                      return (
                        <button
                          key={index}
                          className="px-3 py-2 text-base text-center rounded-lg hover:bg-gray-50"
                          onClick={() =>
                            navigate(`/search?q=${result}&category=${category}`)
                          }
                        >
                          <span className="font-medium text-gray-900">
                            {result}
                          </span>
                        </button>
                      );
                    }

                    const beforeMatch = result.slice(0, startIndex);
                    const match = result.slice(
                      startIndex,
                      startIndex + query.length,
                    );
                    const afterMatch = result.slice(startIndex + query.length);

                    return (
                      <button
                        key={index}
                        className="px-3 py-2 text-base text-center rounded-lg hover:bg-gray-50"
                        onClick={() =>
                          navigate(`/search?q=${result}&category=${category}`)
                        }
                      >
                        <span className="font-medium text-gray-40">
                          {beforeMatch}
                          <span className="font-medium text-black">
                            {match}
                          </span>
                          {afterMatch}
                        </span>
                      </button>
                    );
                  })}
                </div>
              </div>
            ) : (
              <>
                {
                  <div className="flex flex-wrap gap-2 p-4 bg-white rounded-[20px] items-center justify-center">
                    {me?.searchHistory?.length > 0 ? (
                      me?.searchHistory?.map((item: string, i: number) => (
                        <button
                          key={i + item}
                          onClick={() =>
                            navigate(`/search?q=${query}&category=${category}`)
                          }
                          className="flex items-center gap-2 p-[10px] text-sm text-[#696969] bg-white border border-solid border-[#cfcfcf] rounded-[10px] hover:bg-gray-50"
                        >
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <mask
                              id="mask0_1215_105586"
                              style={{ maskType: "alpha" }}
                              maskUnits="userSpaceOnUse"
                              x="0"
                              y="0"
                              width="16"
                              height="16"
                            >
                              <rect width="16" height="16" fill="#D9D9D9" />
                            </mask>
                            <g mask="url(#mask0_1215_105586)">
                              <path
                                d="M7.98725 13.6692C6.54366 13.6692 5.28555 13.194 4.2129 12.2436C3.14026 11.2932 2.52274 10.1017 2.36035 8.66926H3.38083C3.54921 9.82053 4.06737 10.775 4.93532 11.5327C5.80327 12.2904 6.82058 12.6693 7.98725 12.6693C9.28725 12.6693 10.39 12.2165 11.2956 11.3109C12.2011 10.4054 12.6539 9.30259 12.6539 8.00259C12.6539 6.70259 12.2011 5.59981 11.2956 4.69425C10.39 3.7887 9.28725 3.33592 7.98725 3.33592C7.25905 3.33592 6.57657 3.49767 5.93982 3.82117C5.30307 4.14467 4.75478 4.58975 4.29495 5.15642H6.03854V6.1564H2.65397V2.77182H3.65393V4.35132C4.19496 3.71287 4.84282 3.21735 5.59752 2.86479C6.35222 2.51222 7.1488 2.33594 7.98725 2.33594C8.77357 2.33594 9.51032 2.48466 10.1975 2.7821C10.8847 3.07954 11.4838 3.4838 11.9949 3.9949C12.506 4.50602 12.9103 5.10516 13.2077 5.79234C13.5052 6.47952 13.6539 7.21627 13.6539 8.00259C13.6539 8.78891 13.5052 9.52566 13.2077 10.2128C12.9103 10.9 12.506 11.4992 11.9949 12.0103C11.4838 12.5214 10.8847 12.9256 10.1975 13.2231C9.51032 13.5205 8.77357 13.6692 7.98725 13.6692ZM9.98853 10.6872L7.50648 8.20516V4.66925H8.50647V7.80002L10.6911 9.98466L9.98853 10.6872Z"
                                fill="#1D1D1D"
                              />
                            </g>
                          </svg>
                          {item}
                        </button>
                      ))
                    ) : (
                      <div className=" font-sh5  flex items-center justify-center w-full h-full p-5 text-[#696969] ">
                        <p> No search history to display. </p>
                      </div>
                    )}
                  </div>
                }
              </>
            )}
          </DialogContent>
        </Dialog>
      ) : (
        <Drawer>
          <DrawerTrigger asChild>{children}</DrawerTrigger>
          <DrawerContent className="h-screen mb-0 rounded-none">
            <DrawerHeader className={`text-left bg-[#EEEEEA] p-5 m-0`}>
              <DrawerTitle className="pt-1 pl-1 text-5xl font-normal text-gray-100 font-sh5">
                <div className="flex items-center gap-4">
                  <div className="bg-transparent w-full bg-white border border-solid border-[#cfcfcf] rounded-full shadow-sm">
                    <div className="flex items-center h-12 px-4">
                      <Search className="w-5 h-5 stroke-[1px]" />
                      <input
                        type="text"
                        placeholder="Search for"
                        className="flex-1 ml-3 text-base bg-transparent outline-none"
                        onChange={(e) => debounceSearch(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            navigate(`/search?q=${query}&category=${category}`);
                          }
                        }}
                      />
                    </div>
                  </div>

                  <DialogClose asChild className="w-6 h-6 cursor-pointer">
                    <img src="/close1.svg" alt="Close" />
                  </DialogClose>
                </div>
                <div className="flex gap-1 pt-3 pb-1 overflow-x-scroll w-[calc(100vw-44px)] px-0.5">
                  {categories.map((item) => (
                    <button
                      key={item.id}
                      // onClick={() => console.log("but")}
                      className={`px-4 py-2 rounded-full text-sm whitespace-nowrap ${
                        item.id === "all"
                          ? "bg-black text-white"
                          : "bg-white text-gray-900 border border-gray-200"
                      }`}
                    >
                      {item.name}
                    </button>
                  ))}
                </div>
              </DrawerTitle>
            </DrawerHeader>

            {data && data.length > 0 ? (
              <div className="p-2 bg-white rounded-[20px] h-[200px] overflow-y-auto">
                <div className="flex flex-col space-y-1">
                  {data?.map((result: string, index: number) => {
                    const lowerResult = result.toLowerCase();
                    const lowerQuery = query.toLowerCase();
                    const startIndex = lowerResult.indexOf(lowerQuery);

                    if (startIndex === -1) {
                      return (
                        <button
                          key={index}
                          className="px-8 py-2 text-base text-left rounded-lg hover:bg-gray-50"
                          onClick={() =>
                            navigate(`/search?q=${query}&category=${category}`)
                          }
                        >
                          <span className="font-medium text-gray-900">
                            {result}
                          </span>
                        </button>
                      );
                    }

                    const beforeMatch = result.slice(0, startIndex);
                    const match = result.slice(
                      startIndex,
                      startIndex + query.length,
                    );
                    const afterMatch = result.slice(startIndex + query.length);

                    return (
                      <button
                        key={index}
                        className="px-8 py-2 text-base text-left rounded-lg hover:bg-gray-50"
                        onClick={() =>
                          navigate(`/search?q=${query}&category=${category}`)
                        }
                      >
                        <span className="font-medium text-gray-40">
                          {beforeMatch}
                          <span className="font-medium text-black">
                            {match}
                          </span>
                          {afterMatch}
                        </span>
                      </button>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="flex items-center gap-2 p-5 overflow-x-auto">
                {me?.searchHistory?.map((item: string, i: number) => (
                  <button
                    key={item + i}
                    onClick={() =>
                      navigate(`/search?q=${query}&category=${category}`)
                    }
                    className="flex items-center gap-2 px-[10px] py-2 text-sm text-[#696969] bg-white border border-solid border-[#cfcfcf] rounded-[10px] hover:bg-gray-50"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_1215_105586"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="16"
                        height="16"
                      >
                        <rect width="16" height="16" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_1215_105586)">
                        <path
                          d="M7.98725 13.6692C6.54366 13.6692 5.28555 13.194 4.2129 12.2436C3.14026 11.2932 2.52274 10.1017 2.36035 8.66926H3.38083C3.54921 9.82053 4.06737 10.775 4.93532 11.5327C5.80327 12.2904 6.82058 12.6693 7.98725 12.6693C9.28725 12.6693 10.39 12.2165 11.2956 11.3109C12.2011 10.4054 12.6539 9.30259 12.6539 8.00259C12.6539 6.70259 12.2011 5.59981 11.2956 4.69425C10.39 3.7887 9.28725 3.33592 7.98725 3.33592C7.25905 3.33592 6.57657 3.49767 5.93982 3.82117C5.30307 4.14467 4.75478 4.58975 4.29495 5.15642H6.03854V6.1564H2.65397V2.77182H3.65393V4.35132C4.19496 3.71287 4.84282 3.21735 5.59752 2.86479C6.35222 2.51222 7.1488 2.33594 7.98725 2.33594C8.77357 2.33594 9.51032 2.48466 10.1975 2.7821C10.8847 3.07954 11.4838 3.4838 11.9949 3.9949C12.506 4.50602 12.9103 5.10516 13.2077 5.79234C13.5052 6.47952 13.6539 7.21627 13.6539 8.00259C13.6539 8.78891 13.5052 9.52566 13.2077 10.2128C12.9103 10.9 12.506 11.4992 11.9949 12.0103C11.4838 12.5214 10.8847 12.9256 10.1975 13.2231C9.51032 13.5205 8.77357 13.6692 7.98725 13.6692ZM9.98853 10.6872L7.50648 8.20516V4.66925H8.50647V7.80002L10.6911 9.98466L9.98853 10.6872Z"
                          fill="#1D1D1D"
                        />
                      </g>
                    </svg>

                    {item}
                  </button>
                ))}
              </div>
            )}
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};

export default FigureSearch;
