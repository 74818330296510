import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

type RouteMeta = {
  title: string;
  description: string;
};

const routesMeta: Record<string, RouteMeta> = {
  "/": {
    title: "Asign.art | Buy, sell, and showcase artwork online",
    description: "Buy, sell, and showcase artwork online",
  },
  "/login": {
    title: "Login | Sign In",
    description: "Sign in to your account",
  },
  "/register": {
    title: "Register | Create Account",
    description: "Create a new account",
  },
  "/contact": {
    title: "Contact Us | Get in Touch",
    description: "Get in touch with us",
  },
  "/privacy-policy": {
    title: "Privacy Policy | Asign.art",
    description: "Learn about our privacy practices",
  },
  "/terms-and-conditions": {
    title: "Terms and Conditions | Asign.art",
    description: "Read our terms of service",
  },
  "/add-image-guidelines": {
    title: "Image Guidelines | Asign.art",
    description: "Learn about image upload rules",
  },
};

const getDynamicRouteMeta = (path: string): RouteMeta | null => {
  if (path === "/artist") {
    return {
      title: "Artist | Explore",
      description: "Learn more about this artist",
    };
  }
  if (path === "/business") {
    return {
      title: "Business | Explore",
      description: "Learn more about this business",
    };
  }
  if (path === "/collectors") {
    return {
      title: "Collectors | Explore",
      description: "Learn more about collectors",
    };
  }
  if (path === "/everyone") {
    return {
      title: "Everyone | Explore",
      description: "Learn more about everyone",
    };
  }
  return null;
};
declare global {
  interface Window {
    dataLayer: any[];
    gtag?: (...args: any[]) => void;
  }
}

const SEO: React.FC = () => {
  const { pathname } = useLocation();

  const defaultMeta: RouteMeta = {
    title: "Asign.art | Buy, sell, and showcase artwork online",
    description: "Buy, sell, and showcase artwork online",
  };

  const meta =
    routesMeta[pathname] || getDynamicRouteMeta(pathname) || defaultMeta;

  useEffect(() => {
    document.title = meta.title;
  }, [meta.title, pathname]);

  useEffect(() => {
    const loadScript = (id: string) => {
      if (!id) return;
      const script = document.createElement("script");
      script.async = true;
      script.id = "google-analytics";
      script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
      if (!document.getElementById("google-analytics")) {
        document.head.appendChild(script);
        script.onload = () => {
          window.dataLayer = window.dataLayer || [];
          window.gtag = function gtag() {
            window.dataLayer.push(arguments);
          };
          window.gtag("js", new Date());
          window.gtag("config", id, { anonymize_ip: true });
        };
      }
    };

    const loadGSCmetatag = (id: string) => {
      if (!id) return;
      const meta = document.createElement("meta");
      meta.id = "google-site-verification";
      meta.name = "google-site-verification";
      meta.content = id;
      if (!document.getElementById("google-site-verification")) {
        document.head.appendChild(meta);
      }
    };

    // Load Google Analytics
    if (import.meta.env.VITE_GA_CODE) {
      loadScript(import.meta.env.VITE_GA_CODE);
    }

    // Load Google Search Console
    if (import.meta.env.VITE_GSC) {
      loadGSCmetatag(import.meta.env.VITE_GSC);
    }
  }, []);

  return (
    <Helmet>
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:type" content="website" />
    </Helmet>
  );
};

export default SEO;
