import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from "react";
import Slider from "react-slick";
import Image from "@/components/Global/Image";
import { cn, createWheelHandler } from "@/lib/utils";
import { sectionType } from "@/types/global.type";
import { getContentImageAndTitle, Item } from "@/helper";
import { OverLaly } from "@/components/Preview/DiscoverPreview";
import { useNavigate } from "react-router-dom";
import LongPressPreivew from "@/modal/LongPressPreivew";
import { ArtObject } from "@/apis/objects";
import { getId } from "@/lib/helper";

interface Props {
  data: {
    templateId: number;
    items: Item[];
  };
  type: sectionType;
  order: string;
}
const DBigBanner = React.memo(({ data, type, order }: Props) => {
  const sliderRef = useRef<Slider | null>(null);

  const next = () => {
    sliderRef.current?.slickNext();
  };

  const previous = () => {
    sliderRef.current?.slickPrev();
  };
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const navigate = useNavigate();
  const CustomDot = useCallback(
    () => <div className="w-[8px] h-[8px] rounded-full bg-gray-80"></div>,
    [],
  );
  let dragging = false;
  const settings = useMemo(
    () => ({
      dots: true,
      customPaging: CustomDot,
      dotsClass: "slick-dots dots-inside-the-slider w-auto ",
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      beforeChange: () => (dragging = true),
      afterChange: () => (dragging = false),
      swipe: true,
      swipeToSlide: true,
      touchThreshold: 30,
      touchMove: true,
    }),
    [CustomDot],
  );

  useEffect(() => {
    const initResizeObserver = () => {
      const container = containerRef.current;
      if (!container) return;

      const updateWidth = () => setContainerWidth(container.offsetWidth);
      const resizeObserver = new ResizeObserver(updateWidth);

      resizeObserver.observe(container);

      updateWidth();

      return () => resizeObserver.disconnect();
    };

    initResizeObserver();
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const wheelHandler = createWheelHandler();
    const handleWheel = (e: WheelEvent) => wheelHandler(e, next, previous);
    container.addEventListener("wheel", handleWheel, { passive: false });
    return () => {
      container.removeEventListener("wheel", handleWheel);
    };
  }, [next, previous]);

  const isSmallWidth = containerWidth < 375;
  const renderSlide = useCallback(
    (item: Item | number, index: number) => {
      const isPlaceholder = typeof item === "number";
      const artist = isPlaceholder ? null : item;

      return (
        <LongPressPreivew type={type} data={item as ArtObject}>
          <div className="relative h-[100%]" key={crypto.randomUUID()}>
            <div
              className={cn(
                "relative overflow-hidden h-[100%] w-full object-cover",
                isSmallWidth ? "aspect-[9/12]" : "",
                isPlaceholder || !item
                  ? "bg-gradient-to-b from-gray-200 to-black"
                  : "",
              )}
            >
              {!isPlaceholder && artist && (
                <Image
                  src={getContentImageAndTitle(item).image}
                  alt={getContentImageAndTitle(item).title}
                  className="object-cover w-full h-full "
                  loading="lazy"
                />
              )}

              {/* {[false, true].map((flip, index) => (
              <img
                key={index}
                className={cn(
                  "p-[3px] bg-[#EEEEEA] rounded-[100%] h-[26px] w-[26px] absolute cursor-pointer z-10 transition-opacity ease-in-out duration-300 group-hover:visible invisible",
                  flip ? "left-5 rotate-180" : "right-5 top-1",
                  "top-1/2 transform -translate-y-1/2",
                )}
                src="/arrow-right.svg"
                alt={flip ? "Previous" : "Next"}
                onClick={flip ? previous : next}
              />
            ))} */}

              <div
                className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-gray-100 to-70% opacity-100 transition-opacity ease-in-out duration-300 cursor-pointer "
                onClick={() => {
                  if (dragging) return;
                  navigate(`/${type}/detail/${getId(item)}`);
                }}
              />
            </div>

            <OverLaly
              type={type}
              data={item}
              className={{
                parent: "px-5 mq750:px-5 visible bottom-[40px]",
                avatar: "w-[72px] h-[72px]",
                h1: "text-base font-sh5",
                date: "text-sm text-[#CCCCCC]",
                likeIcon: "h-[48px] w-[48px] mb-2",
                likesvg: "h-[24px] w-[24px]",
                price: "mt-3",
                board: "right-5",
              }}
            />
          </div>
        </LongPressPreivew>
      );
    },
    [isSmallWidth],
  );

  return (
    <div ref={containerRef} className="group" id="group-slider">
      <div className="absolute left-0 right-0 z-10 flex justify-between pointer-events-none top-1/2">
        {[true, false].map((flip, index) => (
          <div
            key={index}
            className={cn(
              "p-[3px] mx-[23px] rounded-[100%] h-[26px] w-[26px] cursor-pointer transition-opacity ease-in-out duration-300 group-hover:visible invisible pointer-events-auto",
              order === "right"
                ? flip
                  ? "rotate-180 mt-3"
                  : ""
                : flip
                  ? ""
                  : "rotate-180 mt-3",
            )}
            onClick={flip ? previous : next}
          >
            <svg
              width="26"
              height="26"
              viewBox="0 0 26 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_b_6258_2216)">
                <rect
                  x="26"
                  y="26"
                  width="26"
                  height="26"
                  rx="13"
                  transform="rotate(180 26 26)"
                  fill="#EEEEEA"
                />
                <path
                  d="M15.0859 18.3438L10.1306 13.3884L15.0859 8.43304"
                  stroke="#303030"
                  strokeWidth="0.825893"
                />
              </g>
              <defs>
                <filter
                  id="filter0_b_6258_2216"
                  x="-39.6429"
                  y="-39.6429"
                  width="105.286"
                  height="105.286"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feGaussianBlur
                    in="BackgroundImageFix"
                    stdDeviation="19.8214"
                  />
                  <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_6258_2216"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_backgroundBlur_6258_2216"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
        ))}
      </div>
      <Slider className="h-[100%]" {...settings} ref={sliderRef}>
        {data?.items?.length <= 1
          ? [1, 2].map((item, index) => renderSlide(item, index))
          : data?.items?.map((item, index) => renderSlide(item, index))}
      </Slider>
    </div>
  );
});

export default DBigBanner;
