import { Badge } from "@/components/ui/badge";
import React, { useEffect, useState } from "react";

interface props {
  data: {
    text: string;
    logoUrl: string;
    logoName: string;
    displayTags: string[];
  };
}

const Announcement = ({ data }: props) => {
  // if my url have /discover the create a state marking true of false
  const [isDiscover, setIsDiscover] = useState(false);
  useEffect(() => {
    if (window.location.pathname.includes("/discover")) {
      setIsDiscover(true);
    }
  }, [window.location.pathname]);

  console.log(isDiscover);
  return (
    <div className="w-full px-2 text-center font-sh5 bg-bg-5 py-[105px] h-full flex-col items-center justify-center flex relative">
      <div dir="ltr">
        <h2 className="font-normal capitalize leading-[38.4px] font-eb lg:text-[36px] text-5xl whitespace-normal break-all text-wrap text-[#1d1d1d]">
          {data?.text ?? "Title / Tagline"}
        </h2>
        <div className="flex flex-row items-center justify-center gap-1 mt-4">
          <img
            src={data?.logoUrl ?? "/protect.svg"}
            className="mq1000:h-[16px] mq1000:w-[16px] h-[22px] w-[22px]"
            alt="logo"
          />
          <p className="font-sh5 font-normal text-[#1D1D1D] mq1000:text-xs text-base capitalize">
            {data?.logoName ?? "Logo Name"}
          </p>
        </div>
      </div>
      {!isDiscover && (
        <Badge className="absolute capitalize top-5 left-5 lg:p-[12px_16px]">
          {data?.displayTags[0]}
        </Badge>
      )}
    </div>
  );
};

export default Announcement;
