import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const createWheelHandler = () => {
  let lastScrollTime = 0;
  const scrollDelay = 250;

  return (e: WheelEvent, next: () => void, previous: () => void) => {
    if (Math.abs(e.deltaX) > Math.abs(e.deltaY)) {
      e.preventDefault();

      const currentTime = Date.now();
      if (currentTime - lastScrollTime < scrollDelay) {
        return;
      }

      if (Math.abs(e.deltaX) > 50) {
        if (e.deltaX > 0) {
          next();
        } else {
          previous();
        }
        lastScrollTime = currentTime;
      }
    }
  };
};
