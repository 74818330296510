import React from "react";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Banner from "./Components/Banner";
import bannerConfig from "../../constant/banners.json";
import Accordion from "./Components/Faqs";
import Featured from "./Components/Featured";
export default function Collectors() {
  return (
    <>
      <div className="font-sh5 mx-auto px-0">
        <Header />

        <Banner
          desktopImage={bannerConfig.collectors.desktopImage}
          mobileImage={bannerConfig.collectors.mobileImage}
          page="collectors"
        />

        <section className="2xl:container mt-[100px] py-[100px] max-sm:px-[20px] max-md:px-[24px] lg:px-[48px] max-2xl:px-[48px] max-md:mt-[160px] max-md:pt-[40px] max-md:py-[60px] ">
          <div className="max-w-full mx-auto grid grid-cols-1 md:grid-cols-3 gap-12 max-md:gap-12 text-start">
            {/* Artist Profile Pages */}
            <div className="flex flex-col items-start">
              <img
                src="./prelogin-page-images/icon_digital.svg"
                className="w-[48px] h-[48px]  max-sm:w-[40px] max-sm:h-[40px]"
                alt=""
              />
              <h3 className="text-[28px] leading-[100%] max-md:text-[24px] max-sm:mt-[24px] tracking-normal font-normal mb-4 mt-[40px]">
                Business Profile Pages
              </h3>
              <p className="text-[#696969] text-[18px] max-md:text-[16px] leading-[120%] tracking-normal">
                Present artworks and showcase artists you represent with a
                beautifully designed, free page builder.
              </p>
            </div>

            <div className="flex flex-col items-start">
              <img
                src="./prelogin-page-images/icon_register.svg"
                className="w-[48px] h-[48px] max-sm:w-[40px] max-sm:h-[40px]"
                alt=""
              />
              <h3 className="text-[28px] leading-[100%] max-md:text-[24px] max-sm:mt-[24px] tracking-normal font-normal mb-4 mt-[40px]">
                Collection Management
              </h3>
              <p className="text-[#696969] text-[18px]  max-md:text-[16px] leading-[120%] tracking-normal">
                Organise your artworks privately. Detailed information such as
                provenance, location, condition are stored along with all
                related documentation on a secure, digital platform.
              </p>
            </div>
            {/* Sales & Invoicing */}
            <div className="flex flex-col items-start">
              <img
                src="./prelogin-page-images/icon_collections.svg"
                className="w-[48px] h-[48px]  max-sm:w-[40px] max-sm:h-[40px]"
                alt=""
              />
              <h3 className="text-[28px] leading-[100%] max-md:text-[24px] max-sm:mt-[24px] tracking-normal font-normal mb-4 mt-[40px]">
                Multi-Channel Sales
              </h3>
              <p className="text-[#696969]  max-md:text-[16px] text-[18px] leading-[120%] tracking-normal">
                Many ways to get the best value for your artworks – from fixed
                prices or price on request, to exhibitions and auctions, and
                facilitating buyer-seller negotiations
              </p>
            </div>
          </div>
        </section>

        <section className="2xl:container mt-[0px] py-[120px] max-sm:mt-[0px] max-md:py-[60px] max-sm:px-[20px] px-[48px] max-md:px-[24px] max-md:mt-[0px]  bg-[#eeeeea] ">
          <div className="max-w-full mx-auto flex flex-col lg:flex-row items-start lg:items-center space-y-10 lg:space-y-0 lg:space-x-8">
            {/* Title Section */}
            <div className="flex-1">
              <h2 className="text-[48px] max-md:text-[28px] leading-[100%] font-normal text-[#1d1d1d]">
                Advisory Services
              </h2>
            </div>
            {/* Description Section */}
            <div className="flex-1">
              <p className="text-[#696969] max-md:text-[14px] font-normal text-[16px] leading-[120%] tracking-normal">
                We're here to help you unlock the true value of your collection.
                Tap into a team of experts with insider knowledge of the art
                world, and get support on pricing and protecting the integrity
                of your acquisitions, so you can focus on finding more art you
                love.
              </p>
            </div>
          </div>
        </section>

        <section className="2xl:container  py-[100px] lg:px-[48px] max-2xl:px-[48px] max-md:py-[60px] max-sm:px-[20px] max-md:px-[24px]  ">
          <h2 className="text-[#1d1d1d] text-[48px] max-md:text-[28px] pb-[16px] text-center max-md:text-start font-normal leading-[100%]">
            The safest place to buy and sell art &amp; collectibles online
          </h2>
          <p className="text-[18px] leading-[120%] max-sm:text-[16px] tracking-normal max-md:text-start text-center text-[#696969] max-md:mb-[32px] mb-[80px]">
            Network, buy and sell securely on a platform designed exclusively
            for the art world.
          </p>
          <div className="max-w-full mx-auto grid grid-cols-1 md:grid-cols-3 gap-12 text-start">
            {/* Artist Profile Pages */}
            <div className="flex flex-col items-start">
              <img
                src="./prelogin-page-images/icon_verifiedMembers.svg"
                className="w-[48px] h-[48px]  max-sm:w-[40px] max-sm:h-[40px]"
                alt=""
              />
              <h3 className="text-[28px] leading-[100%] max-md:text-[20px] max-md:mt-[24px] tracking-normal font-normal mb-4 mt-[40px]">
                Verified Members
              </h3>
              <p className="text-[#696969] text-[18px] max-md:text-[14px] leading-[120%] tracking-normal ">
                Aadhaar and PAN details of all members are verified.
              </p>
            </div>

            {/* Resale Rights */}
            <div className="flex flex-col items-start">
              <img
                src="./prelogin-page-images/icon_protect.svg"
                className="w-[48px] h-[48px]  max-sm:w-[40px] max-sm:h-[40px]"
                alt=""
              />
              <h3 className="text-[28px] max-md:text-[20px] max-md:mt-[24px] leading-[100%] tracking-normal font-normal mb-4 mt-[40px]">
                Asign Protect+
              </h3>
              <p className="text-[#696969] text-[18px] max-md:text-[14px] leading-[120%] tracking-normal">
                Our proprietary technology authenticates all available artworks.
              </p>
            </div>

            <div className="flex flex-col items-start">
              <img
                src="./prelogin-page-images/icon_blockchain.svg"
                className="w-[48px] h-[48px]  max-sm:w-[40px] max-sm:h-[40px]"
                alt=""
              />
              <h3 className="text-[28px] max-md:text-[20px] max-md:mt-[24px] leading-[100%] tracking-normal font-normal mb-4 mt-[40px]">
                Secure transactions
              </h3>
              <p className="text-[#696969] text-[18px] max-md:text-[14px] leading-[120%] tracking-normal">
                Buy and sell with complete peace of mind
              </p>
            </div>
          </div>
        </section>
        <Accordion page="collectors" />
        <Footer />
      </div>
    </>
  );
}
