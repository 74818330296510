import React, { createContext, useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import useObjects from "@/apis/objects";
import { useSearchParams, useNavigate } from "react-router-dom";
import useFilter, { QueryData } from "@/hooks/useFilter";
import useAsignProtect from "@/apis/protect";
import { set } from "date-fns";
import { apis } from "@/apis";
import { useUserContext } from "./user";
import { useAuth } from "@/hooks/useAuth";

// Define the context type
interface ObjectContextType {
  currentObject: any;
  isLoading: boolean;
  updateOrAddItem: (data: any) => void;
  setFilterOption: React.Dispatch<React.SetStateAction<any>>;
  setQuery: React.Dispatch<React.SetStateAction<any>>;
  removeQueryKey: (key: string) => void;
  filterOption: QueryData;
  objectsList: any;
  setPaginatorData: React.Dispatch<React.SetStateAction<any>>;
  refetchPaginationData: (next: number) => void;
  eligibility: boolean;
  missingFields: string[];
  falseBooleanFields: string[];
  category: any;
  filledFields: any;
  setFilledFields: React.Dispatch<React.SetStateAction<any>>;
  addOrDeleteObjectId: (id: string) => void;
  objectIds: string[];
  setObjectIds: React.Dispatch<React.SetStateAction<string[]>>;
  removeAllQuery: () => void;
  removeOptionKey: (key: string) => void;
  locations: any;
  setLocations: React.Dispatch<React.SetStateAction<any>>;
  isapPageVisited: boolean;
  setIsapPageVisited: React.Dispatch<React.SetStateAction<boolean>>;
  valuationPricing: any;
  setValuationPricing: React.Dispatch<React.SetStateAction<any>>;
  filterList: any;
  isCropModalOpen: boolean;
  setIsCropModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  cropImageSrc: string;
  setCropImageSrc: React.Dispatch<React.SetStateAction<string>>;
  cropImageName: string;
  setCropImageName: React.Dispatch<React.SetStateAction<string>>;
  cropedImage: {
    image: string;
    source: string;
  };
  setCropedImage: React.Dispatch<
    React.SetStateAction<{
      image: string;
      source: string;
    }>
  >;
  handleCropModal: (
    base64String: string,
    name: string,
    source?: string,
  ) => void;
  filterListLoading: boolean;
  resetPaginator: () => void;
}

const ObjectContext = createContext<ObjectContextType | undefined>(undefined);

const ObjectContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { addObjects, updateObject, getObjectById, objectList } = useObjects();
  const { isLogin } = useAuth();
  const { checkEligiblity } = useAsignProtect();
  const { me } = useUserContext();
  const {
    filterOption,
    setQuery,
    setFilterOption,
    removeAllQuery,
    setPaginatorData,
    refetchPaginationData,
    removeOptionKey,
    removeQueryKey,
    resetPaginator,
  } = useFilter({
    options: {
      pagination: true,
      limit: 20,
      offset: 0,
      populate: "",
    },
  });
  const [valuationPricing, setValuationPricing] = useState();
  const [eligibility, setEligibility] = useState(false);
  const [isapPageVisited, setIsapPageVisited] = useState(false);
  const [missingFields, setMissingFields] = useState<string[]>([]);
  const [falseBooleanFields, setFalseBooleanFields] = useState<string[]>([]);
  const [category, setCategory] = useState();
  const [filledFields, setFilledFields] = useState();
  const [objectIds, setObjectIds] = useState<string[]>([]);
  const [locations, setLocations] = useState({});
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const objectId = searchParams.get("oi");
  const isCatalogRaisonne = searchParams.get("catalogue_raisonne");
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  const [cropImageSrc, setCropImageSrc] = useState<string>("");
  const [cropImageName, setCropImageName] = useState("");
  const [cropedImage, setCropedImage] = useState<{
    image: string;
    source: string;
  }>({
    image: "",
    source: "",
  });

  // Check object eligibility
  const eligibilityHandler = useMutation({
    mutationFn: checkEligiblity,
    onSuccess: ({ data }) => {
      const { missingFields, falseBooleanFields, isEligible } = data[0]; // Assuming data structure
      setMissingFields(missingFields);
      setFalseBooleanFields(falseBooleanFields);
      setEligibility(isEligible);
      setCategory(data[0].missingFieldCategory);
      setFilledFields(data[0].missingFieldCategory);
    },
    onError: () => {},
  });

  // Fetch current object if objectId is present
  const currentObject = useQuery({
    queryKey: ["object", objectId],
    queryFn: () => getObjectById({ id: objectId as string }),
    enabled: !!objectId && isLogin,
  });

  const objectsList = useQuery({
    queryKey: ["objectsList", filterOption],
    queryFn: () => isLogin && objectList(filterOption),
  });

  //filter list of the object
  const { data: filterList, isLoading: filterListLoading } = useQuery({
    queryKey: [
      "filterList",
      filterOption?.query?.type,
      filterOption?.query?.artistName,
      filterOption?.query?.creationYear,
      filterOption?.query?.searchQuery,
      filterOption?.query?.size,
    ],
    queryFn: async () => {
      const queryParams = new URLSearchParams(window.location.search);
      let apiReqUrl = "/client/api/v1/search/platform/objects";
      if (queryParams.has("catalogue_raisonne_artist_id")) {
        apiReqUrl = "/client/api/v1/search/platform/raisonne-artist";
      }
      const response = await apis.get(apiReqUrl, {
        params: {
          onlyMe: true,
          type: filterOption?.query?.type,
          artistName: filterOption?.query?.artistName,
          creationYear: filterOption?.query?.creationYear,
          searchQuery: filterOption?.query?.searchQuery,
          size: filterOption?.query?.size,
        },
      });
      return response.data?.data;
    },
    staleTime: 1000 * 60 * 60 * 24, //do not the api for next 24 hours
  });

  // Add Object mutation
  const addObjectHandler = useMutation({
    mutationFn: addObjects,
    onMutate: () => {
      toast.loading("Adding object...");
    },
    onSuccess: (data) => {
      toast.dismiss();
      toast.success("Saved successfully.");
      navigate(`?oi=${data.id}`); // Update URL with new object ID
      queryClient.invalidateQueries({
        queryKey: ["object", data.id],
      }); // Refetch new object
      queryClient.invalidateQueries({
        queryKey: ["objectsList"],
      }); // Refetch updated object
      queryClient.invalidateQueries({
        queryKey: ["filterList"],
      }); // Refetch updated object
      eligibilityHandler.mutate({
        ids: [data.id],
        params: { catalogue_raisonne: isCatalogRaisonne },
      }); // Check eligibility after adding object
    },
    onError: () => {
      toast.dismiss();
      toast.error("Something went wrong");
    },
  });

  // Update Object mutation
  const updateObjectHandler = useMutation({
    mutationFn: updateObject,
    onMutate: () => {
      toast.loading("Updating object...");
    },
    onSuccess: () => {
      toast.dismiss();
      toast.success("Object updated successfully");

      queryClient.invalidateQueries({
        queryKey: ["object", objectId],
      }); // Refetch updated object
      queryClient.invalidateQueries({
        queryKey: ["objectsList"],
      }); // Refetch updated object
      queryClient.invalidateQueries({
        queryKey: ["filterList"],
      }); // Refetch updated object
      eligibilityHandler.mutate({
        ids: [objectId as string],
        params: { catalogue_raisonne: isCatalogRaisonne },
      });
    },

    onError: () => {
      toast.dismiss();
      toast.error("Something went wrong");
    },
  });

  // Helper function to get differences between two objects
  const getObjectDiff = (oldObj: any, newObj: any) => {
    const diff: any = {};
    for (const key in newObj) {
      if (JSON.stringify(oldObj[key]) !== JSON.stringify(newObj[key])) {
        diff[key] = newObj[key];
      }
    }
    return diff;
  };

  // Either add or update object
  const updateOrAddItem = (data: any) => {
    if (objectId && currentObject.data) {
      const updatedFields = getObjectDiff(currentObject.data, data);
      if (Object.keys(updatedFields).length > 0) {
        updateObjectHandler.mutate({
          id: objectId,
          data: { ...updatedFields, objectId: currentObject?.data?.objectId },
        });
      }
    } else {
      const isAnyFieldFilled = Object.values(data).some(
        (field) => field !== "" && field !== null && field !== undefined,
      );
      if (isAnyFieldFilled) {
        addObjectHandler.mutate(data);
      }
    }
  };

  //add object or delete the object id to app for the assignProtect
  const addOrDeleteObjectId = (id: string) => {
    const isIdAlreadyExist = objectIds.find((objId) => objId == id);

    if (isIdAlreadyExist) {
      setObjectIds(objectIds.filter((objId) => objId !== id));
    } else {
      setObjectIds([...objectIds, id]);
    }
  };

  const handleCropModal = (
    base64String: string,
    name: string,
    source?: string,
  ) => {
    setCropImageSrc(base64String);
    setCropImageName(name);
    setIsCropModalOpen(true);
    if (source) {
      setCropedImage((prev) => ({
        ...prev,
        source,
      }));
    }
  };

  useEffect(() => {
    if (objectId) {
      eligibilityHandler.mutate({
        ids: [objectId],
        params: { catalogue_raisonne: isCatalogRaisonne },
      });
    } else {
      setEligibility(false);
      setMissingFields([]);
      setFalseBooleanFields([]);
      setCategory(undefined);
      setFilledFields(undefined);
    }
  }, [objectId]);

  //current location
  useEffect(() => {
    if (!objectId) {
      //set the primary location
      const primaryLocation = me?.address.find(
        (location: any) => location.primary,
      );
      if (!primaryLocation) return;
      setLocations({
        currentLocation: {
          ...primaryLocation,
          locationId: primaryLocation?._id,
          createdAt: new Date(),
        },
      });
    }
  }, [me?.address]);

  const contextValue: ObjectContextType = {
    currentObject: currentObject.data,
    isLoading: currentObject.isLoading,
    updateOrAddItem,
    setFilterOption,
    setPaginatorData,
    refetchPaginationData,
    setQuery,
    removeQueryKey,
    filterOption,
    objectsList,
    eligibility,
    missingFields,
    falseBooleanFields,
    category,
    addOrDeleteObjectId,
    objectIds,
    setObjectIds,
    removeAllQuery,
    removeOptionKey,
    locations,
    setLocations,
    isapPageVisited,
    setIsapPageVisited,
    valuationPricing,
    setValuationPricing,
    filterList,
    isCropModalOpen,
    setIsCropModalOpen,
    cropImageSrc,
    setCropImageSrc,
    cropImageName,
    setCropImageName,
    cropedImage,
    setCropedImage,
    handleCropModal,
    filledFields,
    setFilledFields,
    filterListLoading,
    resetPaginator,
  };

  return (
    <ObjectContext.Provider value={contextValue}>
      {children}
    </ObjectContext.Provider>
  );
};

// Custom hook to use ObjectContext
export const useObjectContext = () => {
  const context = useContext(ObjectContext);
  if (!context) {
    throw new Error(
      "useObjectContext must be used within ObjectContextProvider",
    );
  }
  return context;
};

export default ObjectContextProvider;
