import React, {
  FunctionComponent,
  lazy,
  Suspense,
  memo,
  useRef,
  useCallback,
} from "react";
import Layout from "@/layout/DefaultLayout";
import useFilter from "@/hooks/useFilter";
import useGlobalApis from "@/apis/global";
import { useInfiniteQuery } from "@tanstack/react-query";
import LazyLoader from "@/components/LazyLoader";
const Preview = lazy(() => import("@/components/Preview/Preview"));

const ITEMS_PER_PAGE = 10; // Adjust based on your API's page size

const Discover: FunctionComponent = React.memo(() => {
  const { filterOption } = useFilter({
    query: {
      sectionStatus: "publish",
    },
  });

  const { getFeed } = useGlobalApis();

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    isLoading,
  } = useInfiniteQuery({
    queryKey: ["discover", filterOption],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await getFeed({
        params: filterOption,
        type: "discover",
      });
      return response;
    },
    getNextPageParam: (lastPage, allPages) => {
      // Assuming your API returns total pages or has a way to determine if there's more data
      const hasMore = lastPage.data?.length === ITEMS_PER_PAGE;
      if (!hasMore) return undefined;
      return allPages.length + 1;
    },
    initialPageParam: 1,
  });

  // Intersection Observer setup for infinite scroll
  const intersectionRef = useRef<HTMLDivElement>(null);

  const onIntersect = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (entry.isIntersecting && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage],
  );

  React.useEffect(() => {
    const observer = new IntersectionObserver(onIntersect, {
      rootMargin: "100px", // Load more content before reaching the bottom
      threshold: 0.1,
    });

    if (intersectionRef.current) {
      observer.observe(intersectionRef.current);
    }

    return () => observer.disconnect();
  }, [onIntersect]);

  // Flatten the pages data for the Preview component
  const flattenedData = React.useMemo(() => {
    return data?.pages.flatMap((page) => page.data) ?? [];
  }, [data?.pages]);

  return (
    <Layout viewMobTopNav>
      <Suspense fallback={<LazyLoader />}>
        <Preview
          data={flattenedData}
          type="discover"
          parentclass="gap-0 overflow-x-hidden lg:gap-y-[0px] gap-y-[0px] px-5 lg:px-[48px]"
          isLoading={isLoading}
        />

        {/* Loading indicator and intersection observer target */}
        {isFetchingNextPage && (
          <div ref={intersectionRef} className="w-full py-4">
            <div className="flex justify-center">
              <LazyLoader />
            </div>
          </div>
        )}

        {/* Error state */}
        {status === "error" && (
          <div className="py-4 text-center text-red-500">
            Error loading data. Please try again.
          </div>
        )}
      </Suspense>
    </Layout>
  );
});

export default memo(Discover);
