import useGlobalApis from "@/apis/global";
import { Badge } from "@/components/ui/badge";
import { getContentImageAndTitle } from "@/helper";
import { cn, createWheelHandler } from "@/lib/utils";
import { Content, sectionType } from "@/types/global.type";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useUserContext } from "@/context/user";
import Image from "@/components/Global/Image";
import { getId } from "@/lib/helper";
import { useDebouncedCallback } from "use-debounce";
import { Check } from "lucide-react";
import { useMediaQuery } from "@mui/material";

interface SurveyProps {
  title: string;
  data: any[];
  isImage?: boolean;
  type: sectionType;
  sectionId?: string;
  isDiscover?: boolean;
}

const Survey = ({
  title,
  data,
  isImage,
  type,
  sectionId,
  isDiscover,
}: SurveyProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const sliderRef = useRef<Slider | null>(null);
  const isMobile = useMediaQuery("(max-width: 696px)");
  const { submitSurvey } = useGlobalApis();
  const next = () => {
    sliderRef.current?.slickNext();
  };

  const previous = () => {
    sliderRef.current?.slickPrev();
  };

  const renderData = () => {
    if (!isImage) {
      return data[0];
    }
    if (isImage && isDiscover) {
      return data[0];
    }
    if (!isDiscover) {
      return {
        title: title,
        tag: "heading",
        items: data,
      };
    }
    return {}; // Default return value to avoid undefined
  };

  useEffect(() => {
    const container = containerRef.current;

    if (!container) return;

    const updateWidth = () => setContainerWidth(container.offsetWidth);
    const resizeObserver = new ResizeObserver(updateWidth);
    resizeObserver.observe(container);
    updateWidth();

    return () => resizeObserver.disconnect();
  }, []);

  const shouldShowSingleSlide = () => {
    if (containerWidth < 375) {
      return {
        toShow: 3,
        toScroll: 3,
        rows: 2,
      };
    } else if (containerWidth >= 375 && containerWidth < 600) {
      return {
        toShow: 3,
        toScroll: 3,
        rows: 2,
      };
    } else if (containerWidth >= 600 && containerWidth < 800) {
      return {
        toShow: 4,
        toScroll: 4,
        rows: 1,
      };
    } else {
      // For widths 1024px and above
      return {
        toShow: 6,
        toScroll: 6,
        rows: 1,
      };
    }
  };

  const slideSettings = shouldShowSingleSlide();

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slideSettings.toShow,
    slidesToScroll: slideSettings.toScroll,
    arrows: false,
    rows: slideSettings.rows,
    swipe: false,
    swipeToSlide: false,
    touchThreshold: 30,
    touchMove: false,
  };

  const queryClient = useQueryClient();
  const { interactions } = useUserContext();
  const isSurveyAlreadySubmitted = interactions?.surveyResponses.find(
    (response) => response.sectionId === sectionId,
  );
  const submitSurveyMutation = useMutation({
    mutationFn: (response: string) => {
      //check if the response is already submitted then deselect it by removing the response from the array
      const latestResponseArray = [response];
      return submitSurvey(sectionId ?? "", type, latestResponseArray);
    },
    onSuccess: () => {
      toast.dismiss();
      toast.success("Thanks for your response!");
      queryClient.invalidateQueries({ queryKey: ["user-interactions"] });
    },
    onError: () => {
      toast.dismiss();
      toast.error("Error submitting survey. Please try again.");
    },
    onMutate: () => {
      toast.loading("Submitting your response...");
    },
  });

  const debouncedSubmitSurveyMutation = useDebouncedCallback(
    submitSurveyMutation.mutate,
    300,
  );

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const wheelHandler = createWheelHandler();
    const handleWheel = (e: WheelEvent) => wheelHandler(e, next, previous);
    container.addEventListener("wheel", handleWheel, { passive: false });
    return () => {
      container.removeEventListener("wheel", handleWheel);
    };
  }, [next, previous]);
  return (
    <div
      className={cn(
        "w-full px-10 text-center font-sh5 bg-bg-5 h-full",
        isImage ? "py-[40px]" : "pt-[68px] pb-[76px]",
        isImage && isMobile && "px-[30px] py-[28px]",
      )}
      ref={containerRef}
      id="survey-container"
    >
      <div className="flex flex-col items-center justify-center h-full">
        <h2
          className={`w-full font-normal capitalize font-eb italic text-[30px] text-[#1D1D1D] ${
            isImage ? " " : " lg:text-[36px]"
          } mq750:text-5xl leading-[120%]`}
        >
          {renderData().title}
        </h2>
        <p
          className={cn(
            "pt-2 text-xs font-normal capitalize text-gray-60",
            isMobile && "pt-4",
            !isImage && "pt-4 mq1000:text-xs text-sm",
          )}
        >
          {renderData().tag}
        </p>

        {/* Render options or images based on isImage prop */}
        {!isImage ? (
          <div
            className={cn(
              "flex flex-wrap justify-center gap-1 mt-12",
              isMobile && "mt-4",
            )}
          >
            <SubmitOptions
              options={renderData().options || []}
              sectionId={sectionId || ""}
              type={type}
              submitSurveyMutation={debouncedSubmitSurveyMutation}
            />
          </div>
        ) : (
          <div
            className={cn("relative w-full mt-10 group", isMobile && "mt-9")}
          >
            <div className="flex flex-wrap justify-center gap-3 w-full">
              {renderData()?.items?.map((item: Content) => (
                <div
                  className={cn(
                    "relative w-[28%] aspect-square cursor-pointer flex-shrink-0",
                    containerWidth >= 648 && "w-[14.89%]",
                    containerWidth <= 299 && "w-[25%]",
                    containerWidth >= 948 && "w-[15.5%]",
                  )}
                  key={getId(item)}
                  onClick={() => debouncedSubmitSurveyMutation(getId(item))}
                >
                  <Image
                    src={getContentImageAndTitle(item).image}
                    className="object-cover w-full h-full"
                    alt={getContentImageAndTitle(item).title}
                  />
                  {isSurveyAlreadySubmitted?.response?.ans?.includes(
                    getId(item),
                  ) && (
                    <div className="absolute inset-0 flex items-center justify-center w-full bg-black/30">
                      <img
                        src="/white-tickmark.svg"
                        alt="tickmark"
                        className="w-[30px] h-[30px]"
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Survey;

const SubmitOptions = ({
  options,
  sectionId,
  type,
  submitSurveyMutation,
}: {
  options: string[];
  sectionId: string;
  type: sectionType;
  submitSurveyMutation: any;
}) => {
  const { interactions } = useUserContext();

  const isSurveyAlreadySubmitted = interactions?.surveyResponses.find(
    (response) => response.sectionId === sectionId,
  );
  const handleSubmit = (option: string) => {
    submitSurveyMutation(option);
  };
  // console.log(isSurveyAlreadySubmitted?.response?.ans);

  return options.map((option, index) => (
    <Badge
      variant={
        !isSurveyAlreadySubmitted?.response?.ans?.includes(option)
          ? "outline"
          : "secondary"
      }
      className={cn(
        "p-[10px_20px]  cursor-pointer capitalize mq1000:text-xs text-base border border-gray-[#CFCFCF]",
        !isSurveyAlreadySubmitted?.response?.ans?.includes(option)
          ? "text-gray-60"
          : "text-white",
      )}
      key={option + crypto.randomUUID() + index}
      onClick={() => handleSubmit(option)}
    >
      {option}
    </Badge>
  ));
};
