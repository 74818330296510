import React from "react";
import Header from "@/components/Global/header/Header";
import Footer from "./Components/Footer";
import Footer2 from "@/components/Global/Footer2";
import { useAuth } from "@/hooks/useAuth";
import PreloginHeader from "./Components/Header";
import BottomNavBar from "@/components/Global/BottomBarNav";

export default function PrivacyPolicy() {
  const { isLogin } = useAuth();
  return (
    <div className="font-sh5">
      {isLogin ? (
        <Header />
      ) : (
        <PreloginHeader className="md:!mt-[-65px] max-md:!mt-[0px] max-2xl:px-[48px]" />
      )}
      <div
        className={`w-full pt-[70px] max-md:pt-[180px] mb-10 mx-auto 2xl:container lg:max-w-[776px] max-md:px-[24px]  ${isLogin ? "max-md:pt-[60px]" : ""} ${!isLogin ? "md:pt-[145px]" : ""}`}
      >
        {/* Top Section */}
        <div className="w-full">
          <div className="pb-[2px]">
            <p className="text-[48px] max-md:text-[28px] text-[#1D1D1D]">
              PRIVACY POLICY
            </p>
            <div className="flex gap-2">
              <img src="/clock.svg" alt="clock" />
              <p className="text-sm text-[#1D1D1D] opacity-1 tracking-[0.15008px] text-opacity-[60%]">
                Last Updated: March 15, 2025
              </p>
            </div>
          </div>
          <div className="mt-[20px] pb-[40px] max-lg:mt-[14px] pt-[40px] max-md:pb-[28px] text-[#696969] tracking-[0.15008px] border-b-[1px] border-solid border-[#cccccc]">
            <p className="text-[18px] max-w-[90%] max-md:text-[14px] text-[#696969] lg:max-w-[90%] leading-[123%] ">
              Democrart Technologies Private Limited (“Company”/We”/ “Us”/
              “Our”) is engaged in the business of trade and display of physical
              art on its Platform (“Services”) to individuals (who shall
              hereinafter be referred to as “You”, “Your”, “User” as
              applicable). The Services shall be rendered either through its
              website at www.asign.art (“Website”) or the mobile application
              that is made available to you (“Application”) from time to time
              (the Website and Application shall be collectively referred to as
              “Platform”).
            </p>

            <p className="mt-[40px] max-md:mt-[24px] text-[18px] max-w-[90%] max-md:text-[14px] text-[#696969] leading-[123%]">
              This privacy policy ("Privacy Policy") explains how We collect,
              use, share, disclose and protect Personal information about the
              Users of the Services, and the visitors of the Website (jointly
              and severally referred to as “You” or “Users” in this Privacy
              Policy).
            </p>
            <p className="mt-[40px] max-md:mt-[24px] text-[18px] max-w-[90%] max-md:text-[14px] text-[#696969] leading-[123%]">
              BY USING THE SERVICES OR BY OTHERWISE GIVING US YOUR INFORMATION,
              YOU WILL BE DEEMED TO HAVE READ, UNDERSTOOD AND AGREED TO THE
              PRACTICES AND POLICIES OUTLINED IN THIS PRIVACY POLICY AND AGREE
              TO BE BOUND BY THE PRIVACY POLICY. YOU HEREBY CONSENT TO OUR
              COLLECTION, USE AND SHARING, DISCLOSURE OF YOUR INFORMATION AS
              DESCRIBED IN THIS PRIVACY POLICY. WE RESERVE THE RIGHT TO CHANGE,
              MODIFY, ADD OR DELETE PORTIONS OF THE TERMS OF THIS PRIVACY
              POLICY, AT OUR SOLE DISCRETION, AT ANY TIME. IF YOU DO NOT AGREE
              WITH THIS PRIVACY POLICY AT ANY TIME, DO NOT USE ANY OF THE
              SERVICES OR ACCESS OUR PLATFORM. IF YOU USE THE SERVICES ON BEHALF
              OF SOMEONE ELSE (SUCH AS YOUR CHILD) OR AN ENTITY (SUCH AS YOUR
              EMPLOYER), YOU REPRESENT THAT YOU ARE AUTHORISED BY SUCH
              INDIVIDUAL OR ENTITY TO (I) ACCEPT THIS PRIVACY POLICY ON SUCH
              INDIVIDUAL’S OR ENTITY’S BEHALF, AND (II) CONSENT ON BEHALF OF
              SUCH INDIVIDUAL OR ENTITY TO OUR COLLECTION, USE AND DISCLOSURE OF
              SUCH INDIVIDUAL’S OR ENTITY’S INFORMATION AS DESCRIBED IN THIS
              PRIVACY POLICY.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[28px] text-[#1d1d1d]">
            About your privacy and this privacy policy
          </p>
          <div className="mt-[20px] pb-[28px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-w-[90%] max-md:text-[14px] text-[#696969] leading-[123%]">
              Your privacy is extremely important to us. We are constantly
              putting a great deal of thought, effort, tools,resources and
              procedures in place in order to protect and safeguard your
              privacy. This document is our "Privacy Policy" and it contains
              details on issues related to your privacy when using our services.
              It is intended to inform you of our policies, procedures and
              practices regarding the collection, use and disclosure of any
              information that you provide through the Platform.{" "}
            </p>
            <p className="mt-[40px] max-md:mt-[24px] text-[18px] max-w-[90%] max-md:text-[14px] text-[#696969] leading-[123%]">
              The Privacy Policy is part of our Terms of Use which can be found
              on our website. The terms in the Privacy Policy (such as, but not
              limited to, "we", "our", "us", "Platform", "Services" etc.) have
              the same meaning as in our Terms of Use document. When you use our
              Platform, you accept and agree to both the Terms of Use and the
              Privacy Policy. If you do not agree to be bound to the Privacy
              Policy you should stop using the Platform immediately. By
              accessing and using our Platform you affirm that you have read the
              Terms of Use and the Privacy Policy and that you understand, agree
              and acknowledge all the terms contained in both of them.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[28px] text-[#1D1D1D]">
            Information collection, use and disclosure
          </p>
          <div className="mt-[20px] pb-[40px] text-[#696969] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-w-[90%] max-md:text-[14px] text-[#696969] leading-[123%]">
              To let us operate the Platform effectively and to let you use the
              Platform, including the Services, we may have to collect your
              personally identifiable information The types of information that
              we collect directly from you include: personal details (e.g. name,
              date of birth), contact details (e.g. phone number, email address,
              postal address), transaction information (e.g. bidding or purchase
              records, shipping details, information about items you purchase or
              wish to consign), details about your collection, limited financial
              information (e.g. tokenized payment information in connection with
              your purchases, wire instructions, digital wallet details and
              address), username and password, user ID, unique device ID,
              information on forms required for certain transactions (e.g. PAN,
              AADHAAR number, GST number, CIN, etc.), and identification
              information (e.g. photo ID), information related to the Services
              or your need for Services, and any information which is exchanged
              between you and Us (collectively the "Information"). You may
              decide which Information, if any, you would like to share with us,
              but some functions of the Platform may not be available to you
              without providing us with the necessary Information. By deciding
              to provide the Information you agree to our methods of collection
              and use, as well as to other terms and provisions of this Privacy
              Policy.
            </p>
            <p className="mt-[40px] max-md:mt-[24px] text-[18px] max-w-[90%] max-md:text-[14px] text-[#696969] leading-[123%]">
              Protecting this Information is a top priority for us. We will
              never sell or rent any information you share on the Platform.
              Other than in the limited ways detailed in this Privacy Policy, we
              will never use or disclose any Information unless you specifically
              and explicitly request or approve us to do so.
            </p>

            <p className="mt-[40px] text-[18px] max-w-[90%] max-md:text-[14px] text-[#696969]">
              The Information may be used for the following purposes:
            </p>
            <ul className="list-square pl-6 space-y-2 text-[#696969] font-sh5 text-[18px] max-md:text-[14px] pt-[10px] leading-[120%] font-normal">
              <li className="marker:text-[#1d1d1d] pt-[10px] text-[#696969] leading-[123%]">
                To contact you or provide you with information, alerts and
                suggestions that are related to the service.
              </li>
              <li className="marker:text-[#1d1d1d] pt-[10px] text-[#696969] leading-[123%]">
                Billing-related purposes.
              </li>
              <li className="marker:text-[#1d1d1d] pt-[10px] text-[#696969] leading-[123%]">
                To enable and facilitate the Services.
              </li>
              <li className="marker:text-[#1d1d1d] pt-[10px] text-[#696969] leading-[123%]">
                To supervise, administer and monitor the Services.
              </li>
              <li className="marker:text-[#1d1d1d] pt-[10px] text-[#696969] leading-[123%]">
                To measure and improve the quality, effectiveness and delivery
                of our Services.
              </li>
            </ul>
            <p className="mt-[40px] max-md:mt-[24px] text-[18px] max-w-[90%] max-md:text-[14px] text-[#696969] leading-[123%]">
              As a Company that conducts transactions with entities globally, We
              may in the normal course of its business transfer your personal
              data internationally to any entities who may need to process your
              data in connection with the services that the Company provides to
              you. The Company uses Amazon Web Services (AWS) for its servers,
              and the location of the servers is dependent on AWS.
            </p>
            <p className="mt-[40px] max-md:mt-[24px] text-[18px] max-w-[90%] max-md:text-[14px] text-[#696969] leading-[123%]">
              In addition to the above, the following information is defined as
              “Personal Information” as defined under Information Technology
              (Reasonable Security Practices & Procedures and Sensitive Personal
              Data or Information) Rules, 2011{" "}
              <span className="font-medium">(“SPDI Rules”)</span> to mean any
              information that relates to a natural person, which, either
              directly or indirectly, in combination with other information
              available or likely to be available with a body corporate, is
              capable of identifying such person.
            </p>
            <p className="mt-[40px] max-md:mt-[24px] text-[18px] max-w-[90%] max-md:text-[14px] text-[#696969] leading-[123%]">
              The SPDI Rules further define “sensitive personal data or
              information” of a person to mean personal information about that
              person relating to the following: (i) passwords; (ii) financial
              information such as bank accounts, credit and debit card details
              or other payment instrument details; (iii) sexual orientation;
              (iv) biometric information; (v) information received by body
              corporate under lawful contract or otherwise; (vi) visitor details
              as provided at the time of registration or thereafter; and (vii)
              call data records.
            </p>
            <p className="mt-[40px] max-md:mt-[24px] text-[18px] max-w-[90%] max-md:text-[14px] text-[#696969] leading-[123%]">
              You are responsible for maintaining the accuracy of the
              information you submit to us, such as your contact information
              provided as part of account registration. If your Personal
              Information changes, you may correct, delete inaccuracies, or
              amend information by notifying us of the change. If you provide
              any information that is untrue, inaccurate, out of date or
              incomplete (or becomes untrue, inaccurate, out of date or
              incomplete), or the Company has reasonable grounds to suspect that
              the information provided by you is untrue, inaccurate, out of date
              or incomplete, Company may, at its sole discretion, discontinue
              the provision of the Services to you. Company shall retain the
              right not to correct, delete or update your Personal Information,
              including (a) where the Personal Information is opinion data that
              is kept solely for evaluative purposes; and (b) the Personal
              Information is in documents related to a prosecution if all
              proceedings relating to the prosecution have not been completed.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[28px] text-[#1d1d1d]">
            Retention of personal information
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-w-[90%] max-md:text-[14px] text-[#696969] leading-[123%]">
              We can retain Personal Information for as long as needed or
              permitted in light of the purpose(s) for which it was obtained and
              consistent with Applicable Law. The criteria for determining the
              duration for which the Company will keep your Personal Information
              are as follows: (i) the Company will retain copies of your
              Personal Information in a form that permits identification only
              for as long as is necessary in connection with the purposes set
              out in this Privacy Policy, including for the length of time the
              Company have an ongoing relationship with you and provide the
              Services to you; (ii) if there is a legal obligation to which the
              Company are subject (for example, certain laws require the Company
              to keep records of your transactions for a certain period of time
              before the Company can delete them); or (iii) keeping in view the
              legitimate interests of the Company (litigation or regulatory
              investigations).
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[28px] text-[#1d1d1d]">
            Cookies
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-w-[90%] max-md:text-[14px] text-[#696969] leading-[123%]">
              Cookies are files with a small amount of data that is commonly
              used as an anonymous unique identifier. These are sent to your
              browser from the website that you visit and are stored on your
              computer’s hard drive for record-keeping purposes. You can change
              your browser's settings so it will stop accepting cookies or
              prompt you before accepting a cookie. However, if you do not
              accept cookies you may not be able to use the Platform.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[28px] text-[#1d1d1d]">
            Phishing
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-w-[90%] max-md:text-[14px] text-[#696969] leading-[123%]">
              Online identity theft and account hacking, including the practice
              currently known as "phishing", are of great concern. You should
              always be diligent when you are being asked for your account
              information and you must always make sure you do that in our
              secure system. We will never request your login information or
              your credit card information in any non-secure or unsolicited
              communication (email, phone or otherwise). We take phishing
              attempts seriously. If you receive correspondence you think may
              not be from the Company, We request you to please report it
              immediately to the Company.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[28px] text-[#1d1d1d]">
            Links
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-w-[90%] max-md:text-[14px] text-[#696969] leading-[123%]">
              The Platform may contain links to other websites, services or
              offers that are owned, operated or maintained by third parties. If
              you click on a third-party link, you will be directed to that
              third website or service. The fact that we link to a website or
              service is not an endorsement, authorization or representation of
              our affiliation with that third party, nor is it an endorsement of
              their privacy or information security policies or practices. We do
              not have control over third-party websites and services and we do
              not have control over their privacy policies and terms of use.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[28px] text-[#1d1d1d]">
            Security
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-w-[90%] max-md:text-[14px] text-[#696969] leading-[123%]">
              While using any Internet-based service carries inherent security
              risks that cannot be 100% prevented, our systems, infrastructure,
              encryption technology, operation and processes are all designed,
              built and maintained with your security and privacy in mind. We
              apply industry standards and best practices to prevent any
              unauthorised access, use, and disclosure. We comply with or exceed
              all applicable federal laws, state laws, and regulations regarding
              data privacy.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[28px] text-[#1d1d1d]">
            Service providers
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-w-[90%] max-md:text-[14px] text-[#696969] leading-[123%]">
              We may employ third-party companies and individuals to facilitate
              our Platform, to perform certain tasks that are related to the
              Platform, or to provide audit, legal, operational or other
              services for us. These tasks include, but are not limited to,
              customer service, technical maintenance, monitoring, email
              management and communication, database management, billing and
              payment processing, reporting and analytics. We will share with
              them only the minimum necessary information to perform their task
              for us and only after entering into appropriate confidentiality
              agreements.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[28px] text-[#1d1d1d]">
            Children's privacy
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-w-[90%] max-md:text-[14px] text-[#696969] leading-[123%]">
              We do not knowingly collect or solicit any information from anyone
              under the age of eighteen (18) years or knowingly allow such
              persons to become our users. The Platform is not directed and not
              intended to be used by children under the age of eighteen (18)
              years. If you're aware that we have collected Personal Information
              from a child under the age of eighteen (18) years please let us
              know by contacting us and we will delete that information.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[28px] text-[#1d1d1d]">
            Compliance with laws and law enforcement
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-w-[90%] max-md:text-[14px] text-[#696969] leading-[123%]">
              We cooperate with government and law enforcement officials to
              enforce and comply with the law. We may disclose information
              necessary or appropriate to protect the safety of the public or
              any person, to respond to claims and legal processes (including
              but not limited to subpoenas), and to prevent or stop activity
              that may be illegal or dangerous. The Company is the Controller
              with respect to your Personal Data. You can contact our Data
              Protection Officer with questions, concerns or objections about
              this policy, or about your data by writing to:{" "}
              <span className="underline text-[#1d1d1d]">care@asign.art</span>
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[28px] text-[#1d1d1d]">
            Deletion of account
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%] border-b-[1px] border-solid border-[#cccccc]">
            <p className="mt-[20px] text-[18px] max-w-[90%] max-md:text-[14px] text-[#696969] leading-[123%]">
              Should you wish to delete your account, you may send an email to{" "}
              <span className="underline text-[#1d1d1d]">care@asign.art</span>{" "}
              with the subject line “Please delete my account”. Deleted accounts
              will be permanently removed from our system and cannot be
              reactivated in the future, hence you are advised to be cautious
              before opting for deletion. Please note that your account cannot
              be deleted in the event you have any transactions, past or
              present, linked to your account due to regulatory requirements.
              Your account can however be deactivated and will no longer be
              accessible. To deactivate your account, you may send an email to{" "}
              <span className="underline text-[#1d1d1d]">care@asign.art</span>{" "}
              with the subject line “Please deactivate my account”. A
              deactivated account can be reactivated at a later date at your
              request.
            </p>
          </div>

          <p className="text-[24px] max-md:text-[18px] pt-[28px] text-[#1d1d1d]">
            Changes to the privacy policy
          </p>
          <div className="mt-[20px] pb-[40px] text-[#1d1d1d] tracking-[0.15008px] text-opacity-[60%]">
            <p className="mt-[20px] text-[18px] max-w-[90%] max-md:text-[14px] text-[#696969] leading-[123%]">
              We may update this privacy statement at our sole discretion. The
              date of the last revision of this policy appears at the end of
              this page. We encourage you to periodically review this page for
              the latest information on our Privacy Policy and practices.
              Regardless of changes to our Privacy Policy, we will never use the
              information you submit under our current privacy notice in a new
              way without first notifying you and giving you the option to
              opt-out. By clicking on the “I Accept” button, you agree and
              acknowledge that you have read the terms of this Privacy Policy,
              understood its contents, and have chosen to execute it of your own
              free will and these Terms are made in good faith and are fair and
              reasonable and shall be binding upon you, once executed.
            </p>
          </div>
        </div>
      </div>
      {!isLogin ? (
        <Footer />
      ) : (
        <>
          <Footer2 className=" pt-[40px] pb-[20px] md:pt-[70px] max-sm:pb-[40px] max-sm:h-[181px] max-sm:ml-[24px]" />
          <BottomNavBar />
        </>
      )}
    </div>
  );
}
